import { render, staticRenderFns } from "./upload-drawer-simple.vue?vue&type=template&id=04e1c592&scoped=true"
import script from "./upload-drawer-simple.vue?vue&type=script&lang=js"
export * from "./upload-drawer-simple.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04e1c592",
  null
  
)

export default component.exports