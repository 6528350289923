import axios from 'axios'

/**
 * General function for calling the API
 * @param path
 * @param commitTo
 * @param commit
 * @param payload
 * @returns {Promise<T | any>}
 */
function api(path, commitTo, commit, payload){
    return axios.post(path, { filter: payload})
        .then((response) => {
            commit(commitTo,response.data);
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
}

/**
 * Get all available projects
 * @param commit
 * @returns {Promise<any>}
 */
export const getProjects = ({ commit, dispatch }, [page, limit]) => {
    return axios.get('/api/projects?page='+page+'&limit='+limit)
        .then((response) => {
            commit('setProjects',response.data.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

export const findSor = ({ commit, dispatch }, searchString) => {
    return axios.post('/api/project/findSor', {search: searchString})
        .then((response) => {
            commit('setProjects',response.data.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

/**
 *
 * @param commit
 * @param payload
 * @returns {Promise<T | any>}
 */
export const saveProject = ({ commit }, payload) => {
    return axios.post('/projects/project', payload)
        .then((response) => {
            if(response.data.valid) {
                commit('setProject', response.data.project);
            }
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const saveProjectFinancial = ({ commit }, payload) => {
    return axios.post('/projects/project-financial', payload)
        .then((response) => {
            if(response.data.valid) {
                commit('setProject', response.data.project);
            }
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const saveProjectBudgetLine = ({ commit }, payload) => {
    return axios.post('/projects/project-budget', payload)
        .then((response) => {
            if(response.data.valid) {
                commit('setProject', response.data.project);
            }
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const saveProjectIndirect = ({ commit }, payload) => {
    return axios.post('/projects/project-indirect', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const saveProjectFinancing = ({ commit }, payload) => {
    return axios.post('/projects/project-financing', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};
export const saveProjectTeh = ({ commit }, payload) => {
    return axios.post('/projects/project-teh', payload)
        .then((response) => {
            if(response.data.valid) {
                commit('setProject', response.data.project);
            }
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const saveProjectEmployee = ({ commit }, payload) => {
    return axios.post('/projects/project-employee', payload)
        .then((response) => {
            if(response.data.valid) {
                commit('setProject', response.data.project);
            }
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const saveProjectUsers = ({ commit }, payload) => {
    return axios.post('/api/project/users', payload)
        .then((response) => {
            // commit('setProject', response.data.project);
            return Promise.resolve(response.data)
        })
};

export const loadProject = ({ commit }, payload) => {
    return axios.get('/projects/project/' + payload)
        .then((response) => {
            commit('setProject', response.data);
            return Promise.resolve(response.data);
        })
};

// remove promoter
export const removeProject = ({ commit, dispatch }, project_id) => {
    return axios.post('/api/project/remove', project_id)
        .catch((error) => {
            console.log(error);
            return Promise.resolve(error);
        })
};

// remove promoter
export const removeBudgetLine = ({ commit, dispatch }, payload) => {
    return axios.post('/api/project/remove-line', payload)
        .catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

// remove team
export const removeEmployeeLine = ({ commit, dispatch}, payload) => {
    return axios.post('/api/project/remove-employee', payload)
        .catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

// remove activity
export const removeActivity = ({ commit, dispatch}, payload) => {
    return axios.post('/api/project/remove-activity', payload)
        .catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

// remove objective
export const removeObjective = ({ commit, dispatch}, payload) => {
    return axios.post('/api/project/remove-objective', payload)
        .catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

// remove partner
export const removePartnerLine = ({ commit, dispatch}, payload) => {
    return axios.post('/api/project/remove-partner', payload)
        .catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

// remove INDICATOR
export const removeIndicator = ({ commit, dispatch}, payload) => {
    return axios.post('/api/project/remove-indicator', payload)
        .catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

// remove calendar
export const removeCalendar = ({ commit, dispatch}, payload) => {
    return axios.post('/api/project/remove-calendar', payload)
        .catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getPartnerBudget = ({ commit }, payload) => {
    return axios.post('/api/project/partner-budget', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};


/**
 * Get catalog data
 * @param commit
 * @param payload
 * @returns {Promise<any | never>}
 */
export const getAppeals = ({ commit }, payload) => {
    return api('/api/get-catalog/numar-apel', 'setAppeals', commit, payload);
};
export const getRounds = ({ commit }, payload) => {
    return api('/api/get-catalog/runda', 'setRounds', commit, payload);
};
export const getGrants = ({ commit }, payload) => {
    return api('/api/get-catalog/tip-grant', 'setGrants', commit, payload);
};
export const getMedium = ({ commit }, payload) => {
    return api('/api/get-catalog/mediul-desfasurare', 'setMedium', commit, payload);
};
export const getFraming = ({ commit }, payload) => {
    return api('/api/get-catalog/categoria-incadrare', 'setFraming', commit, payload);
};
export const getPriorities = ({ commit }, payload) => {
    return api('/api/get-catalog/prioritati-program', 'setPriorities', commit, payload);
};
export const getPolicies = ({ commit }, payload) => {
    return api('/api/get-catalog/policy-markers', 'setPolicies', commit, payload);
};
export const getCompanyTypes = ({ commit }, payload) => {
    return api('/api/get-catalog/tip-partener', 'setCompanyTypes', commit, payload);
};
export const getDocumentCategories = ({ commit }, payload) => {
    return api('/api/get-catalog/categorii-documente', 'setDocumentCategories', commit, payload);
};
export const getWorkloads = ({ commit }, payload) => {
    return api('/api/get-catalog/tip-norma', 'setWorkloads', commit, payload);
};
export const getCostCategories = ({ commit }, payload) => {
    return api('/api/get-catalog/categorii-costuri', 'setCostCategories', commit, payload);
};
export const getIndirectCategories = ({ commit }, payload) => {
    return api('/api/get-catalog/categorii-indirecte', 'setCostCategories', commit, payload);
};
export const getBudgetLines = ({ commit }, payload) => {
    return api('/api/get-catalog/linii-buget', 'setBudgetLines', commit, payload);
};
export const getUnits = ({ commit }, payload) => {
    return api('/api/get-catalog/unitate-masura', 'setUnits', commit, payload);
};
export const getOutcomes = ({ commit }, payload) => {
    return api('/api/get-catalog/outcome-proiect', 'setOutcomes', commit, payload);
};
export const getOutputs = ({ commit }, payload) => {
    return api('/api/get-catalog/output-proiect', 'setOutputs', commit, payload);
};
export const getOutcomeIndicators = ({ commit }, payload) => {
    return api('/api/get-catalog/indicatori-outcome', 'setOutcomeIndicators', commit, payload);
};
export const getOutputIndicators = ({ commit }, payload) => {
    return api('/api/get-catalog/indicatori-output', 'setOutputIndicators', commit, payload);
};

export const getIndirectsBudget = ({ commit }, payload) => {
    //return api('/notifications/indirectsBudget', 'setNotificationIndirects', commit, payload);
    return axios.post('/projects/indirectsBudget', payload)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getIndirectPercent = ({ commit }, payload) => {
    //return api('/notifications/izndirectsBudget', 'setNotificationIndirects', commit, payload);
    return axios.post('/projects/indirectPercent', payload)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getFinancingBudget = ({ commit }, payload) => {
    //return api('/notifications/financingBudget', 'setNotificationFinancing', commit, payload);
    return axios.post('/projects/financingBudget', payload)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getTotalBudget = ({ commit }, payload) => {
    //return api('/notifications/totalBudget', 'setNotificationTotals', commit, payload);
    return axios.post('/projects/totalBudget', payload)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getTotalPartner = ({ commit }, payload) => {
    //return api('/notifications/totalPartner', 'setNotificationTotalsPartner', commit, payload);
    return axios.post('/projects/totalPartnerBudget', payload)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getSynthetic = ({ commit }, payload) => {
    //return api('/notifications/synthetic', 'setNotificationSynthetic', commit, payload);
    return axios.post('/projects/synthetic', payload)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};


