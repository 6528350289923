export default{
    promoters: [],
    promoter: [],
    saveValidation: false,
    promoterValidated: false,
    validatePromoterAccount: false,
    promoterAccountValidated: false,
    errors: false,
    financialReport: [],
    financialReportNow: [],
    //financialIndirectReport: [],
    reporting: {},
    validationState: {},
    project: {},
    hrReport: [],
    syntheticReport: [],
    syntheticReportNow: [],
    financialIndirectReport: [],
    financialIndirectReportNow: [],
    financialPartners: [],
    financialPartnersNow: [],
    riskDocuments: [],
    reportingDocuments: [],
    validateFinal: false,
    validateIndirect: false,
    validateActivity: [],
    validateGeneral: false,
    validateReport: [],
}
