//
import axios from "axios";
// a test
export const saveValidationActivityLine = ({ commit, dispatch}, promoter) => {
    return axios.post('/reportings/validationActivityLine', promoter)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.reject(error)
        })
};

export const saveIndirectLine = ({ commit }, payload) => {
    return axios.post('/reportings/reportingIndirectLine', payload)
        .then((response) => {
            if(response.data.valid) commit('setReporting',response.data.reporting);
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const saveReporting = ({ commit }, payload) => {
    return axios.post('/reportings/saveReporting', payload)
        .then((response) => {
            commit('setReporting',response.data.reporting);
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const setValidateReport = ({ commit }, payload) => {
    commit('setValidateReport', payload);
    // state.validateReport = payload
};

export const saveActivityLine = ({ commit }, payload) => {
    return axios.post('/reportings/reportingActivityLine', payload)
        .then((response) => {
            //if(response.data.valid) commit('setReporting',response.data.reporting);
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const removeActivityLine = ({ commit, dispatch}, payload) => {
    return axios.post('/api/reporting/remove-activity-line', payload)
        .catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const saveAppStatus = ({ commit, dispatch}, promoter) => {
    return axios.post('/reportings/saveAppStatus', promoter)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.reject(error)
        })
};

export const getFinancialIndirectReport = ({ commit }, payload) => {
    return axios.post('/api/financialIndirectReport', payload)
        .then((response) => {
            commit('setFinancialIndirectReport',response.data);
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getSyntheticReport = ({ commit }, payload) => {
    return axios.post('/api/syntheticReport', payload)
        .then((response) => {
            commit('setSyntheticReport',response.data);
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};
export const getFinancialPartners = ({ commit }, payload) => {
    return axios.post('/api/financialPartners', payload)
        .then((response) => {
            commit('setFinancialPartners',response.data);
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const saveReportingAnswerLimit = ({ commit }, payload) => {
    return axios.post('/reportings/saveAnswerLimit', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getFinancialReport = ({ commit }, payload) => {
    return axios.post('/api/financialReport', payload)
        .then((response) => {
            commit('setFinancialReport',response.data);
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getReporting = ({ commit }, payload) => {
    return axios.post('/reportings/reporting', payload)
        .then((response) => {
            commit('setReporting',response.data);
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getReportingActivities = ({ commit }, payload) => {
    return axios.post('/reportings/reportingActivities', payload)
        .then((response) => {
            commit('setReportingActivities',response.data);
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getProject = ({ commit }, payload) => {
    return axios.post('/reportings/project', payload)
        .then((response) => {
            commit('setProject',response.data);
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getValidationState = ({ commit }, payload) => {
    return axios.post('/reportings/getValidationState', payload)
        .then((response) => {
            commit('setValidationState',response.data.validation);
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const editComment = ({ commit }, payload) => {
    return axios.post('/reportings/editComment', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const deleteComment = ({ commit }, payload) => {
    return axios.post('/reportings/deleteComment', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const saveCommentActivity = ({ commit }, payload) => {
    return axios.post('/reportings/saveCommentActivity', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const saveCommentDOC = ({ commit }, payload) => {
    return axios.post('/reportings/saveCommentDOC', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const saveCommentOnDOC2 = ({ commit }, payload) => {
    return axios.post('/reportings/saveCommentOnDoc2', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

//getSources
export const getSources = ({ commit }, payload) => {
    //return api('/notifications/totalBudget', 'setNotificationTotals', commit, payload);
    return axios.post('/reportings/getSources', payload)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getIndirectReport = ({ commit }, payload) => {
    //return api('/notifications/totalBudget', 'setNotificationTotals', commit, payload);
    return axios.post('/reportings/getIndirectReport', payload)
        .then((response) => {
            //commit('setIndirectReportNow',response.data);
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getSyntheticFromReport = ({ commit }, payload) => {
    //return api('/notifications/totalBudget', 'setNotificationTotals', commit, payload);
    return axios.post('/reportings/getSyntheticFromReport', payload)
        .then((response) => {
            commit('setSyntheticReportNow',response.data);
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getFinancingPartnerReport = ({ commit }, payload) => {
    //return api('/notifications/totalBudget', 'setNotificationTotals', commit, payload);
    return axios.post('/reportings/getFinancingPartnerReport', payload)
        .then((response) => {
            //commit('setSyntheticReportNow',response.data);
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getActivityReportNew = ({ commit }, payload) => {
    //return api('/notifications/totalBudget', 'setNotificationTotals', commit, payload);
    return axios.post('/reportings/getActivityReportNew', payload)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getHrReport = ({ commit }, payload) => {
    //return api('/notifications/totalBudget', 'setNotificationTotals', commit, payload);
    return axios.post('/api/getHrReport', payload)
        .then((response) => {
            commit('setHrReport',response.data);
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getReportingDocuments = ({ commit }, payload) => {
    return axios.post('/api/getReportingDocuments', payload)
        .then((response) => {
            commit('setReportingDocuments',response.data);
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getRiskDocuments = ({ commit }, payload) => {
    return axios.post('/api/getRiskDocuments', payload)
        .then((response) => {
            commit('setRiskDocuments',response.data);
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const setExpenseChange = ({ commit }, payload) => {
    return axios.post('/api/setExpenseChange', payload)
        .then((response) => {
            // commit('setRiskDocuments',response.data);
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const setIndirectExpenseChange = ({ commit }, payload) => {
    return axios.post('/api/setIndirectExpenseChange', payload)
        .then((response) => {
            // commit('setRiskDocuments',response.data);
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};
export const setVrfReporting = ({ commit }, payload) => {
    return axios.post('/api/setVrfReporting', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};
export const setVrtReporting = ({ commit }, payload) => {
    return axios.post('/api/setVrtReporting', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getVrfEligibility = ({ commit }, payload) => {
    return axios.post('/api/getVrfEligibility', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getVrfEligibilityDetails = ({ commit }, payload) => {
    return axios.post('/api/getVrfEligibilityDetails', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getVrfDocuments = ({ commit }, payload) => {
    return axios.post('/api/getVrfDocuments', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getVrfCash = ({ commit }, payload) => {
    return axios.post('/api/getVrfCash', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getVrfFinancingExpenses = ({ commit }, payload) => {
    return axios.post('/api/getVrfFinancingExpenses', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getVrfIndirects = ({ commit }, payload) => {
    return axios.post('/api/getVrfIndirects', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getVrfBudget = ({ commit }, payload) => {
    return axios.post('/api/getVrfBudget', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getVrfOthers = ({ commit }, payload) => {
    return axios.post('/api/getVrfOthers', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getVrfExport = ({ commit }, payload) => {
    return axios.post('/api/getVrfExport', payload, {responseType: 'blob'})
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'VRF.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getVrtExport = ({ commit }, payload) => {
    return axios.post('/api/getVrtExport', payload, {responseType: 'blob'})
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'VRT.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};
