<template>
    <div v-if="project" id="indirect">
        <h3 class="mb-4 mt-3">
            Cheltuieli indirecte
        </h3>
        <div class="row">
            <div class="col-sm-12">
                <el-table :data="indirects" stripe fit highlight-current-row style="width:100%">
<!--                    <el-table-column type="expand" width="40">-->
<!--                        <template slot-scope="scope">-->
<!--                            bla-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                    <el-table-column label="Linie" prop="line" width="60" align="center"></el-table-column>-->
                    <el-table-column type="index"  width="60" align="center"></el-table-column>
                    <el-table-column label="Capitol buget" width="200">
                        <template slot-scope="scope">
                            <el-popover placement="top-start" title="Capitol buget" width="300" trigger="click" :content="scope.row.category.value">
                                <span  slot="reference">{{ scope.row.category.value }}</span>
<!--                                | truncate(40, '...')-->
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="Aplicant/Partener" prop="partner">
                        <template slot-scope="scope">
                            {{ scope.row.partner !== null && scope.row.partner !== undefined && scope.row.partner !== '0'? 'P' + scope.row.partner.id : 'A' }}
                        </template>
                    </el-table-column>
<!--                    <el-table-column label="Cod" prop="code"></el-table-column>-->
                    <el-table-column label="Linie buget" width="150">
                        <template slot-scope="scope">
                            <el-popover placement="top-start" title="Linie buget" width="300" trigger="click" :content="scope.row.budget.value">
                                <span  slot="reference">{{ scope.row.budget.value}}</span>
<!--                                | truncate(30, '...')-->
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="Finantare">
                        <template slot-scope="scope">
                            {{ formatNumber(scope.row.amount_financing_percent,2) }}% ({{ formatPrice(parseFloat(scope.row.amount_financing)/parseFloat(scope.row.rate)) }})
                        </template>
                    </el-table-column>
                    <el-table-column label="Cofinantare">
                        <template slot-scope="scope">
                            {{ formatNumber(scope.row.amount_cofinancing_percent,2) }}% ({{ formatPrice(parseFloat(scope.row.amount_cofinancing)/parseFloat(scope.row.rate)) }})
                        </template>
                    </el-table-column>
                    <el-table-column label="Total Cost" align="right">
                        <template slot-scope="scope">
                            {{ formatPrice((parseFloat(scope.row.amount_financing) + parseFloat(scope.row.amount_cofinancing))/parseFloat(scope.row.rate)) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Dezv. Org.">
                        <template slot-scope="scope">
                            {{formatPrice(scope.row.dezv !== undefined ? scope.row.dezv : 0) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Actiuni" width="130px" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="secondary" icon="el-icon-edit"
                                       @click="handleSelectRow(scope.$index, scope.row)"></el-button>
                            <el-button size="mini" type="danger" icon="el-icon-delete"
                                       @click="handleDeleteRow(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-form :model="form" :id="'reportingIndirectForm'" :validate-on-rule-change="false" v-loading="loading"  :rules="rules" :ref="'reportingIndirectForm'" label-position="top" :status-icon="true" :inline-message="true">
            <div class="row">
                <div class="col-sm-12">
                    <h4 class="mb-4 mt-5">Adauga/modifica linie cheltuieli</h4>
                </div>
                <div class="col-sm-6 form-group">
                    <el-form-item label="Cheltuiala se aloca la:" prop="partner">
                        <el-select v-model="form.partner" clearable placeholder="Selecteaza" style="width:100%" value-key="id">
                            <el-option key="0" label="Aplicant" value="0"></el-option>
                            <el-option v-for="item in project.partners"
                                       :key="item.id" :label="item.name"  :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
<!--                <div class="col-sm-6 form-group" v-if="showPosition">-->
<!--                    <el-form-item label="Pozitia in cadrul proiectului">-->
<!--                        <el-select v-model="form.position" placeholder="Selecteaza" style="width:100%" value-key="id" @change="updateSupplier">-->
<!--                            <el-option v-for="item in project.employees"-->
<!--                                       :key="item.id" :label="item.position + (item.first_name ? ' - ' + item.first_name + ' ' + item.last_name: '')"  :value="item">-->
<!--                            </el-option>-->
<!--                        </el-select>-->
<!--                    </el-form-item>-->
<!--                </div>-->
            </div>
            <div class="row">
                <div class="col-sm-6 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Completati CUI-ul pentru furnizorii de diverse servicii sau completati CNP-ul pentru personalul administrativ! ATENTIE! Nu puteti raporta un salariat care a fost raportat si la costuri directe, indiferent de perioada.</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="CUI Furnizor / CNP" prop="supplier">
                        <el-autocomplete v-model="form.supplier" @select="handleSelectCui" placeholder="Introduceti CUI-ul / CNP-ul"
                                         type="text" :maxlength="13" show-word-limit :fetch-suggestions="getCompanies" style="width:100%">
                            <template slot-scope="{ item }">
                                <span>{{ item.value }} - {{ item.label }}</span>
                            </template>
                        </el-autocomplete>
                    </el-form-item>
                </div>
                <div class="col-sm-6 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Pentru linia bugetara 1.6.1. Experti - contracte drepturi autor se completeaza cu numele si prenumele expertului.</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="Denumire furnizor / Nume angajat" prop="supplier_name">
                        <el-input v-model="form.supplier_name" placeholder="Introduceti numele firmei/angajatului" type="text" maxlength="60" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-6 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Completati cu suficiente detalii astfel incat sa se inteleaga ceea ce raportati.</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="Descriere" prop="description">
                        <el-input v-model="form.description" placeholder="Introduceti descrierea" type="text" maxlength="255" show-word-limit></el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Se va completa integral numarul din documentul de angajare a costului (toate cifrele ) fara a mentiona seria si tipul documentului(Ex. 0000075 sau 12357VJH002)
                            <br/>In cazul statelor de plata fara numar, se va completa cu numarul lunii pentru care s-a intocmit statul de plata ( ex: pentru ianuarie, se va completa cu 1, fiind prima luna din an)
                            <br/>In cazuri exceptionale, cand se identifica documente de angajare a costului fara numar, se va completa cu ,,FN'' (fara numar).
                            <br/>In cazul muncii voluntare (linia 1.1.2), se va completa cu numarul contractului de voluntariat.</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item :label="(showPosition ? 'Nr. doc. angajare cost' : 'Nr. document cost') " prop="invoice_no">
                        <el-input v-model="form.invoice_no" placeholder="Introduceti numarul" type="text" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Selectati sau scrieti de forma ZZ.LL.AAAA</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item :label="(showPosition ? 'Data doc. angajare cost' : 'Data document cost')" prop="invoice_date">
                        <el-date-picker v-model="form.invoice_date" type="date" value-format="yyyy-MM-dd"  format="d.M.yyyy" placeholder="Selecteaza data" style="width:100%" :picker-options="periodOptions" @change="getRate"></el-date-picker>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Se va completa numarul ordinului de plata/extrasului de cont/chitantei/bonului fiscal/dispozitiei de plata, etc. Daca documentul de angajare este acelasi cu documentul de plata (Ex. bon fiscal, bilet de tren, tichet de calatori), se vor completa aceleasi informatii ca cele din campul ,, Nr. document angajare cost''</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="Nr. document plata" prop="receipt_no">
                        <el-input v-model="form.receipt_no" placeholder="Introduceti numarul" type="text" maxlength="20" show-word-limit>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Selectati sau scrieti de forma ZZ.LL.AAAA</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="Data document plata" prop="receipt_date">
                        <el-date-picker v-model="form.receipt_date" type="date" value-format="yyyy-MM-dd"  format="d.M.yyyy" placeholder="Selecteaza data" style="width:100%" :picker-options="periodOptions"></el-date-picker>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Pentru promotori si pentru parteneri romani moneda utilizata este in mod obligatoriu RON </div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item :label="(showPosition ? 'Moneda pentru cost' : 'Moneda in care a fost facuta cheltuiala')" prop="currency">
                        <el-select v-model="form.currency" placeholder="Selecteaza" style="width:100%" @change="getRate">
                            <el-option key="RON" label="RON" value="RON"></el-option>
                            <el-option key="EUR" label="EUR" value="EUR"></el-option>
                            <el-option key="BGN" label="BGN" value="BGN"></el-option>
                            <el-option key="CHF" label="CHF" value="CHF"></el-option>
                            <el-option key="CZK" label="CZK" value="CZK"></el-option>
                            <el-option key="HRK" label="HRK" value="HRK"></el-option>
                            <el-option key="HUF" label="HUF" value="HUF"></el-option>
                            <el-option key="ISK" label="ISK" value="ISK"></el-option>
                            <el-option key="MDL" label="MDL" value="MDL"></el-option>
                            <el-option key="NOK" label="NOK" value="NOK"></el-option>
                            <el-option key="PLN" label="PLN" value="PLN"></el-option>
                            <el-option key="RSD" label="RSD" value="RSD"></el-option>
                            <el-option key="SIT" label="SIT" value="SIT"></el-option>
                            <el-option key="UAH" label="UAH" value="UAH"></el-option>
                            <el-option key="GBP" label="GBP" value="GBP"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Se va completa valoare totala a documentului de angajare a costului (100%), indiferent daca aceasta se raporteaza impartit pe mai multe proiecte/activitati generale .
                            <br/>In cazul salariilor se va completa cu costul total salarial pentru fiecare persoana raportata, aferent intregului contract individual de munca pentru care se raporteaza, indiferent de procentul de repartizare in cadrul proiectului.
                            <br/>ATENTIE! Nu puteti raporta un salariat care a fost raportat si la costuri directe, indiferent de perioada.</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="Valoare totala document cost" prop="amount_cost">
                        <el-input v-model="form.amount_cost" placeholder="0"
                                  @focus="$event.target.select()" @input="recalculateTotal" show-word-limit style="text-align:right" @change="getRate">
                            <template slot="append">{{form.currency}}</template>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-6 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Se completeaza cu partea din procentul de repartizare a costurilor indirecte care se solicita din finantare nerambursabila.
                            <br/>Nu pot fi alocate proiectului cheltuieli aferente
                            <br/>costurilor indirecte în proporţie de 100% (finantare+cofinanatre) din valoarea unui document justificativ (facturi, etc ) ci doar
                            <br/>suma rezultată prin aplicarea procentului din metodologia de repartizare a costurilor indirecte asupra valorii totale a documentului.
                            <br/>Procentul total din metodologie poate fi repartizat, dupa caz, integral pe finantare nerambursabila, integral pe cofinantare sau
                            <br/>impartit pe finantare nerambursabila si cofinantare.</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="Din care cheltuieli finantare" prop="amount_financing_percent">
                        <el-input v-model="form.amount_financing_percent" placeholder="0"  style="width:40%"
                                  show-word-limit @input="getRate2" @focus="$event.target.select()">
                            <template slot="append">%</template>
                        </el-input>
                        <el-input :model="form.amount_financing" size="mini" :value="((form.amount_financing_percent/100)*form.amount_cost).toFixed(2)" type="number"
                                  style="width:54%;margin-top:5px;" disabled>
                            <template slot="append">{{form.currency}}</template>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-6 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Se completeaza cu partea din procentul de repartizare a costurilor indirecte care se solicita din cofinantare.
                            <br/>Nu pot fi alocate proiectului cheltuieli aferente costurilor
                            <br/>indirecte în proporţie de 100% (finantare+cofinanatre) din valoarea unui document justificativ (facturi, etc ) ci doar suma rezultată
                            <br/>prin aplicarea procentului din metodologia de repartizare a costurilor indirecte asupra valorii totale a documentului. Procentul total
                            <br/>din metodologie poate fi repartizat, dupa caz, integral pe finantare nerambursabila, integral pe cofinantare sau impartit pe finantare
                            <br/>nerambursabila si cofinantare.</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="Din care cheltuieli cofinantare" prop="amount_cofinancing_percent">
                        <el-input v-model="form.amount_cofinancing_percent" placeholder="0"  style="width:40%"
                                  show-word-limit @input="getRate3" @focus="$event.target.select()">
                            <template slot="append">%</template>
                        </el-input>
                        <el-input :model="form.amount_cofinancing" size="mini" :value="((form.amount_cofinancing_percent/100)*form.amount_cost).toFixed(2)"
                                  style="width:54%;margin-top:5px;" disabled>
                            <template slot="append">{{form.currency}}</template>
                        </el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 form-group">
                    <el-form-item label="Curs EURO">
                        <el-input v-model="currentRate" type="text" disabled align="right"></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Cheltuieli din finantare">
                        <el-input  :model="form.total_financing_spent" :value="formatPrice(form.amount_financing / currentRate)" type="text" disabled align="right">
                            <template slot="append">€</template>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Cheltuieli din cofinantare">
                        <el-input :model="form.total_cofinancing_spent"  :value="formatPrice(form.amount_cofinancing / currentRate)" type="text" disabled align="right">
                            <template slot="append">€</template>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="COST TOTAL">
                        <el-input :model="form.total_spent" :value="formatPrice((parseFloat(form.amount_financing) + parseFloat(form.amount_cofinancing))/currentRate)"
                                  type="text" disabled style="text-align:right">
                            <template slot="append">€</template>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-6 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Se completeaza cu suma aferenta dezvoltarii organizationale.
                            <br/>ATENTIE! Acest cost se completeaza doar la activitatea de dezvoltare organizationala conform bugetului;</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="Partea costului total raportat care intra pe componenta de dezvoltare organizationala" class="form-group">
                        <el-input v-model="form.dezv" placeholder="0"  @focus="$event.target.select()">
                            <template slot="append">€</template>
                        </el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="alert alert-danger fade show" v-if="!okIndirects2()">
                <i class="icofont-warning"></i>Valoarea costurilor indirecte introduse depaseste valorea proiectului! Modifica valorile introduse.
            </div>
            <div class="row">
                <div class="col-sm-12 text-right mb-5">
                    <el-button @click.prevent="cancelRecord">Anulează, m-am răzgândit</el-button>
                    <el-button @click.prevent="clear">Goleste formularul</el-button>
                    <el-button @click.prevent="addRecord" type="secondary">Aplică modificările</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from './../../mixins/common';
    import moment from 'moment';
    import VueScrollTo from 'vue-scrollto';
    export default {
        name: "reporting-activity-form",
        mixins:[mixin],
        data(){
            return {
                indirects:[],
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                showPosition: false,
                showVolunteer: false,
                loading:false,
                form:{
                    category: 106,
                    budget: 187,
                    dezv: 0,
                    amount_cost: 0,
                    amount_financing: 0,
                    amount_financing_percent: 0,
                    amount_cofinancing: 0,
                    amount_cofinancing_percent: 0,
                    total_financing_spent: 0,
                    total_cofinancing_spent: 0,
                    total_spent: 0,
                },
                minDate: new Date(),
                maxDate: new Date(),
                periodOptions: {
                    disabledDate: this.disabledDate
                },
                currentRate: 1,
                drawer: false,
                rules: {
                    partner: [
                        {required: true, message: 'Aplicantul sau partenerul este obligatoriu', trigger: 'blur'},
                    ],
                    supplier: [
                        {required: true, message: 'CUI-ul/CNP-ul este obligatoriu', trigger: 'blur'},
                    ],
                    supplier_name: [
                        {required: true, message: 'Denumirea/numele este obligatoriu', trigger: 'blur'},
                    ],
                    description: [
                        {required: true, message: 'Descrierea este obligatorie', trigger: 'blur'},
                    ],
                    invoice_no: [
                        {required: true, message: 'Numarul documentului este obligatoriu', trigger: 'blur'},
                    ],
                    invoice_date: [
                        {required: true, message: 'Data documentului este obligatoriu', trigger: 'blur'},
                    ],
                    receipt_no: [
                        {required: true, message: 'Numarul documentului este obligatoriu', trigger: 'blur'},
                    ],
                    receipt_date: [
                        {required: true, message: 'Data documentului este obligatoriu', trigger: 'blur'},
                    ],
                    amount_cost: [
                        {required: true, message: 'Valoarea este obligatorie', trigger: 'blur'},
                    ],
                    amount_financing_percent: [
                        {required: true, message: 'Valoarea este obligatorie', trigger: 'blur'},
                    ],
                    amount_cofinancing_percent: [
                        {required: true, message: 'Valoarea este obligatorie', trigger: 'blur'},
                    ],
                },
            }
        },
        computed:{
            ...mapGetters({
                // rates: 'rates',
                dayRate: 'dayRate',
                costCategories: 'project/costCategories',
                budgetLines: 'project/budgetLines',
                // units: 'project/units',
                getProject: 'project/project',
                validateIndirect: 'reportings/validateIndirect',
                validationErrorsExists: 'validationErrorsExists',
                reporting: 'reportings/reporting',
            }),
            project(){
                return this.getProject;
            }
        },
        watch:{
            validateIndirect: function(){
                if(this.validateIndirect) {
                    this.submitForm();
                }
            },
            reporting: function(newVal){
                // console.log(newVal.activities);
                if(newVal.indirects) {
                    this.indirects = newVal.indirects;
                    // this.form.line = newVal.indirects.length + 1;
                    this.form.line =  this.indirects.length > 0 ? this.indirects.reduce((a,b) => Number(a.line) > Number(b.line) ? a.line : b.line,1) + 1 : 1;
                }

                if(this.reporting.type_report === 2)
                {
                    this.minDate = moment(this.project.implementation_start_date);
                    this.maxDate = moment(this.project.implementation_end_date).add(30,'days');
                }
                else
                {
                    this.minDate = moment(newVal.start_date);
                    this.maxDate = moment(newVal.end_date);
                }
            }
        },
        mounted(){
            this.clear();
            if(this.reporting.type_report === 2)
            {
                this.minDate = moment(this.project.implementation_start_date);
                this.maxDate = moment(this.project.implementation_end_date).add(30,'days');
            }
            else
            {
                this.minDate = moment(this.reporting.start_date);
                this.maxDate = moment(this.reporting.end_date);
            }

            // this.minDateRep = moment(this.reporting.start_date);
            // this.maxDateRep = moment(this.reporting.end_date);

            // this.contractMinDate = moment(this.project.implementation_start_date);
            // this.contractMaxDate = moment(this.project.implementation_end_date);

        },
        methods:{
            ...mapActions({
                getBudgetLines: 'project/getBudgetLines',
                searchAnafCompany: 'searchAnafCompany',
                getDateRates: 'getDateRates',
                saveIndirectLine: 'reportings/saveIndirectLine',
                removeIndirectLine: 'reportings/removeIndirectLine',
            }),
            ...mapMutations({
                setValidateIndirect: 'reportings/setValidateIndirect',
                setValidationErrorsExists: 'setValidationErrorsExists',
            }),
            disabledDate(date) {
                if(moment(date) >= this.minDate && moment(date) <= this.maxDate){
                    return false;
                }else{
                    return true;
                }
            },
            getRate(){
                if(this.form.invoice_date && this.form.currency){
                    this.getDateRates([moment(new Date(this.form.invoice_date)).format('YYYY-MM-DD'),this.form.currency])
                        .then((result) => {
                            this.currentRate = result.value;
                        });
                }
            },
            recalculateTotal() {
                this.form.amount_financing = ((this.form.amount_financing_percent/100)*this.form.amount_cost).toFixed(2);
                this.form.amount_cofinancing = ((this.form.amount_cofinancing_percent/100)*this.form.amount_cost).toFixed(2);
            },
            getRate2(){
                this.form.amount_financing = ((this.form.amount_financing_percent/100)*this.form.amount_cost).toFixed(2);
            },
            getRate3() {
                this.form.amount_cofinancing = ((this.form.amount_cofinancing_percent/100)*this.form.amount_cost).toFixed(2);
            },
            handleSelectCui(item){
                this.form.supplier_name = item.label;
            },
            updatePosition(){
                this.showPosition = this.form.category.id === 99;
                this.getBudgetLines(this.form.category.id);
                // this.getUnits(this.form.category.id === 99 ? 99 : 0);
            },
            updateSupplier(){
                this.form.supplier = this.form.position.first_name + ' ' + this.form.position.last_name;
            },
            updateLine(){
                this.showPosition = this.form.category.id === 99;
                if(this.form.budget.id === 108) {
                    this.showVolunteer = true;
                }else{
                    this.showVolunteer = false;
                }
            },
            getCompanies(query, cb) {
                if (query !== '') {
                    // setTimeout(() => {
                    this.searchAnafCompany(query).then(result => {
                       cb(result);
                    });
                    // }, 200);
                }
            },
            cancelRecord(){
                this.clear();
                VueScrollTo.scrollTo('#indirect');
            },
            okIndirects2(){
                let total = 0;
                let totalIndirects = 0;
                this.reporting.activities.forEach((element) => {
                    if(element.budget_id === 107) {
                        total += (parseFloat(element.amount_financing)+parseFloat(element.amount_cofinancing))/parseFloat(element.rate);
                    }
                });
                total = parseFloat(total*this.project.indirect_budget);
                if (this.reporting.indirects !== undefined) {
                    this.reporting.indirects.forEach((element) => {
                        totalIndirects += (parseFloat(element.amount_financing)+parseFloat(element.amount_cofinancing))/parseFloat(element.rate);
                    });
                }
                totalIndirects += parseFloat(this.form.amount_financing);

                return total < totalIndirects ? false : true;
                //return true;
            },
            addRecord(){
                //if(!this.okIndirects2()) return this.$notify({ type: 'warning', title: 'Buget', message: 'Valoarea costurilor indirecte introduse depaseste valorea proiectului! Modifica valorile introduse.'});
                this.$refs['reportingIndirectForm'].validate((valid) => {
                    if (valid) {
                        this.loading = false;
                        this.form.rate = this.currentRate;
                        //console.log(this.form.total_financing_spent);

                       // this.form.rate = this.currentRate;
                        //this.form.id = this.reporting.id;
                        this.form.project_id = this.reporting.project_id;
                        console.log(this.form.amount_cofinancing);
                        console.log(this.form.amount_financing);
                        console.log(this.form.amount_cost);
                        if ((parseFloat(this.form.amount_cofinancing) + parseFloat(this.form.amount_financing)) > parseFloat(this.form.amount_cost)) {
                            this.$notify({ type: 'error', title: 'Indirecte', message: 'Finantare + cofinantare trebuie sa fie mai mic de cost total'});
                            //this.clear();
                            return false;
                        }
                        let totalBudget = 0;
                        // let filteredBudget = this.project.indirects.filter(function(obj) {
                        //     return (obj.budget_id === value);
                        // });
                        this.reporting.indirects.forEach(function(e){
                            totalBudget += parseFloat(e.amount_cost);
                        });
                        let value = totalBudget * this.currentRate;
                        console.log(totalBudget);
                        console.log('---');
                        console.log(this.reporting.indirectMaxValue);
                        if (parseFloat(value) > parseFloat(this.reporting.indirectMaxValue)) {
                            this.$notify({ type: 'error', title: 'Indirecte', message: 'Valoarea introdusa depaseste valoarea maxima permisa conform procentului din proiect'});
                            return false;
                        }

                        this.form.line = this.indirects.length > 0 ? this.indirects.reduce((a,b) => Number(a.line) > Number(b.line) ? a.line : b.line,1) + 1 : 1;
                        this.form.reporting_id = this.reporting.id;
                        //console.log(this.form.amount_financing_percent, this.form.amount_cofinancing_percent);
                        if (parseInt(this.form.amount_financing_percent) + parseInt(this.form.amount_cofinancing_percent) >= 100) {
                            this.$notify({ type: 'error', title: 'Indirecte', message: 'Procent finantare + procent cofinantare trebuie sa fie mai mic de 100%'});
                            return false;
                        }
                        if(this.form.line > this.indirects.length) {
                            this.indirects.push(this.form);
                        }else{
                            this.indirects[this.form.line - 1] = this.form
                        }
                        this.saveIndirectLine(this.form).then(() => {
                            this.$notify({ type: 'success', title: 'Cheltuieli', message: 'Înregistrarea a fost adăugată cu succes!'});
                            this.clear();
                            VueScrollTo.scrollTo('#reportingIndirectForm');
                            this.loading = false;
                        })

                        this.setValidateIndirect(false);
                        VueScrollTo.scrollTo('#indirect');
                        this.clear();
                        return true;
                    } else {
                        VueScrollTo.scrollTo('#reportingIndirectForm');
                        return false;
                    }
                });
            },
            handleSelectRow(line, row){
                this.form = row;
                this.form.line = line;
                if(!row.partner){
                    this.form.partner = '0';
                }
                this.updatePosition();
                this.getRate();
                VueScrollTo.scrollTo('#reportingIndirectForm');
            },
            clear(){
                this.form =  {
                    // line: this.indirects.length + 1,
                    line: this.indirects.length > 0 ? this.indirects.reduce((a,b) => Number(a.line) > Number(b.line) ? a.line : b.line,1) + 1 : 1,
                    currency: 'RON',
                    category: {
                        id: 106,
                        value: '2. COSTURI INDIRECTE'
                    },
                    budget: {
                        id: 187,
                        value: '2.1. Costuri indirecte'
                    },
                    supplier:'',
                    supplier_name:'',
                    dezv: 0,
                    amount_cost: 0,
                    amount_financing: 0,
                    amount_financing_percent: 0,
                    amount_cofinancing: 0,
                    amount_cofinancing_percent: 0,
                    total_financing_spent: 0,
                    total_cofinancing_spent: 0,
                    total_spent: 0,
                };
                this.updatePosition();
            },
            handleDeleteRow(line, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi aceasta inregistrare? Acest proces este ireversibil!')
                    .then(_ => {
                        if (line !== -1) {
                            if(row.id > 0) {
                                this.removeIndirectLine({
                                    pid: row.project_id,
                                    rid: row.reporting_id,
                                    line: row.id
                                });
                                this.indirects.splice(line, 1);
                            }else {
                                this.indirects.splice(line, 1);
                            }
                            this.$notify({ type: 'success', title: 'Linie buget', message: 'Inregistrarea a fost stearsa cu succes!'});
                            this.clear();
                        }
                    }).catch(err => {
                        console.log(err)
                    });
            },
            submitForm() {
                // if (this.activity.length > 0) {
                    this.reporting.indirects = this.indirects;
                    this.setValidateIndirect(false);
                    this.setValidationErrorsExists(false);
                    VueScrollTo.scrollTo('#indirect');
                    return true;
                // } else {
                //     this.setValidationErrorsExists(true);
                //     this.$notify({ type: 'warning', title: 'Buget', message: 'Trebuie sa adaugati cel putin o linie de cheltuieli!'});
                //     return false;
                // }
            },
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
