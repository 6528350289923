<template>
    <el-form :model="reporting" :rules="rules"  ref="general" id="general" label-position="top" :status-icon="true" :inline-message="true">
        <div class="row">
            <div class="col-sm-6">
                <label>Numar contract finantare</label>
                <p>{{ project.funding_number }}</p>
            </div>
            <div class="col-sm-6">
                <label>Titlul proiectului</label>
                <p>{{ project.name }}</p>
            </div>
            <div class="col-sm-6">
                <label>Numele promotorului</label>
                <p>{{project.promoter.name}}</p>
            </div>
            <div class="col-sm-6">
                <label>Numele prestatorului de servicii de audit</label>
                <p>{{ reporting.names }}</p>
            </div>
            <div class="col-sm-6">
                <label>Perioada auditata</label>
                <p>{{ formatUserDate(project.implementation_start_date) }} - {{ formatUserDate(project.implementation_end_date) }}</p>
            </div>
        </div>
    </el-form>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from './../../mixins/common';
    import moment from 'moment';
    import VueScrollTo from 'vue-scrollto';
    import numeral from 'numeral';
    export default {
        name: "reporting-general-form",
        mixins:[mixin],
        data(){
            return {
                rules: {
                    start_date: [
                        { required: true, message: 'Selectați data de început', trigger: 'blur' }
                    ],
                    end_date: [
                        { required: true, message: 'Selectați data de final', trigger: 'blur' }
                    ],
                    type_report: [
                        { required: true, message: 'Selectați tipul raportului', trigger: 'blur' },
                    ],
                    collected: [
                        { required: true, message: 'Completati cu avansul sau ultima transa incasata, dupa caz.', trigger: 'blur' }
                    ],
                    collected_date: [
                        { required: true, message: 'Selectați data încasării', trigger: 'change' }
                    ],
                },
                isDisabled: true,
                disDate: 0,
                projectPeriodOptions: {
                    disabledDate: this.disabledPeriodDate
                },
                type_final_disabled: false,
            }
        },
        computed:{
            ...mapGetters({
                reporting: 'reportings/reporting',
                project: 'project/project',
                validateGeneral: 'reportings/validateGeneral',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        watch:{
            validateGeneral: function(){
                if(this.validateGeneral) {
                    this.setValidateGeneral(false);
                    this.submitForm();
                }
            },
        },
        mounted(){
            //console.log(this.project);
            this.reporting.project_id = this.project.id;
            this.contractMinDate = moment(this.project.project_start_date);
            this.contractMaxDate = moment(this.project.project_end_date);

        },
        methods:{
            ...mapMutations({
                setValidateGeneral: 'reportings/setValidateGeneral',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            disabledPeriodDate(date) {

                return !(date >= this.contractMinDate && date <= this.disDate);
            },
            UPDB()
            {
                axios.post('/reportings/getGraph', {id: this.reporting.graph_id}).then((result) => {
                    this.disDate = moment(result.data.data);
                });
                this.isDisabled = false;
                this.disDate = moment(this.reporting.graph_id.date);
                // this.isDisabled = this.reporting.graph_id ? false : true;
                // this.reporting.graph_id = this.reporting.graph_id.reporting_id;
            },
            submitForm() {
                this.$refs['general'].validate((valid) => {
                    if (valid) {
                        this.setValidationErrorsExists(false);
                        return true;
                    } else {
                        VueScrollTo.scrollTo('#general');
                        this.setValidationErrorsExists(true);
                        return false;
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
