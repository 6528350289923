<template>
    <div>
        <div class="row">
            <div class="col-sm-22">
                <h3>
                    Centralizator Resurse Umane
                    <a href="#" @click.prevent="openUrl('/reportings/exportHr/'+reporting.id, true)" title="Export in Excel" class="btn btn-sm btn-secondary ml-5">
                        <i class="icofont-file-excel"></i> Export Excel
                    </a>
                </h3>
            </div>
            <div class="col-sm-12">
                <div class="row">
                    <div class="alert alert-danger">Selecteaza o activitate pentru a incarca tabelul cu date!</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <el-tabs>
                    <el-tab-pane :label="'A'+activity.activity" :name="'A'+activity.activity" v-for="activity in project.activities" :key="activity.id">
                    <template slot="label">
                        <el-tooltip position="top">
                            <div slot="content">{{ activity.name }}</div>
                            <span>A{{ activity.activity }}</span>
                        </el-tooltip>
                    </template>
                    <el-table size="mini"
                              :data="hrReport[activity.id]"
                              style="width: 100%">
                        <el-table-column show-overflow-tooltip prop="category.value" label="Luna aferenta perioadei raportate" width="80">
                            <template slot-scope="scope">
                                {{ formatNewDate(scope.row.month) }}
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="Cod Promotor/Partener" width="200">
                            <template slot-scope="scope">
                            <span v-if="scope.row.partner_id !== project.promoter_id">
                               <span v-if="scope.row.partner_id">
                                    P{{ scope.row.partner_id }}
                               </span>
                               <span v-else>
                                   A
                               </span>
                            </span>
                                <span v-else>
                                A
                            </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Activitatea" align="left">
                            <template slot-scope="scope" >
                                <span >A{{scope.row.activity}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Pozitia in cardul proiectului" align="left" width="120">
                            <template slot-scope="scope" v-if="scope.row.position !== null">
                                {{ scope.row.position }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Nume si prenume" align="left"  header-align="center" width="90">
                            <template slot-scope="scope">
                                <span>{{ scope.row.first_name }} {{ scope.row.last_name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="CNP" align="center" header-align="center">
                            <template slot-scope="scope">
                                {{ scope.row.supplier }}
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip align="center" label="Nr. si data CIM / Nr. si data ctr. voluntariat">
                            <template slot-scope="scope" v-if="scope.row.position !== null">
                                {{ scope.row.cim }} - {{scope.row.cim_date}}
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip align="center" label="Salariul brut in luna conform CIM pe orgnizatie si actelor aditionale ulterioare (100%)
 - doar pentru salariati -">
                            <template slot-scope="scope">
                                {{scope.row.month_salary_cim}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Ore lucratoare in luna conform normei din CIM pe organizatie si actelor aditionale ulterioare(100%) / contract de voluntariat" align="center" header-align="center">
                            <template slot-scope="scope">
                                {{scope.row.month_hours_cim}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Ore efectiv lucrate in luna / activitate" align="center" header-align="center">
                            <template slot-scope="scope">
                                {{ scope.row.worked_hours_sum }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Cost total raportat in luna aferent orelor lucrate/activitate" align="right"  header-align="center">
                            <template slot-scope="scope">
                                {{ formatPrice(scope.row.total_spent_sum) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Cost orar raportat in luna/activitate" align="right" header-align="center">
                            <template slot-scope="scope">
                                {{ formatPrice(parseFloat(scope.row.worked_total_spent_sum)) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Costul orar unitar bugetat /activitate" align="right" header-align="center">
                            <template slot-scope="scope">
                                {{scope.row.project}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Abatere de la costul orar bugetat" align="center"  header-align="center">
                            <template slot-scope="scope" v-if="scope.row.position !== null">
                                {{ formatNumber(parseFloat(scope.row.project) - (parseFloat(scope.row.worked_total_spent_sum))) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Justificarea depasirii costului orar bugetat" width="200" align="center"  header-align="center">
                            <template slot-scope="scope">
                                <el-tooltip effect="light" position="bottom">
                                    <div slot="content">{{ scope.row.justify_overdue }}</div>
                                    <i class="el-icon-info mt-1"></i>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="actiuni" label="Actiuni" align="right" fixed="right"  header-align="center" width="150">
                            <template slot-scope="scope">
                                <span><el-button v-if="reporting.status === 1" @click="openDrawer(scope.row)">Editeaza</el-button></span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from './../../mixins/common';
    import showInformationDrawer from "../common/modify-information-drawer";
    import UploadDrawer from "../common/upload-reporting-drawer";

    export default {
        name: "reporting-hr-form",
        mixins:[mixin],
        components: {
            UploadDrawer,
        },
        computed:{
            ...mapGetters({
                showUploadDrawer: 'showUploadDrawer',
                project: 'project/project',
                reporting: 'reportings/reporting',
                hrReport: 'reportings/hrReport',
            }),
        },
        watch:{
            showUploadDrawer:function(){
                if(this.showUploadDrawer.visible === false){

                }
                return this.showUploadDrawer;
            },
        },
        mounted(){
            this.getHrReport({project_id: this.project.id, reporting_id: this.reporting.id});
        },
        methods:{
            ...mapActions({
                getHrReport: 'reportings/getHrReport',
            }),
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
            }),
            openDrawer(object){
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\ReportingValidationActivity',
                    model_id: object.id,
                    category_id: 0,
                    acceptFiles: '.pdf,.docx,.doc', //.docx,.doc,
                    multiple: true,
                    title: 'Incarca document',
                    type: 1,
                    object: object,
                });
            },
        },

    }
</script>

<style scoped>

</style>
