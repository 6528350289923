<template>
    <div>
        <el-drawer title="Încarcă fișiere" :visible.sync="showUploadDrawer.visible" direction="rtl" :wrapperClosable="false" :before-close="handleCloseDrawer" :destroy-on-close="true">
            <div class="px-4">
                <p class="alert alert-info">{{ $t(infoMessage) }}</p>
                <h5>{{ showUploadDrawer.title ? showUploadDrawer.title : title }}</h5>
                <el-upload ref="upload" action="/api/upload" :data="showUploadDrawer"
                           :with-credentials="true" :auto-upload="true"
                           :headers="{ 'X-CSRF-TOKEN': csrf}"
                           :multiple="!showUploadDrawer.multiple ? false : true"
                           :accept="showUploadDrawer.acceptFiles ? showUploadDrawer.acceptFiles : acceptFiles"
                           :on-remove="handleRemove" :before-remove="beforeRemove" :on-preview="handlePreview"
                           :on-error="handleUploadError" :on-success="handleUploadSuccess" :on-exceed="handleOnExceed"
                           :limit="showUploadDrawer.multiple === false ? 1 : 10"
                           :file-list="showUploadDrawer.files">
                    <el-button slot="trigger" size="small" type="secondary">{{ btnSelectText }}</el-button>
                    <!--<el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">{{ btnImportText }}</el-button>-->
                    <div class="el-upload__tip" slot="tip">{{ $t(infoTip) }}</div>
                </el-upload>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import {mapGetters,mapMutations,mapActions} from "vuex";
    export default {
        name: "upload-drawer",
        data() {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                title: 'Import fișier',
                infoMessage: 'Încărcați numai fișiere de tip {{acceptFiles}}, cu dimensiunea maximă de {{maxSize}}',
                infoTip: 'Sunt permise doar fișiere {{acceptFiles}} cu dimensiunea maximă de {{maxSize}}',
                btnImportText: 'Importă',
                btnSelectText: 'Selectează fișier(e)',
                acceptFiles: '.pdf',
                model: '',
                model_id: 0,
                maxSize: '2MB',
                fileList: [],
                showComments: true,
                showDrawer: false
            }
        },
        computed:{
            ...mapGetters({
                showUploadDrawer: 'showUploadDrawer',
                reporting: 'reportings/reporting',
                notification: 'notification/notification',
            }),
            translate(text){
                return this.$t(text);
            }
        },
        methods:{
            ...mapActions({
                removeUploadedFile: 'removeUploadedFile',
            }),
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
            }),
            beforeRemove(file, fileList) {
                return this.$confirm(`Ești sigur(ă) că vrei să ștergi fișierul ${ file.name } din listă ?`);
            },
            handleRemove(file, fileList) {
                // console.log(file);
                if(typeof this.showUploadDrawer.updateCommentFiles !== 'undefined') this.showUploadDrawer.updateCommentFiles.files.splice(file, 1);
                if(typeof this.showUploadDrawer.updateAfterCloseSignedDocument !== 'undefined'){
                    let index = this.showUploadDrawer.files.map(function(item) {
                        return item.id
                    }).indexOf(file.id);
                    this.showUploadDrawer.files.splice(index, 1);
                }
                if(typeof this.showUploadDrawer.reportingDocument !== 'undefined') this.reporting.documents.splice(file, 1);
                if(typeof this.showUploadDrawer.reportingDocumentFinal !== 'undefined') this.reporting.documents_info_address.splice(file, 1);
                if(file.response) {
                    this.removeUploadedFile(file.response.file_id);
                }else if(file.id){
                    this.removeUploadedFile(file.id);
                }
            },
            handlePreview(file, fileList) {
                window.open('/getFile/'+(typeof file.response !== "undefined" ? (file.response.file_id) : (file.id)), '_blank');
            },
            handleUploadError(file){
                console.log(file);
            },
            handleOnExceed(){
                this.$notify({ type: 'warning', title: 'Import', message: 'Este permisa incarcarea unui singur fisier!'});
            },
            handleUploadSuccess(response, file, fileList){
                if(response.errors){
                    let messages = Object.values(response.errors).flat();
                    this.$notify({ type: 'warning', title: 'Import', message: messages.toString()});
                }else if(response.success === true){
                    if(typeof this.showUploadDrawer.updateCommentFiles !== 'undefined') this.showUploadDrawer.comments.files.push(file);
                    if(typeof this.showUploadDrawer.updateAfterCloseSignedDocument !== 'undefined') this.showUploadDrawer.files.push(file);
                    if(typeof this.showUploadDrawer.reportingDocument !== 'undefined') this.reporting.documents.push(file);
                    if(typeof this.showUploadDrawer.reportingDocumentFinal !== 'undefined') this.reporting.documents_info_address.push(file);
                    this.handleCloseDrawer();
                    this.$notify({ type: 'success', title: 'Import', message: "Fisierul a fost importat cu success!"});
                    // this.$refs.upload.clearFiles();
                }
            },
            handleCloseDrawer(done){
                this.setShowUploadDrawer({
                    visible: false
                });
            },
            submitUpload(){
                this.$refs.upload.submit();
            },
            $t (str) {
                var reg = /\{\{(.*?)\}\}/g
                // var res
                var found
                while (found = reg.exec(str)) {
                    if (this.showUploadDrawer[found[1]]) {
                        str = str.replace(found[0], this.showUploadDrawer[found[1]])
                    }else if(this.$data[found[1]]) {
                        str = str.replace(found[0], this.$data[found[1]])
                    } else {
                        str = str.replace(found[0], '--')
                    }
                }
                return str
            },
        }
    }
</script>

<style scoped>

</style>
