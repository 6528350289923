<template>
    <el-form :model="reporting" ref="final" id="final" label-position="top" :status-icon="true" :inline-message="false">
        <div class="row">
            <div class="col-sm-12">
                <h3>Finalizare & Documente suplimentare</h3>
            </div>
            <div class="col-sm-12">
                <div class="form-group">
                    <h6 class="text-muted" style="margin-bottom:0;font-style: italic;">
                        In cazul in care doresti sa incarci documente suplimentare, apasa butonul "Incarca documente".<br/>
                        Atentie! După apăsarea butonului ”<font style="color:green;">Transmite nota de debit</font>”, acesta va putea fi doar vizualizat.
                    </h6>
                    <el-button type="secondary" class="float-right" icon="el-icon-edit" @click="uploadDocument()">
                      <span>Incarca documente suplimentare</span>
                    </el-button>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from './../../mixins/common';
    import moment from 'moment';
    import numeral from 'numeral';
    import VueScrollTo from "vue-scrollto";
    export default {
        name: "reporting-final-form",
        mixins:[mixin],
        data(){
            return {
                
            }
        },
        computed:{
            ...mapGetters({
                reporting: 'reportings/reporting',
                validateFinal: 'reportings/validateFinal',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        watch:{
            validateFinal: function(){
                if(this.validateFinal) {
                    this.setValidateFinal(false);
                    this.submitForm();
                }
            },
        },
        methods:{
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
                setValidateFinal: 'reportings/setValidateFinal',
                setValidationErrorsExists: 'setValidationErrorsExists',
            }),
            uploadDocument()
            {
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Reporting',
                    model_id: this.reporting.id,
                    category_id: 558,
                    acceptFiles: '.pdf,.docx,.doc,.jpg,.xls',
                    multiple: true,
                    title: 'Vizualizare documente',
                    files: this.reporting.documents_info_address,
                    reportingDocumentFinal: 1,
                });
            },
            submitForm() {
                this.$refs['final'].validate((valid) => {
                    if (valid) {
                        this.setValidateFinal(false);
                        this.setValidationErrorsExists(false);
                        VueScrollTo.scrollTo('#final');
                        return true;
                    } else {
                        VueScrollTo.scrollTo('#final');
                        this.setValidationErrorsExists(true);
                        return false;
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
