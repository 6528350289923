export const projects = (state) => { return state.projects };
export const project = (state) => { return state.project };
export const appeals = (state) => { return state.appeals };
export const locations = (state) => { return state.locations };
export const rounds = (state) => { return state.rounds };
export const grants = (state) => { return state.grants };
export const medium = (state) => { return state.medium };
export const framing = (state) => { return state.framing };
export const priorities = (state) => { return state.priorities };
export const prioritiesNew = (state) => { return state.prioritiesNew };
export const policies = (state) => { return state.policies };
export const companyTypes = (state) => { return state.companyTypes };
export const documentCategories = (state) => { return state.documentCategories };
export const workloads = (state) => { return state.workloads };
export const costCategories = (state) => { return state.costCategories };
export const budgetLines = (state) => { return state.budgetLines };
export const units = (state) => { return state.units };
export const indirects = (state) => { return state.indirects };
export const outcomes = (state) => { return state.outcomes };
export const outputs = (state) => { return state.outputs };
export const outcomeIndicators = (state) => { return state.outcomeIndicators };
export const outputIndicators = (state) => { return state.outputIndicators };
export const users = (state) => { return state.users };

export const validateDetails = (state) => { return state.validateDetails };
export const validateDescription = (state) => { return state.validateDescription };
export const validateActivities = (state) => { return state.validateActivities };
export const validateDocuments = (state) => { return state.validateDocuments };
export const validatePartners = (state) => { return state.validatePartners };
export const validateObjectives = (state) => { return state.validateObjectives };
export const validateTeam = (state) => { return state.validateTeam };
export const validateIndicators = (state) => { return state.validateIndicators };
export const validateCalendar = (state) => { return state.validateCalendar };
export const validateActivity = (state) => { return state.validateActivity };
export const validateIndirects = (state) => { return state.validateIndirects };
export const validateFinancing = (state) => { return state.validateFinancing };
export const validateValidation = (state) => { return state.validateValidation };
