<template >
    <div v-loading="loading">
        <!--  Header Info -->
        <el-tabs v-model="tabGeneral" @tab-click="handleClick">
            <el-tab-pane label="Date raportare" name="first">
                <div class="row">
                    <div class="col-sm-2">
                        <label>Raportarea</label>
                        <p v-if="reporting.graph">{{ reporting.graph.reporting_id }}</p>
                    </div>
                    <div class="col-sm-2">
                        <label>Tip raport</label>
                        <p v-if="reporting.type_report === 1">Intermediar</p><p v-else>Final</p>
                    </div>
                    <div class="col-sm-2">
                        <label>Perioadă raportată</label>
                        <p>{{formatUserDate(reporting.start_date)}} - {{formatUserDate(reporting.end_date)}}</p>
                    </div>
                    <div class="col-sm-2">
                        <label>Ultima sumă încasată</label>
                        <p>{{ formatPrice(reporting.collected) }}</p>
                    </div>
                    <div class="col-sm-2">
                        <label>Data încasării</label>
                        <p>{{reporting.collected_date}}</p>
                    </div>
                    <div class="col-sm-2">
                        <label>Stare</label>
                        <p>
                            <span class="badge" style="background-color:#FDD9B5;" v-if="reporting.status === 1">Ciornă</span>
                            <span class="badge" style="background-color:#B99ABF" v-if="reporting.status === 2">Trimis</span>
                            <span class="badge badge-success" v-if="reporting.status === 3">Aprobat</span>
                            <span class="badge badge-danger" v-if="(reporting.status === 4 || reporting.status === 7) && validAnswerLimit(reporting.answer_limit)">Necesită modificări</span>
                            <span class="badge badge-danger" v-if="(reporting.status === 4 || reporting.status === 7) && !validAnswerLimit(reporting.answer_limit)">Transmis fara raspuns la SC</span>
                            <span class="badge" style="background-color:#FDD9B5;" v-if="reporting.status === 5">Ciornă - fără modificări</span>
                            <span class="badge badge-danger" v-if="reporting.status === 6">Audit</span>
                        </p>
                    </div>
                    <div class="col-sm-3">
                        <label>Data transmiterii</label>
                        <p>{{formatUserDateTime(reporting.updated_at)}}</p>
                    </div>
                    <div class="col-sm-3">
                        <label>Numar contract finantare</label>
                        <p>{{project.funding_number}}</p>
                    </div>
                    <div class="col-sm-2">
                        <label>Tip grad de risc</label>
                        <p v-if="typeof reporting.questions !== 'undefined'">
                            <span class="badge" style="background-color:#FDD9B5;" v-if="typeof reporting.questions !== 'undefined' && reporting.questions.risk <= 30">Scazut</span>
                            <span class="badge" style="background-color:#FDD9B5;" v-if="typeof reporting.questions !== 'undefined' && reporting.questions.risk > 30 && reporting.questions.risk <= 50">Mediu</span>
                            <span class="badge" style="background-color:#FDD9B5;" v-if="typeof reporting.questions !== 'undefined' && reporting.questions.risk > 50">Crescut</span>
                        </p>
                    </div>
                    <div class="col-sm-3">
                        <label>Tip grant</label>
                        <p v-if="project.grant">{{project.grant.value}}</p>
                    </div>
                    <div class="col-sm-3">
                        <label>Perioada de implementare</label>
                        <p>{{ formatUserDate(project.implementation_start_date) }} - {{ formatUserDate(project.implementation_end_date) }}</p>
                    </div>
                    <div class="col-sm-3">
                        <label>Durata de implementare</label>
                        <p>{{ datesDifference(project.implementation_start_date, project.implementation_end_date) }}</p>
                    </div>
                    <div class="col-sm-3">
                        <label>Documente semnate incarcate de promotor</label>
                        <p><el-button  @click="viewDocument()" size="mini" type="secondary">Vezi documentele</el-button></p>
                    </div>
                    <div class="col-sm-3">
                        <label>Documente utilizate in verificarea raportului</label>
                        <p><el-button  @click="openDrawerDOC()" size="mini" type="secondary">Vezi/Incarca Documente</el-button></p>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Istoric modificari" name="second">
                <el-timeline>
                    <el-timeline-item
                        v-for="(row, index) in reporting.logs"
                        :key="index"
                        :timestamp="formatUserDate(row.created_at)">
                        {{ row.message }}
                    </el-timeline-item>
                </el-timeline>
            </el-tab-pane>
        </el-tabs>

        <!-- Cheltuieli -->
        <div class="form-group">
            <h3 class="mt-5">
                Cheltuieli activitati
                <a title="Export in Excel" @click.prevent="openUrl('/reportings/export/'+reporting.id, true)" class="btn btn-secondary btn-circle ml-5">
                    <i class="icofont-file-excel"></i>
                </a>
            </h3>
            <div class="alert alert-info">Selecteaza o activitate pentru a incarca cheltuielile!</div>
            <el-tabs @tab-click="selectTab">
                <el-tab-pane label="Gradul de risc" name="risk">
                    <div class="row mt-3">
                        <div class="col-sm-4">
                            <h5>Tip grad de risc:
                                <span class="badge" style="background-color:#FDD9B5;" v-if="typeof reporting.questions !== 'undefined' && reporting.questions.risk <= 30">Scazut</span>
                                <span class="badge" style="background-color:#FDD9B5;" v-if="typeof reporting.questions !== 'undefined' && reporting.questions.risk > 30 && reporting.questions.risk < 50">Mediu</span>
                                <span class="badge" style="background-color:#FDD9B5;" v-if="typeof reporting.questions !== 'undefined' && reporting.questions.risk > 50">Crescut</span>
                            </h5>
                        </div>
                        <div class="col-sm-8 text-right">
                            <a title="Export in Excel" @click.prevent="openUrl('/reportings/export-degree-risk-pdf/'+reporting.id, true)" class="btn btn-secondary ml-5">
                                Export in PDF - Gradul de risc
                            </a>
                        </div>
                    </div>
                    <el-form :model="reporting.questions" v-if="reporting.questions" ref="reporting.questions"
                             class="mt-3" label-position="top" :status-icon="true" :inline-message="true">
                        <div class="row">
                            <div class="col-sm-4">
                                <h5>Punctaj</h5>
                                <p class="text-muted mb-0" v-if="reporting.questions">Punctaj organizatie: {{reporting.questions.q1 }} + {{ reporting.questions.q2 }} + {{ reporting.questions.q3 }} + {{ reporting.questions.q4 }} + {{ reporting.questions.q5 }} + {{ reporting.questions.q6 }} = {{reporting.questions.q1  +  reporting.questions.q2  +  reporting.questions.q3  +  reporting.questions.q4  +  reporting.questions.q5 + reporting.questions.q6 }} => {{reporting.questions.company_risk}}%</p>
                                <p class="text-muted mb-0" v-if="reporting.questions">Punctaj proiect:  {{ reporting.questions.q7 }} + {{ reporting.questions.q8 }} + {{reporting.questions.q9}} + {{reporting.questions.q10}} + {{reporting.questions.q11}} + {{reporting.questions.q12}} + {{reporting.questions.q13}} = {{ reporting.questions.q7 + reporting.questions.q8 + reporting.questions.q9 + reporting.questions.q10 + reporting.questions.q11 + reporting.questions.q12 + reporting.questions.q13 }} => {{reporting.questions.project_risk}}%</p>
                                <p class="text-muted mb-0" v-if="reporting.questions">Procent risc final: {{reporting.questions.risk}}%</p>
                                <p class="text-muted mb-0" v-if="reporting.questions">Procent risc organizatie: {{reporting.questions.company_risk}}%</p>
                                <p class="text-muted mb-0" v-if="reporting.questions">Procent risc proiect: {{reporting.questions.project_risk}}%</p>
                            </div>
                            <div class="col-sm-8">
                                <p class="text-muted mb-0" v-if="reporting.questions">Formula calcul:</p>
                                <p>
                                    <small v-if="reporting.questions">Pentru raspunsurile de la 1 la 6 inclusiv, se aduna punctajul pentru fiecare intrebare in parte - in categoria organizatie</small>
                                    <small v-if="reporting.questions">Pentru raspunsurile de la 7 la 13 inclusiv, se aduna punctajul pentru fiecare intrebare in parte - in categoria proiect</small>
                                </p>
                                <div class="row">
                                    <div class="col-4"><small>Pentru organizatie - interval punctaj:</small></div>
                                    <div class="col-8">
                                        <ul class="list-unstyled">
                                            <li><small>Intre 6 si 12 - grad de risc scazut: 30%, </small><span class="badge" style="background-color:#FDD9B5;">Scazut</span></li>
                                            <li><small>Intre 13 si 18 - grad de risc mediu: 50%, </small><span class="badge" style="background-color:#FDD9B5;">Mediu  </span></li>
                                            <li><small>Intre 19 si 24 - grad de risc crescut: 80%</small><span class="badge" style="background-color:#FDD9B5;">Crescut</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4"><small>Pentru proiect - interval punctaj:</small></div>
                                    <div class="col-8">
                                        <ul class="list-unstyled">
                                            <li><small>Intre 7 si 13 - grad de risc scazut: 30%</small> <span class="badge" style="background-color:#FDD9B5;">Scazut</span></li>
                                            <li><small>Intre 14 si 20 - grad de risc mediu: 50%</small><span class="badge" style="background-color:#FDD9B5;">Mediu  </span></li>
                                            <li><small>Intre 21 si 28 - grad de risc crescut: 80%</small> <span class="badge" style="background-color:#FDD9B5;">Crescut</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <p><small>Calculul final se obtine astfel:<br />
                                    Daca riscul proiectului este mai mic decat riscul organizatiei -> riscul final = riscul organizatiei,
                                    altfel, daca riscul proiectului este mai mare decat riscul organizatiei -> riscul final = riscul proiectului</small>
                                </p>
                            </div>
                        </div>
                        <el-collapse>
                            <el-collapse-item title="" name="1">
                                <template slot="title">
                                    <h5 class="mt-2 mr-5">Raspunsuri Promotor</h5> <small>(apasa aici pentru a deschide/inchide)</small>
                                </template>
                                <div class="row mt-3">
                                    <div class="col-8 form-group">
                                        <el-form-item prop="q1" label="1. In acest moment, care este media bugetului anual administrat de organizatie in ultimii 3 ani in raport cu finantarea aferenta proiectului?">
                                            <el-radio-group v-model="reporting.questions.q1" size="mini">
                                                <el-radio-button :label="1" v-if="reporting.questions.q1 === 1" >peste dublul finanțării solicitate</el-radio-button>
                                                <el-radio-button :label="2" v-if="reporting.questions.q1 === 2" border>mai mult decât finanțarea solicitată până la dublul acesteia</el-radio-button>
                                                <el-radio-button :label="3" v-if="reporting.questions.q1 === 3" border>mai puțin sau egal cu finanțarea solicitată</el-radio-button>
                                                <el-radio-button :label="4" v-if="reporting.questions.q1 === 4" border>nu a fost administrat un buget până în prezent (organizația este nou înființată)</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4">
                                        Raspunsuri posibile:
                                        <ul>
                                            <li>1. peste dublul finanțării solicitate = 1p </li>
                                            <li>2. mai mult decât finanțarea solicitată până la dublul acesteia = 2p</li>
                                            <li>3. mai puțin sau egal cu finanțarea solicitată = 3p</li>
                                            <li>4. nu a fost administrat un buget până în prezent (organizația este nou înființată) = 4p</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 form-group">
                                        <el-form-item prop="q2" label="2. In acest moment, care este numărul total de proiecte finanţate din fonduri nerambursabile derulate de organizatie in calitate de aplicant sau partener  (inclusiv acest proiectul finanțat prin ACF)?">
                                            <el-radio-group v-model="reporting.questions.q2"  size="mini">
                                                <el-radio-button :label="1" v-if="reporting.questions.q2 === 1" border>1 proiect </el-radio-button>
                                                <el-radio-button :label="2" v-if="reporting.questions.q2 === 2" border>2-3 proiecte</el-radio-button>
                                                <el-radio-button :label="3" v-if="reporting.questions.q2 === 3" border>4-5 proiecte</el-radio-button>
                                                <el-radio-button :label="4" v-if="reporting.questions.q2 === 4" border>peste 5 proiecte</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4">
                                        Raspunsuri posibile:
                                        <ul>
                                            <li>1. 1 proiect = 1p </li>
                                            <li>2. 2-3 proiecte = 2p</li>
                                            <li>3. 4-5 proiecte = 3p</li>
                                            <li>4. peste 5 proiecte = 4p</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 form-group">
                                        <el-form-item prop="q3" label="3. In acest moment, care este valoarea cumulată a bugetelor proiectelor finanţate din fonduri nerambursabile derulate de organizaţie în calitate de aplicant sau partener (inclusiv acest proiectul finanțat prin ACF)?">
                                            <el-radio-group v-model="reporting.questions.q3" size="mini" >
                                                <el-radio-button :label="1" v-if="reporting.questions.q3 === 1" border>sub 50 000€</el-radio-button>
                                                <el-radio-button :label="2" v-if="reporting.questions.q3 === 2" border>de la 50.000€ și pana la 150.000€</el-radio-button>
                                                <el-radio-button :label="3" v-if="reporting.questions.q3 === 3" border>peste 150.000€ și pana la 250.000€ </el-radio-button>
                                                <el-radio-button :label="4" v-if="reporting.questions.q3 === 4" border>peste 250.000€ </el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4">
                                        Raspunsuri posibile:
                                        <ul>
                                            <li>1. sub 50 000€ = 1p </li>
                                            <li>2. de la 50.000€ și pana la 150.000€ = 2p</li>
                                            <li>3. peste 150.000€ și pana la 250.000€ = 3p</li>
                                            <li>4. peste  250.000€ = 4p</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 form-group">
                                        <el-form-item prop="q4" label="4. Care este numărul mediu de salariați (cu contract individual de muncă) și colaboratori permanenți (cu vechime de peste 1 an) ai organizației din ultimii 3 ani, anteriori depunerii raportului financiar?">
                                            <el-radio-group v-model="reporting.questions.q4" size="mini" >
                                                <el-radio-button :label="4" v-if="reporting.questions.q4 === 4" border>1-3 angajați/colaboratori permanenți</el-radio-button>
                                                <el-radio-button :label="3" v-if="reporting.questions.q4 === 3" border>4-6 angajați/colaboratori permanenți</el-radio-button>
                                                <el-radio-button :label="2" v-if="reporting.questions.q4 === 2" border>7-10 angajați/colaboratori permanenți</el-radio-button>
                                                <el-radio-button :label="1" v-if="reporting.questions.q4 === 1" border>peste 10 angajați/colaboratori permanenți</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4">
                                        Raspunsuri posibile:
                                        <ul>
                                            <li>1. 1-3 angajați/colaboratori permanenți = 4p </li>
                                            <li>2. 4-6 angajați/colaboratori permanenț = 3p</li>
                                            <li>3. 7-10 angajați/colaboratori permanenți = 2p</li>
                                            <li>4. peste 10 angajați/colaboratori permanenți = 1p</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 form-group">
                                        <el-form-item prop="q5" label="5. Care este fluctuația de personal a organizației din ultimii 3 ani, anteriori depunerii raportului financiar? (numărul angajaților care au plecat / totalul efectivului de angajați x 100) pe an calendaristic">
                                            <el-radio-group v-model="reporting.questions.q5" size="mini" >
                                                <el-radio-button :label="1" v-if="reporting.questions.q5 === 1">0 - 10%, inclusiv</el-radio-button>
                                                <el-radio-button :label="2" v-if="reporting.questions.q5 === 2">peste 10% si pana la 15%, inclusiv</el-radio-button>
                                                <el-radio-button :label="3" v-if="reporting.questions.q5 === 3">peste 15% si pana la 20%, inclusiv</el-radio-button>
                                                <el-radio-button :label="4" v-if="reporting.questions.q5 === 4">peste 20%</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4">
                                        Raspunsuri posibile:
                                        <ul>
                                            <li>1. 0 - 10%, inclusiv = 1p </li>
                                            <li>2. peste 10% si pana la 15%, inclusiv = 2p</li>
                                            <li>3. peste 15% si pana la 20%, inclusiv = 3p</li>
                                            <li>4. peste 20% = 4p</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 form-group">
                                        <el-form-item prop="q6" label="6. In acest moment, care este situația organizației în ceea ce privește activitatea de contabilitate?">
                                            <el-radio-group v-model="reporting.questions.q6" size="mini" >
                                                <el-radio-button :label="1" v-if="reporting.questions.q6 === 1" border>organizația are angajat permanent (cu contract individual de muncă) un contabil cu studii superioare economice</el-radio-button>
                                                <el-radio-button :label="4" v-if="reporting.questions.q6 === 4" border>organizația colaborează cu un birou contabil autorizat CECCAR</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4">
                                        Raspunsuri posibile:
                                        <ul>
                                            <li>1. organizația are angajat permanent (cu contract individual de muncă) un contabil cu studii superioare economice = 1p </li>
                                            <li>2. organizația colaborează cu un birou contabil autorizat CECCAR = 4p</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 form-group">
                                        <el-form-item prop="q7" label="7. În echipa proiectului există prevăzută poziția de responsabil financiar pentru o perioada de timp relevanta din perioada de implementare a proiectului?">
                                            <el-radio-group v-model="reporting.questions.q7" size="mini" >
                                                <el-radio-button :label="1" v-if="reporting.questions.q7 === 1" border>da, există un responsabil financiar prevăzut în echipa de proiect</el-radio-button>
                                                <el-radio-button :label="4" v-if="reporting.questions.q7 === 4" border>nu, nu există poziția de responsabil financiar prevăzut în echipa de proiect</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4">
                                        Raspunsuri posibile:
                                        <ul>
                                            <li>1. da, există un responsabil financiar prevăzut în echipa de proiect = 1p </li>
                                            <li>2. nu, nu există poziția de responsabil financiar prevăzut în echipa de proiect = 4p</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 form-group">
                                        <el-form-item prop="q8" label="8. Care este fluctuația de personalului cheie (Manager de proiect, asistent de proiect, responsabil financiar, specialisti cu CIM) de la inceputul proiectului si pana la sfarsitul perioadei de raportare?">
                                            <el-radio-group v-model="reporting.questions.q8" size="mini" >
                                                <el-radio-button :label="1" v-if="reporting.questions.q8 === 1" border>nicio schimbare - 1 schimbare</el-radio-button>
                                                <el-radio-button :label="2" v-if="reporting.questions.q8 === 2" border>2-3 schimbări</el-radio-button>
                                                <el-radio-button :label="3" v-if="reporting.questions.q8 === 3" border>4-5 schimbări</el-radio-button>
                                                <el-radio-button :label="4" v-if="reporting.questions.q8 === 4" border>de la 6 schimbări</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4">
                                        Raspunsuri posibile:
                                        <ul>
                                            <li>1. nicio schimbare - 1 schimbare = 1p </li>
                                            <li>2. 2-3 schimbări = 2p</li>
                                            <li>3. 4-5 schimbări = 3p</li>
                                            <li>4. de la 6 schimbări = 4p</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 form-group">
                                        <el-form-item prop="q9" label="9. Care este valoarea subcontractării din totalul costurilor directe eligibile ale proiectului (din buget)?">
                                            <el-radio-group v-model="reporting.questions.q9" size="mini" >
                                                <el-radio-button :label="1" v-if="reporting.questions.q9 === 1" border>sub 5% din valoarea costurilor directe</el-radio-button>
                                                <el-radio-button :label="2" v-if="reporting.questions.q9 === 2" border> de la 5% si pana la 10%, inclusiv, din valoarea costurilor directe</el-radio-button>
                                                <el-radio-button :label="3" v-if="reporting.questions.q9 === 3" border>peste 10 % si pana la 20%, inclusiv  din valoarea costurilor directe</el-radio-button>
                                                <el-radio-button :label="4" v-if="reporting.questions.q9 === 4" border>peste 20%  din valoarea costurilor directe</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4">
                                        Raspunsuri posibile:
                                        <ul>
                                            <li>1. sub 5% din valoarea costurilor directe = 1p</li>
                                            <li>2. de la 5% si pana la 10%, inclusiv, din valoarea costurilor directe = 2p</li>
                                            <li>3. peste 10 % si pana la 20%, inclusiv  din valoarea costurilor directe = 3p</li>
                                            <li>4. peste 20%  din valoarea costurilor directe = 4p</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 form-group">
                                        <el-form-item prop="q10" label="10. Exista in buget costuri cu constructia/reconstructia?">
                                            <el-radio-group v-model="reporting.questions.q10" size="mini" >
                                                <el-radio-button :label="4" v-if="reporting.questions.q10 === 4" border>da, exista</el-radio-button>
                                                <el-radio-button :label="1" v-if="reporting.questions.q10 === 1" border>nu, nu exista</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4">
                                        Raspunsuri posibile:
                                        <ul>
                                            <li>1. da, exista = 4p </li>
                                            <li>2. nu, nu exista = 1p</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 form-group">
                                        <el-form-item prop="q11" label="11. Care este numărul partenerilor cu buget alocat în proiect?">
                                            <el-radio-group v-model="reporting.questions.q11" size="mini" >
                                                <el-radio-button :label="0" v-if="reporting.questions.q11 === 0" border>nu exista inca un raspuns</el-radio-button>
                                                <el-radio-button :label="1" v-if="reporting.questions.q11 === 1" border>fără parteneri</el-radio-button>
                                                <el-radio-button :label="2" v-if="reporting.questions.q11 === 2" border>1-2 parteneri</el-radio-button>
                                                <el-radio-button :label="3" v-if="reporting.questions.q11 === 3" border>3-4 parteneri</el-radio-button>
                                                <el-radio-button :label="reporting.questions.q11" v-if="reporting.questions.q11 >= 4" border>peste 4 parteneri</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4">
                                        Raspunsuri posibile:
                                        <ul>
                                            <li>1. fără parteneri = 1p </li>
                                            <li>2. 1-2 parteneri = 2p</li>
                                            <li>3. 3-4 parteneri= 3p</li>
                                            <li>4. peste 4 parteneri = 4p</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 form-group">
                                        <el-form-item prop="q12" label="12. Care este valoarea cofinanțării in bani asumate în proiect?">
                                            <el-radio-group v-model="reporting.questions.q12" size="mini" >
                                                <el-radio-button :label="1" v-if="reporting.questions.q12 === 1" border>0-2.000 euro, inclusiv</el-radio-button>
                                                <el-radio-button :label="2" v-if="reporting.questions.q12 === 2" border>peste 2.000 euro si pana la 6.000 euro, inclusiv</el-radio-button>
                                                <el-radio-button :label="3" v-if="reporting.questions.q12 === 3" border>peste 6.000 euro si pana la 10.000 euro, inclusiv</el-radio-button>
                                                <el-radio-button :label="4" v-if="reporting.questions.q12 === 4" border>peste 10.000 euro</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4">
                                        Raspunsuri posibile:
                                        <ul>
                                            <li>1. 0-2.000 euro, inclusiv = 1p </li>
                                            <li>2. peste 2.000 euro si pana la 6.000 euro, inclusiv = 2p</li>
                                            <li>3. peste 6.000 euro si pana la 10.000 euro, inclusiv = 3p</li>
                                            <li>4. peste 10.000 euro = 4p</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 form-group">
                                        <el-form-item prop="q13" label="13. După analiza rapoartelor  financiare anterioare, care este totalul cheltuielilor considerate a fi neeligibile? (se  cumuleaza sumele neeligibile din finantare si cofinantare, din fiecare raport depus anterior si aprobat, pana la raportul curent)">
                                            <el-radio-group v-model="reporting.questions.q13" size="mini" >
                                                <el-radio-button :label="1" v-if="reporting.questions.q13 === 1" border>0 euro - 500 euro, inclusiv </el-radio-button>
                                                <el-radio-button :label="2" v-if="reporting.questions.q13 === 2" border>peste 500 euro si pana la 1000 euro, inclusiv</el-radio-button>
                                                <el-radio-button :label="3" v-if="reporting.questions.q13 === 3" border>peste 1000 euro si pana la 2000 euro, inclusiv</el-radio-button>
                                                <el-radio-button :label="4" v-if="reporting.questions.q13 === 4" border>peste 2000 euro, inclusiv</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div class="col-4">
                                        Raspunsuri posibile:
                                        <ul>
                                            <li>1. 0 euro - 500 euro, inclusiv = 1p </li>
                                            <li>2. peste 500 euro si pana la 1000 euro, inclusiv = 2p</li>
                                            <li>3. peste 1000 euro si pana la 2000 euro, inclusiv = 3p</li>
                                            <li>4. peste 2000 euro, inclusiv = 4p</li>
                                        </ul>
                                    </div>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </el-form>
                </el-tab-pane>

                <el-tab-pane v-for="activity in project.activities" :label="'A'+activity.activity"
                             :name="'activity' + activity.activity" :id="activity.activity" class="active" :key="activity.activity">
                    <h5>Activitatea {{activity.activity}}: {{activity.name}}</h5>
                    <el-table size="mini" :data="elements" style="width: 100%" :summary-method="getActivitySummaries" show-summary>
                        <el-table-column label="Status" fixed="left">
                            <template slot-scope="scope">
                                <span style="color:green" v-if="scope.row.validation !== null && scope.row.validation.status === 1">Valid</span>
                                <span style="color:black" v-if="scope.row.validation === null">Fara verificare</span>
                                <span style="color:red" v-if="scope.row.validation !== null && scope.row.validation.status === 2">Invalid</span>
<!--                                <span style="color:blue" v-if="scope.row.validation !== null && scope.row.validation.comments">Clarificari</span>-->
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip prop="category.value" label="Capitol buget" width="200"></el-table-column>
                        <el-table-column show-overflow-tooltip label="Alocat la" width="100">
                            <template slot-scope="scope">
                                <span v-if="scope.row.partner_id === 0">Aplicant</span>
                                <span v-if="scope.row.partner_id > 0">{{ scope.row.partner.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip prop="budget.value" label="Linia de buget" width="150"></el-table-column>
                        <el-table-column label="Pozitia">
                            <template slot-scope="scope">
                                <span v-if="scope.row.position">{{ scope.row.position.position }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Luna / An">
                            <template slot-scope="scope" v-if="scope.row.month">
                                {{ formatNewDate(scope.row.month) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Ore lucr/luna" align="right"  header-align="center" width="90">
                            <template slot-scope="scope" v-if="scope.row.month_hours > 0">
                                {{ formatNumber(scope.row.month_hours) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Ore ef. lucr." align="right"  header-align="center" width="90">
                            <template slot-scope="scope" v-if="scope.row.worked_hours > 0">
                                {{ formatNumber(scope.row.worked_hours) }}
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip prop="supplier" label="CNP / CUI" width="150"></el-table-column>
                        <el-table-column show-overflow-tooltip prop="supplier_name" label="Nume prenume / Denumire furnizor" width="150"></el-table-column>
                        <el-table-column show-overflow-tooltip prop="description" label="Descriere" width="150"></el-table-column>
                        <el-table-column prop="invoice_no" label="Nr. doc. cost" align="right" header-align="center"></el-table-column>
                        <el-table-column label="Data. doc. cost" header-align="center" width="90">
                            <template slot-scope="scope">
                                {{ formatUserDate(scope.row.invoice_date) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="receipt_no" label="Nr. doc. plata" align="right"  header-align="center"></el-table-column>
                        <el-table-column label="Data doc. plata" header-align="center" width="90">
                            <template slot-scope="scope">
                                {{ formatUserDate(scope.row.receipt_date) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="currency" label="Moneda" align="center" header-align="center"></el-table-column>
                        <el-table-column prop="amount_cost" label="Val. tot. doc. cost" align="right"  header-align="center" width="100">
                            <template slot-scope="scope">
                                {{ formatNumber(scope.row.amount_cost) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="amount_financing" label="Chelt. fin." align="right"  header-align="center" width="100">
                            <template slot-scope="scope">
                                {{ formatNumber(scope.row.amount_financing) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="amount_cofinancing" label="Chelt. co-fin." align="right"  header-align="center" width="100">
                            <template slot-scope="scope">
                                {{ formatNumber(scope.row.amount_cofinancing) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="rate" label="Curs EURO" header-align="center" align="center" ></el-table-column>
                        <el-table-column prop="total_financing_spent" label="Chelt din fin." align="right"  header-align="center" width="100">
                            <template slot-scope="scope">
                                {{ formatPrice(scope.row.total_financing_spent) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="total_cofinancing_spent" label="Chelt din co-fin." align="right"  header-align="center" width="100">
                            <template slot-scope="scope">
                                {{ formatPrice(scope.row.total_cofinancing_spent) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="total_spent" label="Cost total" align="right"  header-align="center" width="100">
                            <template slot-scope="scope">
                                {{ formatPrice(scope.row.total_spent) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="dezv" label="Cost dezv." align="right"  header-align="center" width="100">
                            <template slot-scope="scope">
                                {{ formatPrice(scope.row.dezv) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="financial_accepted" label="Cheltuieli finantare acceptate." align="right"  header-align="center" width="150">
                            <template slot-scope="scope">
                                <span>{{ formatPrice(scope.row.financial_accepted) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="cofinancial_accepted" label="Cheltuieli cofinantare acceptate." align="right"  header-align="center" width="150">
                            <template slot-scope="scope">
                                <span>{{ formatPrice(scope.row.cofinancial_accepted) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="dezv_accepted" label="Cheltuieli dezvoltare acceptate." align="right"  header-align="center" width="150">
                            <template slot-scope="scope">
                                <span>{{ formatPrice(scope.row.dezv_accepted) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="nonaccepted_financing" label="Cheltuieli neeligibile finantare" align="right"  header-align="center" width="150">
                            <template slot-scope="scope">
                                <span>{{ formatPrice(scope.row.nonaccepted_financing) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="nonaccepted_cofinancing" label="Cheltuieli neeligibile cofinantare" align="right"  header-align="center" width="150">
                            <template slot-scope="scope">
                                <span>{{ formatPrice((scope.row.nonaccepted_cofinancing)) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="nonaccepted_dezv" label="Cheltuieli neeligibile dezvoltare" align="right"  header-align="center" width="150">
                            <template slot-scope="scope">
                                <span>{{ formatPrice((scope.row.nonaccepted_dezv))  }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="motivate_cost" label="Motivarea neeligibilitatii costului" align="right"  header-align="center" width="150">
                            <template slot-scope="scope">
                                <span v-if="scope.row.validation !== null">{{ scope.row.validation.motivate_cost  }}</span><span v-else>0</span>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="user_type === 0" prop="actiuni" label="Actiuni" align="right" fixed="right" header-align="center" width="100">
                            <template slot-scope="scope">
                                <template v-if="scope.row.validation !== null && scope.row.validation.documents !== null">
                                    <el-button  @click="openDrawer(scope.row)" size="mini" type="primary"
                                                :class="getCommentStatus(scope.row.validation ? scope.row.validation.comments : [])">Modifica</el-button>
                                </template>
                                <template v-else>
                                    <el-button  @click="openDrawer(scope.row)" size="mini" type="secondary"
                                                :class="getCommentStatus(scope.row.validation ? scope.row.validation.comments : [])">Valideaza</el-button>
                                </template>
                                <br />
                                <el-button @click="changeExpense(scope.row);showModal=true;" size="mini" type="warning">Detalii</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>

                <el-tab-pane label="Cheltuieli indirecte" name="indirect">
                    <el-table size="mini" :data="reporting.indirects" :summary-method="getIndirectsSummaries" show-summary>
                        <el-table-column label="Status" fixed="left">
                            <template slot-scope="scope">
                                <span style="color:green" v-if="scope.row.validation !== null && scope.row.validation.status === 1">Valid</span><span style="color:black" v-if="scope.row.validation === null">Fara verificare</span><span style="color:red" v-if="scope.row.validation !== null && scope.row.validation.status == 2">Invalid</span>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip prop="category.value" label="Capitol de buget" width="160"></el-table-column>
                        <el-table-column prop="partner_id" label="Alocat la">
                            <template slot-scope="scope">
                                <span v-if="scope.row.partner_id === project.promoter_id || scope.row.partner_id === 0">Aplicant</span>
                                <span v-else>{{ scope.row.partner.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip prop="budget.value" label="Linia de buget" width="150"></el-table-column>
                        <el-table-column show-overflow-tooltip prop="supplier" label="CUI/CNP" width="100"></el-table-column>
                        <el-table-column show-overflow-tooltip prop="supplier_name" label="Denumire/Nume" width="150"></el-table-column>
                        <el-table-column show-overflow-tooltip prop="description" label="Descriere" width="200"></el-table-column>
                        <el-table-column prop="invoice_no" label="Nr. doc. cost" align="right" header-align="center"></el-table-column>
                        <el-table-column label="Data. doc. cost" header-align="center" width="90">
                            <template slot-scope="scope">
                                {{ formatUserDate(scope.row.invoice_date) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="receipt_no" label="Nr. doc. plata" align="right"  header-align="center"></el-table-column>
                        <el-table-column label="Data doc. plata" header-align="center" width="90">
                            <template slot-scope="scope">
                                {{ formatUserDate(scope.row.receipt_date) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="currency" label="Moneda" align="center" header-align="center"></el-table-column>
                        <el-table-column prop="amount_cost" label="Val. tot. doc. cost" align="right"  header-align="center" width="100">
                            <template slot-scope="scope">
                                {{ formatNumber(scope.row.amount_cost) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="amount_financing" label="Chelt. fin." align="right"  header-align="center" width="100">
                            <template slot-scope="scope">
                                {{ formatNumber(scope.row.amount_financing_percent,2) }}% ({{ formatNumber(scope.row.amount_financing) }})
                            </template>
                        </el-table-column>
                        <el-table-column prop="amount_cofinancing" label="Chelt. co-fin." align="right"  header-align="center" width="100">
                            <template slot-scope="scope">
                                {{ formatNumber(scope.row.amount_cofinancing_percent,2) }}% ({{ formatNumber(scope.row.amount_cofinancing) }})
                            </template>
                        </el-table-column>
                        <el-table-column prop="rate" label="Curs EURO" header-align="center" align="center" ></el-table-column>
                        <el-table-column prop="total_financing_spent" label="Chelt din fin." align="right"  header-align="center" width="100">
                            <template slot-scope="scope">
                                {{ formatPrice(scope.row.total_financing_spent) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="total_cofinancing_spent" label="Chelt din co-fin." align="right"  header-align="center" width="100">
                            <template slot-scope="scope">
                                {{ formatPrice(scope.row.total_cofinancing_spent) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="total_spent" label="Cost total" align="right"  header-align="center" width="100">
                            <template slot-scope="scope">
                                {{ formatPrice(scope.row.total_spent) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="dezv" label="Cost dezv." align="right"  header-align="center" width="100">
                            <template slot-scope="scope">
                                {{ formatPrice(scope.row.dezv) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="financial_accepted" label="Cheltuieli finantare acceptate." align="right"  header-align="center" width="150">
                            <template slot-scope="scope">
                                <span >{{ formatPrice(scope.row.financial_accepted) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="cofinancial_accepted" label="Cheltuieli cofinantare acceptate." align="right"  header-align="center" width="150">
                            <template slot-scope="scope">
                                <span >{{ formatPrice(scope.row.cofinancial_accepted) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="dezv_accepted"  label="Cheltuieli dezvoltare acceptate." align="right"  header-align="center" width="150">
                            <template slot-scope="scope">
                                <span >{{ formatPrice(scope.row.dezv_accepted) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="nonaccepted_financing" label="Cheltuieli neeligibile finantare" align="right"  header-align="center" width="150">
                            <template slot-scope="scope">
                                <span >{{ formatPrice(scope.row.nonaccepted_financing) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="nonaccepted_cofinancing" label="Cheltuieli neeligibile cofinantare" align="right"  header-align="center" width="150">
                            <template slot-scope="scope">
                                <span >{{ formatPrice(scope.row.nonaccepted_cofinancing) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="nonaccepted_dezv" label="Cheltuieli neeligibile dezvoltare" align="right"  header-align="center" width="150">
                            <template slot-scope="scope">
                                <span >{{ formatPrice(scope.row.nonaccepted_dezv) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="motivate_cost" label="Motivarea neeligibilitatii costului" align="right"  header-align="center" width="150">
                            <template slot-scope="scope">
                                <span v-if="scope.row.validation !== null">{{ scope.row.validation.motivate_cost  }}</span><span v-else></span>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="user_type === 0" prop="actiuni" label="Actiuni" align="right" fixed="right" header-align="center" width="100">
                            <template slot-scope="scope">
                                <template v-if="scope.row.validation !== null && scope.row.validation.documents !== null">
                                    <el-button  @click="openDrawer(scope.row)" size="mini" type="primary"
                                                :class="getCommentStatus(scope.row.validation ? scope.row.validation.comments : [])">Modifica</el-button>
                                </template>
                                <template v-else>
                                    <el-button  @click="openDrawer(scope.row)" size="mini" type="secondary"
                                                :class="getCommentStatus(scope.row.validation ? scope.row.validation.comments : [])">Valideaza</el-button>
                                </template>
                                <br />
                                <el-button @click="changeExpense(scope.row, 'indirect');showModal=true;" size="mini" type="warning">Cheltuiala</el-button>
                            </template>
                        </el-table-column>

                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </div>

        <!-- Centralizatoare -->
        <div class="form-group">
            <h3 class="mt-5">Centralizatoare</h3>
            <el-tabs class="mt-2" @tab-click="selectTab">
                <el-tab-pane label="Centralizator raport" name="summary">
                    <div class="row">
                        <div class="col-6">
                            <div class="alert alert-success">Selecteaza un centralizator pentru a incarca detaliile!</div>
                        </div>
                        <div class="col-6 text-right">
                            <el-button type="secondary" round @click.prevent="openUrl('/reportings/exportFinancialReport/'+reporting.id+'/'+project.id, true)">
                                Export in Excel - cheltuieli raportate
                            </el-button>
                            <el-button type="secondary" round @click.prevent="openUrl('/reportings/exportFinancialReportAccepted/'+reporting.id+'/'+project.id, true)">
                                Export in Excel - cheltuieli acceptate
                            </el-button>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <!--                {{ project.activities }}-->
                            <el-tabs v-model="reportTabs" @tab-click="handleClick" v-loading="loading">
                                <el-tab-pane :label="'A'+activity.activity" :name="'A'+activity.activity" v-for="activity in project.activities" :key="activity.id">
                                    <template slot="label">
                                        <el-tooltip position="top">
                                            <div slot="content">{{ activity.name }}</div>
                                            <span>A{{ activity.activity }}</span>
                                        </el-tooltip>
                                    </template>
                                    <h5 class="mt-3">Sume acceptate</h5>
                                    <el-table :data="financialReportAccepted[activity.id]" :summary-method="getSummaries" show-summary size="mini">
                                        <el-table-column show-overflow-tooltip label="Linie buget" width="200">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.name">{{ scope.row.name }}</span>
                                            </template>
                                        </el-table-column>
                                        <!--                            <el-table-column label="Linie buget" prop="budget"></el-table-column>-->
                                        <el-table-column label="Buget aprobat" align="center">
                                            <el-table-column label="Total" align="right" header-align="center" prop="total" width="115">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Comp. dezv." align="right" header-align="center" prop="dezv" width="100">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.dezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Cheltuieli acceptate raportari precedente" align="center">
                                            <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingOldFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingOldCoFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldCoFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Total" align="right" header-align="center" prop="totalReportingOld">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOld) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingOldDezv">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldDezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Cheltuieli aferente perioadei raportate" align="center">
                                            <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingNowFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingNowCoFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowCoFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Total" align="right" header-align="center" prop="totalReportingNow">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNow) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingNowDezv">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowDezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Sume necheltuite" align="center">
                                            <el-table-column label="Din buget" align="right" header-align="center" prop="diff_total" width="115">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.diff_total) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din dezv. org." align="right" header-align="center" prop="diff_dezv" width="100">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.diff_dezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                    </el-table>
                                    <h5 class="mt-3">Sume raportate</h5>
                                    <el-table :data="financialReportReported[activity.id]" :summary-method="getSummaries" show-summary size="mini">
                                        <el-table-column show-overflow-tooltip label="Linie buget" width="200">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.name">{{ scope.row.name }}</span>
                                            </template>
                                        </el-table-column>
                                        <!--                            <el-table-column label="Linie buget" prop="budget"></el-table-column>-->
                                        <el-table-column label="Buget aprobat" align="center">
                                            <el-table-column label="Total" align="right" header-align="center" prop="total" width="115">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Comp. dezv." align="right" header-align="center" prop="dezv" width="100">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.dezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Cheltuieli acceptate raportari precedente" align="center">
                                            <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingOldFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingOldCoFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldCoFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Total" align="right" header-align="center" prop="totalReportingOld">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOld) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingOldDezv">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldDezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Cheltuieli aferente perioadei raportate" align="center">
                                            <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingNowFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingNowCoFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowCoFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Total" align="right" header-align="center" prop="totalReportingNow">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNow) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingNowDezv">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowDezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Sume necheltuite" align="center">
                                            <el-table-column label="Din buget" align="right" header-align="center" prop="diff_total" width="115">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.diff_total) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din dezv. org." align="right" header-align="center" prop="diff_dezv" width="100">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.diff_dezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                    </el-table>
                                </el-tab-pane>
                                <el-tab-pane label="Indirecte" name="indirects">
                                    <template slot="label">
                                        <el-tooltip position="top">
                                            <div slot="content">Cheltuieli indirecte</div>
                                            <span>Indirecte</span>
                                        </el-tooltip>
                                    </template>
                                    <h5 class="mt-3">Sume acceptate</h5>
                                    <el-table :data="financialIndirectReportAccepted" :summary-method="getSummaries" show-summary size="mini">
                                        <el-table-column show-overflow-tooltip label="Aplicant/Partener" width="200">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.name">{{ scope.row.name }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Buget aprobat" align="center">
                                            <el-table-column label="Total" align="right" header-align="center" prop="total" width="115">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Comp. dezv." align="right" header-align="center" prop="dezv" width="100">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.dezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Cheltuieli acceptate raportari precedente" align="center">
                                            <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingOldFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingOldCoFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldCoFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Total" align="right" header-align="center" prop="totalReportingOld">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOld) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingOldDezv">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldDezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Cheltuieli aferente perioadei raportate" align="center">
                                            <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingNowFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingNowCoFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowCoFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Total" align="right" header-align="center" prop="totalReportingNow">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNow) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingNowDezv">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowDezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Sume necheltuite" align="center">
                                            <el-table-column label="Din buget" align="right" header-align="center" prop="diff_total" width="115">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.diff_total) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din dezv. org." align="right" header-align="center" prop="diff_dezv" width="100">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.diff_dezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                    </el-table>
                                    <h5 class="mt-3">Sume raportate</h5>
                                    <el-table :data="financialIndirectReportReported" :summary-method="getSummaries" show-summary size="mini">
                                        <el-table-column show-overflow-tooltip label="Aplicant/Partener" width="200">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.name">{{ scope.row.name }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Buget aprobat" align="center">
                                            <el-table-column label="Total" align="right" header-align="center" prop="total" width="115">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Comp. dezv." align="right" header-align="center" prop="dezv" width="100">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.dezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Cheltuieli acceptate raportari precedente" align="center">
                                            <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingOldFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingOldCoFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldCoFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Total" align="right" header-align="center" prop="totalReportingOld">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOld) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingOldDezv">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldDezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Cheltuieli aferente perioadei raportate" align="center">
                                            <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingNowFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingNowCoFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowCoFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Total" align="right" header-align="center" prop="totalReportingNow">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNow) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingNowDezv">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowDezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Sume necheltuite" align="center">
                                            <el-table-column label="Din buget" align="right" header-align="center" prop="diff_total" width="115">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.diff_total) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din dezv. org." align="right" header-align="center" prop="diff_dezv" width="100">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.diff_dezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                    </el-table>
                                </el-tab-pane>
                                <el-tab-pane label="Buget sintetic" name="synthetic">
                                    <h5 class="mt-3">Sume acceptate</h5>
                                    <el-table :data="syntheticReportAccepted" :summary-method="getSummaries" show-summary size="mini">
                                        <el-table-column show-overflow-tooltip label="Linie de buget" width="200">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.name">{{ scope.row.name }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Buget aprobat" align="center">
                                            <el-table-column label="Total" align="right" header-align="center" prop="total" width="115">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Comp. dezv." align="right" header-align="center" prop="dezv" width="100">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.dezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Cheltuieli acceptate raportari precedente" align="center">
                                            <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingOldFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingOldCoFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldCoFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Total" align="right" header-align="center" prop="totalReportingOld">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOld) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingOldDezv">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldDezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Cheltuieli aferente perioadei raportate" align="center">
                                            <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingNowFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingNowCoFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowCoFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Total" align="right" header-align="center" prop="totalReportingNow">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNow) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingNowDezv">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowDezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Sume necheltuite" align="center">
                                            <el-table-column label="Din buget" align="right" header-align="center" prop="diff_total" width="115">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.diff_total) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din dezv. org." align="right" header-align="center" prop="diff_dezv" width="100">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.diff_dezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                    </el-table>
                                    <h5 class="mt-3">Sume raportate</h5>
                                    <el-table :data="syntheticReportReported" :summary-method="getSummaries" show-summary size="mini">
                                        <el-table-column show-overflow-tooltip label="Linie de buget" width="200">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.name">{{ scope.row.name }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Buget aprobat" align="center">
                                            <el-table-column label="Total" align="right" header-align="center" prop="total" width="115">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Comp. dezv." align="right" header-align="center" prop="dezv" width="100">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.dezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Cheltuieli acceptate raportari precedente" align="center">
                                            <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingOldFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingOldCoFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldCoFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Total" align="right" header-align="center" prop="totalReportingOld">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOld) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingOldDezv">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldDezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Cheltuieli aferente perioadei raportate" align="center">
                                            <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingNowFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingNowCoFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowCoFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Total" align="right" header-align="center" prop="totalReportingNow">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNow) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingNowDezv">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowDezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Sume necheltuite" align="center">
                                            <el-table-column label="Din buget" align="right" header-align="center" prop="diff_total" width="115">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.diff_total) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din dezv. org." align="right" header-align="center" prop="diff_dezv" width="100">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.diff_dezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                    </el-table>
                                </el-tab-pane>
                                <el-tab-pane label="Total pe parteneri" name="partners">
                                    <h5 class="mt-3">Sume acceptate</h5>
                                    <el-table :data="financialPartnersAccepted" :summary-method="getSummaries" show-summary size="mini">
                                        <el-table-column show-overflow-tooltip label="Promotor / Partener" width="200">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.name">{{ scope.row.name }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Buget aprobat" align="center">
                                            <el-table-column label="Total" align="right" header-align="center" prop="total" width="115">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Comp. dezv." align="right" header-align="center" prop="dezv" width="100">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.dezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Cheltuieli acceptate raportari precedente" align="center">
                                            <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingOldFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingOldCoFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldCoFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Total" align="right" header-align="center" prop="totalReportingOld">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOld) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingOldDezv">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldDezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Cheltuieli aferente perioadei raportate" align="center">
                                            <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingNowFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingNowCoFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowCoFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Total" align="right" header-align="center" prop="totalReportingNow">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNow) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingNowDezv">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowDezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Sume necheltuite" align="center">
                                            <el-table-column label="Din buget" align="right" header-align="center" prop="diff_total" width="115">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.diff_total) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din dezv. org." align="right" header-align="center" prop="diff_dezv" width="100">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.diff_dezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                    </el-table>
                                    <h5 class="mt-3">Sume raportate</h5>
                                    <el-table :data="financialPartnersReported" :summary-method="getSummaries" show-summary size="mini">
                                        <el-table-column show-overflow-tooltip label="Promotor / Partener" width="200">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.name">{{ scope.row.name }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Buget aprobat" align="center">
                                            <el-table-column label="Total" align="right" header-align="center" prop="total" width="115">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Comp. dezv." align="right" header-align="center" prop="dezv" width="100">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.dezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Cheltuieli acceptate raportari precedente" align="center">
                                            <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingOldFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingOldCoFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldCoFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Total" align="right" header-align="center" prop="totalReportingOld">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOld) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingOldDezv">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldDezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Cheltuieli aferente perioadei raportate" align="center">
                                            <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingNowFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingNowCoFinancing">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowCoFinancing) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Total" align="right" header-align="center" prop="totalReportingNow">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNow) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingNowDezv">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowDezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Sume necheltuite" align="center">
                                            <el-table-column label="Din buget" align="right" header-align="center" prop="diff_total" width="115">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.diff_total) }}</template>
                                            </el-table-column>
                                            <el-table-column label="Din dezv. org." align="right" header-align="center" prop="diff_dezv" width="100">
                                                <template slot-scope="scope">{{ formatPrice(scope.row.diff_dezv) }}</template>
                                            </el-table-column>
                                        </el-table-column>
                                    </el-table>
                                </el-tab-pane>
                                <el-tab-pane label="Surse de finantare" name="totals">
                                    <h5 class="mt-3">Sume acceptate</h5>
                                    <table class="table table-responsive el-table el-table--fit el-table--border el-table--group el-table--enable-row-hover el-table--enable-row-transition el-table--mini">
                                        <thead>
                                        <tr>
                                            <th style="width:40%;white-space:normal;">Sursele de finantare </th>
                                            <th style="width:10%;white-space:normal;">Sume aprobate conform contractului de finantare</th>
                                            <th style="width:10%;white-space:normal;">% conform contractului de finantare</th>
                                            <th style="width:10%;white-space:normal;">Sume raportate ca fiind eligibile</th>
                                            <th style="width:10%;white-space:normal;">% cheltuielilor raportate si acceptate ca fiind eligibile pe surse de finantare</th>
                                            <th style="width:10%;white-space:normal;">Procentul cheltuirii bugetului</th>
                                            <th style="width:10%;white-space:normal;">Procentul bugetului ramas de cheltuit</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                1. Finantare nerambursabila solicitata
                                            </td>
                                            <td class="text-right">
                                                {{ formatPrice(this.source.totalIrredeemable) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalIrredeemable/this.source.totalEligible) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPrice(this.source.totalEligibleAccepted) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalEligibleAccepted/this.source.totalProjectAccepted) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatNumber(this.source.totalEligibleAccepted / this.source.totalIrredeemable * 100) }}%
                                            </td>
                                            <td class="text-right">
                                                {{ formatNumber(100 - (this.source.totalEligibleAccepted / this.source.totalIrredeemable * 100)) }}%
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2. Cofinantare Aplicant si Parteneri</td>
                                            <td class="text-right">
                                                {{ formatPrice(this.source.totalCoFunding) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalCoFunding/this.source.totalEligible) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPrice(this.source.totalCoFundingAccepted) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalCoFundingAccepted/this.source.totalProjectAccepted) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatNumber(this.source.totalCoFundingAccepted / this.source.totalCoFunding * 100) }}%
                                            </td>
                                            <td class="text-right">
                                                {{ formatNumber(100 - (this.source.totalCoFundingAccepted / this.source.totalCoFunding * 100)) }}%
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-muted">2.1 Contributie in bani</td>
                                            <td class="text-right text-muted">
                                                {{ formatPrice(this.source.totalCash) }}
                                            </td>
                                            <td class="text-right text-muted">
                                                {{ formatPercent(this.source.totalCash/this.source.totalCoFunding) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPrice(this.source.totalCashAccepted) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalCashAccepted/this.source.totalCoFundingAccepted) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalCashAccepted/this.source.totalCash) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatNumber(100 - (this.source.totalCashAccepted / this.source.totalCash * 100)) }}%
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-muted">2.2 Contributie in natura</td>
                                            <td class="text-right text-muted">
                                                {{ formatPrice(this.project.totalNature) }}
                                            </td>
                                            <td class="text-right text-muted">
                                                {{ formatPercent(this.project.totalNature/this.project.totalCoFunding) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPrice(this.source.totalNatureAccepted) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalNatureAccepted/this.source.totalCoFundingAccepted) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalNatureAccepted/this.source.totalNature) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatNumber(100 - (this.source.totalNatureAccepted / this.source.totalNature * 100)) }}%
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Total costuri eligibile</td>
                                            <td class="text-right">
                                                {{ formatPrice(this.source.totalEligible) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalEligible/this.source.totalEligible) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPrice(this.source.totalProjectAccepted) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalProjectAccepted/this.source.totalProjectAccepted) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalProjectAccepted/this.source.totalEligible) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatNumber(100 - (this.source.totalProjectAccepted / this.source.totalEligible * 100)) }}%
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <h5 class="mt-3">Sume raportate</h5>
                                    <table class="table table-responsive el-table el-table--fit el-table--border el-table--group el-table--enable-row-hover el-table--enable-row-transition el-table--mini">
                                        <thead>
                                        <tr>
                                            <th style="width:40%;white-space:normal;">Sursele de finantare </th>
                                            <th style="width:10%;white-space:normal;">Sume aprobate conform contractului de finantare</th>
                                            <th style="width:10%;white-space:normal;">% conform contractului de finantare</th>
                                            <th style="width:10%;white-space:normal;">Sume raportate ca fiind eligibile</th>
                                            <th style="width:10%;white-space:normal;">% cheltuielilor raportate si acceptate ca fiind eligibile pe surse de finantare</th>
                                            <th style="width:10%;white-space:normal;">Procentul cheltuirii bugetului</th>
                                            <th style="width:10%;white-space:normal;">Procentul bugetului ramas de cheltuit</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                1. Finantare nerambursabila solicitata
                                            </td>
                                            <td class="text-right">
                                                {{ formatPrice(this.source.totalIrredeemable) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalIrredeemable/this.source.totalEligible) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPrice(this.source.totalEligibleReported) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalEligibleReported/this.source.totalProjectReported) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalEligibleReported/this.source.totalIrredeemable) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatNumber(100 - (this.source.totalEligibleReported / this.source.totalIrredeemable * 100)) }}%
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2. Cofinantare Aplicant si Parteneri</td>
                                            <td class="text-right">
                                                {{ formatPrice(this.source.totalCoFunding) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalCoFunding/this.source.totalEligible) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPrice(this.source.totalCoFundingReported) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalCoFundingReported/this.source.totalProjectReported) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalCoFundingReported/this.source.totalCoFunding) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatNumber(100 - (this.source.totalCoFundingReported / this.source.totalCoFunding * 100)) }}%
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-muted">2.1 Contributie in bani</td>
                                            <td class="text-right text-muted">
                                                {{ formatPrice(this.source.totalCash) }}
                                            </td>
                                            <td class="text-right text-muted">
                                                {{ formatPercent(this.source.totalCash/this.source.totalCoFunding) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPrice(this.source.totalCashReported) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalCashReported/this.source.totalCoFundingReported) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalCashReported/this.source.totalCash) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatNumber(100 - (this.source.totalCashReported / this.source.totalCash * 100)) }}%
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-muted">2.2 Contributie in natura</td>
                                            <td class="text-right text-muted">
                                                {{ formatPrice(this.project.totalNature) }}
                                            </td>
                                            <td class="text-right text-muted">
                                                {{ formatPercent(this.project.totalNature/this.project.totalCoFunding) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPrice(this.source.totalNatureReported) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalNatureReported/this.source.totalCoFundingReported) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalNatureReported/this.source.totalNature) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatNumber(100 - (this.source.totalNatureReported / this.source.totalNature * 100)) }}%
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Total costuri eligibile</td>
                                            <td class="text-right">
                                                {{ formatPrice(this.source.totalEligible) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalEligible/this.source.totalEligible) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPrice(this.source.totalProjectReported) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalProjectReported/this.source.totalProjectReported) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPercent(this.source.totalProjectReported/this.source.totalEligible) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatNumber(100 - (this.source.totalProjectReported / this.source.totalEligible * 100)) }}%
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                </el-tab-pane>

                <el-tab-pane label="Centralizator Resurse Umane" name="hr">
                    <div class="row">
<!--                        <div class="col-6">-->
<!--                            <div class="alert alert-success">Selecteaza o activitate pentru a incarca detaliile!</div>-->
<!--                        </div>-->
                        <div class="col-12 text-right">
                            <el-button type="secondary" round @click.prevent="openUrl('/reportings/exportHr/'+reporting.id, true)">
                                <i class="icofont-file-excel"></i> Export Excel
                            </el-button>
                        </div>
                    </div>
                    <el-tabs>
                        <el-tab-pane :label="'A'+activity.activity" :name="'A'+activity.activity" v-for="activity in project.activities" :key="activity.id">
                            <template slot="label">
                                <el-tooltip position="top">
                                    <div slot="content">{{ activity.name }}</div>
                                    <span>A{{ activity.activity }}</span>
                                </el-tooltip>
                            </template>
                            <el-table size="mini" fit :data="hrReport[activity.id]"
                                      style="width: 100%">
                                <el-table-column show-overflow-tooltip prop="category.value" label="Luna aferenta perioadei raportate" width="110">
                                    <template slot-scope="scope">
                                        {{ formatNewDate(scope.row.month) }}
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" label="Cod Promotor/Partener" width="70">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.partner_id !== project.promoter_id">
                                           <span v-if="scope.row.partner_id">
                                                P{{ scope.row.partner_id }}
                                           </span>
                                           <span v-else>A</span>
                                        </span>
                                        <span v-else>A</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Activitatea" align="left" width="80">
                                    <template slot-scope="scope" >
                                        <span >A{{scope.row.activity}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Pozitia in cardul proiectului" align="left" width="120">
                                    <template slot-scope="scope" v-if="scope.row.position !== null">
                                        {{ scope.row.position }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Nume si prenume" align="left"  header-align="center" width="120">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.first_name }} {{ scope.row.last_name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="CNP" align="center" header-align="center" width="120">
                                    <template slot-scope="scope">
                                        {{ scope.row.cnp }}
                                    </template>
                                </el-table-column>
                                <el-table-column show-overflow-tooltip align="center" width="110"
                                                 label="Nr. si data CIM / Nr. si data ctr. voluntariat">
                                    <template slot-scope="scope" v-if="scope.row.position !== null">
                                        {{ scope.row.cim }} - {{scope.row.cim_date}}
                                    </template>
                                </el-table-column>
                                <el-table-column show-overflow-tooltip align="center" label="Salariul brut in luna conform CIM pe orgnizatie si actelor aditionale ulterioare (100%) - doar pentru salariati -">
                                    <template slot-scope="scope">
                                        {{scope.row.month_salary_cim}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Ore lucratoare in luna conform normei din CIM pe organizatie si actelor aditionale ulterioare(100%) / contract de voluntariat" align="center" header-align="center">
                                    <template slot-scope="scope">
                                        {{scope.row.month_hours_cim}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Ore efectiv lucrate in luna / activitate" align="center" header-align="center">
                                    <template slot-scope="scope">
                                        {{ scope.row.worked_hours_sum }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Cost total raportat in luna aferent orelor lucrate/activitate" align="right"  header-align="center">
                                    <template slot-scope="scope">
                                        {{ formatPrice(scope.row.total_spent_sum) }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Cost orar raportat in luna/activitate" align="right" header-align="center">
                                    <template slot-scope="scope">
                                        {{ formatPrice(parseFloat(scope.row.worked_total_spent_sum)) }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Costul orar unitar bugetat /activitate" align="right" header-align="center">
                                    <template slot-scope="scope">
                                        {{scope.row.project}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Abatere de la costul orar bugetat" align="center"  header-align="center">
                                    <template slot-scope="scope" v-if="scope.row.position !== null">
                                        {{ formatNumber(parseFloat(scope.row.project) - (parseFloat(scope.row.worked_total_spent_sum))) }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Justificarea depasirii costului orar bugetat" width="200" align="center"  header-align="center">
                                    <template slot-scope="scope">
                                        <el-tooltip effect="light" position="bottom">
                                            <div slot="content">{{ scope.row.justify_overdue }}</div>
                                            <i class="el-icon-info mt-1"></i>
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="actiuni" label="Actiuni" align="right" fixed="right"  header-align="center" width="150">
                                    <template slot-scope="scope">
                                        <el-button v-if="reporting.status === 1" @click="openDrawer(scope.row)">Editeaza</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>
                    </el-tabs>
                </el-tab-pane>

                <el-tab-pane label="Documente incarcate" class="active" name="docs">
                    <el-tabs class="mt-5">
                        <el-tab-pane label="Documente aferente cheltuielilor" class="active" name="doc1">
                            <el-table size="mini" :data="riskDocuments" :summary-method="getRiskSummary" show-summary>
                                <el-table-column prop="activity" label="Activitatea" width="80" align="center">
                                    <template slot-scope="scope">
                                        A{{scope.row.activity}}
                                    </template>
                                </el-table-column>
                                <el-table-column show-overflow-tooltip prop="category.value" label="Capitol de buget" width="160"></el-table-column>
                                <el-table-column label="Alocat la">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.partner_id == 0">Aplicant</span>
                                        <span v-if="scope.row.partner_id > 0">{{ scope.row.partner.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column show-overflow-tooltip prop="budget.value" label="Linia de buget" width="150"></el-table-column>
                                <el-table-column show-overflow-tooltip prop="supplier_name" label="Furnizor/Nume si prenume" width="150"></el-table-column>
                                <el-table-column show-overflow-tooltip prop="description" label="Descriere" width="200"></el-table-column>
                                <el-table-column prop="invoice_no" label="Nr. doc. cost" align="right" header-align="center"></el-table-column>
                                <el-table-column label="Data. doc. cost" header-align="center" width="90">
                                    <template slot-scope="scope">
                                        {{ formatUserDate(scope.row.invoice_date) }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="receipt_no" label="Nr. doc. plata" align="right"  header-align="center"></el-table-column>
                                <el-table-column label="Data doc. plata" header-align="center" width="90">
                                    <template slot-scope="scope">
                                        {{ formatUserDate(scope.row.receipt_date) }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="currency" label="Moneda" align="center" header-align="center"></el-table-column>
                                <el-table-column prop="amount_cost" label="Valoare totala document cost" align="right"  header-align="center" width="100">
                                    <template slot-scope="scope">
                                        {{ formatNumber(scope.row.amount_cost) }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="amount_financing" label="Chelt. finantare" align="right"  header-align="center" width="100">
                                    <template slot-scope="scope">
                                        {{ formatNumber(scope.row.amount_financing) }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="amount_cofinancing" label="Chelt. co-finantare" align="right"  header-align="center" width="100">
                                    <template slot-scope="scope">
                                        {{ formatNumber(scope.row.amount_cofinancing) }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="total_spent" label="Cost total EUR" align="right"  header-align="center" width="100">
                                    <template slot-scope="scope">
                                        {{ formatNumber(scope.row.total_spent) }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Atașează documente">
                                    <template slot-scope="scope">
                                        <el-button size="mini" type="primary" @click="uploadFile(scope.row)"><i class="icofont-eye"></i></el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>

                        <el-tab-pane label="Documente aferente achizitiilor" class="active" name="doc2">
                            <el-table size="mini" :data="reporting.ach">
                                <el-table-column prop="name" label="Denumire document" width="500"></el-table-column>
                                <el-table-column label="Actiuni">
                                    <template slot-scope="scope">
                                        <el-button size="mini" type="primary" @click="viewFile2(scope.row)"
                                                   :class="getCommentStatus(scope.row.comments)">
                                            <i class="icofont-comment"></i>
                                        </el-button>
                                        <el-link v-if="scope.row.file" @click="handlePreview(scope.row)" class="mr-4">
                                            <small>{{ scope.row.file_name }}</small>
                                        </el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>

                        <el-tab-pane label="Documente auxiliare" class="active" name="doc3">
                            <el-table size="mini" :data="reportingDocuments">
                                <el-table-column prop="name" label="Denumire document" width="500">
                                </el-table-column>
                                <el-table-column label="Atașează documente">
                                    <template slot-scope="scope">
                                        <el-button size="mini" type="primary" @click="viewFile(scope.row)" :class="getCommentStatus(scope.row.comments)">
                                            <i class="icofont-comment"></i>
                                        </el-button>
                                        <template v-if="scope.row.documents">
                                            <el-link v-for="doc in scope.row.documents" :key="doc.id" @click="handlePreview(doc)" class="mr-5">
                                                <small>{{ doc.file_name }}</small>
                                            </el-link>
                                        </template>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>
                    </el-tabs>
                </el-tab-pane>

                <el-tab-pane label="Raport dubla finantare" name="financing">
                    <div class="row">
                        <div class="col-12 text-right">
                            <el-button type="secondary" round size="medium" @click.prevent="openUrl('/reportings/export-double-financing/'+reporting.id, true)">
                                Export in Excel - Dubla Finantare
                            </el-button>
                            <el-button type="secondary" round size="medium" @click.prevent="openUrl('/reportings/export-double-financing-pdf/'+reporting.id, true)">
                                Export in PDF - Dubla Finantare
                            </el-button>
                        </div>
                    </div>
                </el-tab-pane>

                <el-tab-pane label="VRF" name="vrf">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="alert alert-success">Raspunde la intrebarile de mai jos si verifica cu atentie informatiile</div>
                        </div>
                    </div>
                    <el-form :model="vrfForm" :rules="rules" ref="vrfForm" id="vrfForm" class="mt-3">
                        <h5>1. Verificarea formala</h5>
                        <el-form-item label="1.1 Raportul primit de la promotor, in original, este semnat?"
                                      prop="vrf_1_1" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_1_1" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="1.2. Documentele justificative din esantionul care insoteste raportul
                        financiar sunt complete si conforme cu formatul solicitat?" prop="vrf_1_2" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_1_2" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="1.3 Cererea de plata primita de la Promotor in original, este semnata si
                        conforma cu formatul solicitat?" prop="vrf_1_3" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_1_3" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                                <el-radio-button label="Nu este cazul">Nu este cazul</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="1.4 Declaratia pe proprie raspundere privind evitarea dublei finantari
                        primita de la Promotor in original, este semnata si conforma cu formatul solicitat?"
                                      prop="vrf_1_4"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_1_4" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>

                        <h5 class="mt-3">2. Verificarea continutului</h5>
                        <el-form-item label="2.1. Sumele aferente partenerilor straini cuprinse in raportul financiar
                        corespund cu cele mentionate in raportul de audit?" prop="vrf_2_1" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_2_1" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                                <el-radio-button label="Nu este cazul">Nu este cazul</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="2.2.Costurile raportate si selectate in esantionul de verificare au un
                        corespondent in copii ale documentelor care atesta cheltuirea fondurilor primite?"
                                      prop="vrf_2_2" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_2_2" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                                <el-radio-button label="Nu este cazul">Nu este cazul</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="2.3. Costurile raportate sunt corecte (identificarea de erori de calcul)?"
                                      prop="vrf_2_3" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_2_3" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Da. Erorile de calcul au fost corectate">Da. Erorile de calcul au fost corectate</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="2.4. Exista costuri dublate in raportul financiar?"
                                      prop="vrf_2_4" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_2_4" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                                <el-radio-button label="Nu. Dublarile au fost eliminate">Nu. Dublarile au fost eliminate</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="2.5. Costurile incluse in acest raport financiar au mai fost raportate
                                    inainte, in același proiect / program?" prop="vrf_2_5" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_2_5" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Da, fara a dubla costurile">Da, fara a dubla costurile</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                                <el-radio-button label="Nu. Dublarile au fost eliminate">Nu. Dublarile au fost eliminate</el-radio-button>
                            </el-radio-group>
                        </el-form-item>

                        <h5 class="mt-3">3. Verificarea eligibitatii costurilor</h5>
                        <el-table :data="vrfEligibility" size="mini">
                            <el-table-column show-overflow-tooltip label="Explicatii" width="400" prop="name">
                            </el-table-column>
                            <el-table-column label="Cheltuieli din finantarea acordata" align="right" header-align="center" prop="total_financing">
                                <template slot-scope="scope">
                                    <span v-if="scope.$index + 1 === vrfEligibility.length">
                                        {{ formatPercent(scope.row.total_financing) }}
                                    </span>
                                    <span v-else>
                                        {{ formatPrice(scope.row.total_financing) }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Cheltuieli din cofinantarea asumata" align="right" header-align="center" prop="total_cofinancing">
                                <template slot-scope="scope">
                                    <span v-if="scope.$index + 1 === vrfEligibility.length">
                                        {{ formatPercent(scope.row.total_cofinancing) }}
                                    </span>
                                    <span v-else>
                                        {{ formatPrice(scope.row.total_cofinancing) }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Total cheltuieli" align="right" header-align="center" prop="total_spent">
                                <template slot-scope="scope">
                                    <span v-if="scope.$index + 1 === vrfEligibility.length">
                                        {{ formatPercent(scope.row.total_spent) }}
                                    </span>
                                    <span v-else>
                                        {{ formatPrice(scope.row.total_spent) }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Cheltuieli aferente dezvoltarii organizationale" align="right" header-align="center" prop="total_dezv">
                                <template slot-scope="scope">
                                    <span v-if="scope.$index + 1 === vrfEligibility.length">
                                        {{ formatPercent(scope.row.total_dezv) }}
                                    </span>
                                    <span v-else>
                                        {{ formatPrice(scope.row.total_dezv) }}
                                    </span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-table :data="vrfEligibilityDetails" :summary-method="getSummaries" show-summary size="mini" class="mt-3">
                            <el-table-column show-overflow-tooltip label="Linia de buget" width="400" prop="name">
                            </el-table-column>
                            <el-table-column label="Cheltuieli raportate de Promotor aferente perioade de raportare" align="center">
                                <el-table-column label="Total cheltuieli" align="right" header-align="center" prop="total_spent">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total_spent) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv" align="right" header-align="center" prop="total_dezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli acceptate de Operator aferente perioadei de raportare" align="center">
                                <el-table-column label="Total cheltuieli" align="right" header-align="center" prop="total_spent_elig">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total_spent_elig) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv" align="right" header-align="center" prop="total_dezv_elig">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total_dezv_elig) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli neeligibile aferente perioadei de raportare" align="center">
                                <el-table-column label="Total cheltuieli" align="right" header-align="center" prop="total_spent_neelig">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total_spent_neelig) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv" align="right" header-align="center" prop="total_dezv_neelig">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total_dezv_neelig) }}</template>
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                        <el-form-item label="3.1. Costurile raportate sunt facturate (sau echivalent) si platite?"
                                      prop="vrf_3_1" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_3_1" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.">Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="3.2. Pentru costurile raportate  bunurile, serviciile sau lucrările au fost recepționate in perioada raportata de Promotor?"
                                      prop="vrf_2_2" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_3_2" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.">Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="3.3. Costurile raportate sunt in perioada de implementare a proiectului (perioada de eligibilitate)?"
                                      prop="vrf_3_3" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_3_3" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.">Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="3.4. Costurile raportate au legatura cu obiectul contractului de finantare?"
                                      prop="vrf_3_4" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_3_4" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.">Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="3.5. Costurile raportate sunt prevazute in bugetul detaliat al proiectului?"
                                      prop="vrf_3_5" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_3_5" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.">Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="3.6. Costurile raportate sunt proporționale şi necesare pentru implementare proiectului?"
                                      prop="vrf_3_6" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_3_6" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.">Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="3.7. Costurile raportate sunt identificabile, verificabile si inregistrate in contabilitatea Promotorului si / sau partenerilor?"
                                      prop="vrf_3_7" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_3_7" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.">Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="3.8. Costurile raportate respecta cerințele legislaţiei fiscale şi sociale aplicabile, in vigoare la data realizarii acestora?"
                                      prop="vrf_3_8" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_3_8" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.">Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>

                        <h5 class="mt-3">4. Indicatori financiari</h5>
                        <h5><small>4.1 Valoarea esantionului de documente justificative selectat in urma calcularii gradului de risc</small></h5>
                        <el-table :data="vrfDocuments" size="mini">
                            <el-table-column show-overflow-tooltip label="Item" width="700" prop="name">
                            </el-table-column>
                            <el-table-column label="Cheltulieli aferente perioadei de raportare" header-align="center" align="right" prop="cost">
                                <template slot-scope="scope">
                                    {{ formatNumber(scope.row.cost, 2) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="% Grad de risc calculat automat in functie de raspunsurile promotorului
                             la intrebarile adresate si % esantion verificat" header-align="center" align="right" prop="risk">
                                <template slot-scope="scope">
                                    {{ formatPercent(scope.row.risk / 100, 2) }}
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-form-item label="Au fost solicitate documente justificative (in copie) aferente perioadei de raportare, in plus fata de cele din esantionul selectat?"
                                      prop="vrf_4_2" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_4_2" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <h5 class="mt-2"><small>4.2. Evolutia fluxului de numerar real fata de cel planificat</small></h5>
                        <el-table :data="vrfCash" size="mini">
                            <el-table-column show-overflow-tooltip label="Item" width="700" prop="name">
                            </el-table-column>
                            <el-table-column label="Perioada" align="right" header-align="center" prop="period">
                            </el-table-column>
                            <el-table-column label="Numar zile intarziere" align="right" header-align="center" prop="days">
                                <template slot-scope="scope">
                                    {{ scope.row.days }}
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-form-item label="Comentariu monitor financiar" prop="vrf_4_1" class="mb-0">
                            <el-input type="textarea" v-model="vrfForm.vrf_4_1" size="mini" rows="4"></el-input>
                        </el-form-item>

                        <h5 class="mt-2"><small>4.3. Evolutia cheltuielilor din finantare in raport cu cele din cofinantare</small></h5>
                        <el-table :data="vrfFinancingExpenses" size="mini">
                            <el-table-column show-overflow-tooltip label="Sursele de finantare" width="200" prop="name">
                            </el-table-column>
                            <el-table-column label="Sume APROBATE conform contractului de finantare"
                                             header-align="center" align="right" prop="approved_amount">
                                <template slot-scope="scope">
                                    {{ formatPrice(scope.row.approved_amount) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="% conform contractului de finantare"
                                             header-align="center" align="right" prop="approved_percent">
                                <template slot-scope="scope">
                                    {{ formatPercent(scope.row.approved_percent) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Sume raportate si acceptate ca fiind eligibile"
                                             header-align="center" align="right" prop="reported_amount">
                                <template slot-scope="scope">
                                    {{ formatPrice(scope.row.reported_amount) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="% cheltuielilor raportate si acceptate ca fiind eligibile pe surse de finantare"
                                             header-align="center" align="right" prop="reported_percent">
                                <template slot-scope="scope">
                                    {{ formatPercent(scope.row.reported_percent) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Procentul cheltuirii bugetului"
                                             header-align="center" align="right" prop="spent_budget">
                                <template slot-scope="scope">
                                    {{ formatPercent(scope.row.spent_budget) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Procentul bugetului  ramas de cheltuit"
                                             header-align="center" align="right" prop="remaining_budget">
                                <template slot-scope="scope">
                                    {{ formatPercent(scope.row.remaining_budget / 100) }}
                                </template>
                            </el-table-column>
                        </el-table>

                        <h5 class="mt-2"><small>4.4. Cheltuieli indirecte</small></h5>
                        <el-table :data="vrfIndirects" size="mini">
                            <el-table-column show-overflow-tooltip label="Item" width="400" prop="name">
                            </el-table-column>
                            <el-table-column label="Sume aferente perioadelor precedente perioadei de raportare"
                                             header-align="center" align="right" prop="previous">
                                <template slot-scope="scope">
                                    <span v-if="scope.$index + 1 === vrfIndirects.length">
                                        {{ formatNumber(scope.row.previous) }}%
                                    </span>
                                    <span v-else>
                                        {{ formatPrice(scope.row.previous) }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Sume aferente perioadei de raportare"
                                             header-align="center" align="right" prop="current">
                                <template slot-scope="scope">
                                    <span v-if="scope.$index + 1 === vrfIndirects.length">
                                        {{ formatNumber(scope.row.current, 2) }}%
                                    </span>
                                    <span v-else>
                                        {{ formatPrice(scope.row.current) }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Total"
                                             header-align="center" align="right" prop="total">
                                <template slot-scope="scope">
                                    <span v-if="scope.$index + 1 === vrfIndirects.length">
                                        {{ formatNumber(scope.row.total, 2) }}%
                                    </span>
                                    <span v-else>
                                        {{ formatPrice(scope.row.total) }}
                                    </span>
                                </template>
                            </el-table-column>
                        </el-table>

                        <h5 class="mt-3">5. Verificarea respectarii procedurilor de achiziții</h5>
                        <el-form-item label="5.1. A fost selectata corect procedura de achizitii?"
                                      prop="vrf_5_1" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_5_1" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.">Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="5.2. Procedura de achizitii selectata a fost corect aplicata?"
                                      prop="vrf_5_2" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_5_2" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.">Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="5.3. Documentele suport corespund cerintelor minime ale procedurii de achizitii selectata?"
                                      prop="vrf_5_3" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrfForm.vrf_5_3" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.">Da. Costurile care nu au indeplinit acest criteriu au fost considerate neeligibile.</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>

                        <h5 class="mt-3">6. Alte informatii financiare</h5>
                        <h5 class="mt-2"><small>6.1. Informatii legate de executia bugetara</small></h5>
                        <el-table :data="vrfBudget" :summary-method="getSummaries" show-summary size="mini">
                            <el-table-column show-overflow-tooltip label="Item" width="400" prop="name">
                            </el-table-column>
                            <el-table-column label="Buget aprobat la data raportarii" align="center">
                                <el-table-column label="Total buget" align="right" header-align="center" prop="total_budget">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total_budget) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv" align="right" header-align="center" prop="total_dezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Total cheltuieli acceptate de Operator" align="center">
                                <el-table-column label="Total cheltuieli" align="right" header-align="center" prop="total_accepted">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total_accepted) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv" align="right" header-align="center" prop="total_accepted_dezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total_accepted_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Sume necheltuite din bugetul aprobat" align="center">
                                <el-table-column label="Total" align="right" header-align="center" prop="total_diff" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total_diff) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv" align="right" header-align="center" prop="total_diff_dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total_diff_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                        </el-table>

                        <h5 class="mt-2"><small>6.2. Balanta</small></h5>
                        <div class="row">
                            <div class="col-4 el-form-item__label">Rata de absortie:</div>
                            <div class="col-3 el-form-item__label"><strong>{{ formatPercent(vrfOthers.rate) }}</strong></div>
                        </div>
                        <div class="row">
                            <div class="col-4 el-form-item__label">Avans platit din finantarea acordata:</div>
                            <div class="col-3">
                                <el-form-item prop="vrf_6_1" class="mb-0"
                                      :rules="[{ required: true, message: 'Introdu o valoare', trigger: 'blur' }]">
                                    <el-input v-model="vrfForm.vrf_6_1" type="number" size="medium">
                                        <template slot="append">€</template>
                                    </el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 el-form-item__label">Total cheltuieli eligibile din finantarea acordata:</div>
                            <div class="col-3 el-form-item__label"><strong>{{ formatPrice(vrfOthers.eligible) }}</strong></div>
                        </div>
                        <div class="row">
                            <div class="col-4 el-form-item__label">
                                <span v-if="parseFloat(vrfOthers.eligible) >= parseFloat(vrfForm.vrf_6_1)">Diferenta de platit din finantarea acordata:</span>
                                <span v-else>Sume necheltuite din avansul primit:</span>
                            </div>
                            <div class="col-3 el-form-item__label">
                                <strong>
                                    <template v-if="vrfForm.vrf_6_3 === 'Transa finala'">
                                        <span v-if="parseFloat(vrfOthers.eligible) >= parseFloat(vrfForm.vrf_6_1)">
                                            {{ formatPrice((parseFloat(vrfOthers.eligible) - parseFloat(vrfForm.vrf_6_1) - parseFloat(vrfForm.vrf_6_2))) }}
                                        </span>
                                        <span v-else>
                                            {{ formatPrice((parseFloat(vrfForm.vrf_6_1) - parseFloat(vrfOthers.eligible) + parseFloat(vrfForm.vrf_6_2)) * -1) }}
                                        </span>
                                    </template>
                                    <template v-else>
                                        <span v-if="parseFloat(vrfOthers.eligible) >= parseFloat(vrfForm.vrf_6_1)">
                                            {{ formatPrice((parseFloat(vrfOthers.eligible) - parseFloat(vrfForm.vrf_6_1))) }}
                                        </span>
                                        <span v-else>
                                            {{ formatPrice((parseFloat(vrfOthers.eligible) - parseFloat(vrfForm.vrf_6_1)) * -1) }}
                                        </span>
                                    </template>
                                </strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 el-form-item__label">Dobanda incasata in contul proiectului:</div>
                            <div class="col-3">
                                <el-form-item prop="vrf_6_2" class="mb-0"
                                              :rules="[{ required: true, message: 'Introdu o valoare', trigger: 'blur' }]">
                                    <el-input v-model="vrfForm.vrf_6_2" type="number" size="medium">
                                        <template slot="append">€</template>
                                    </el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 el-form-item__label">Procent de cheltuire a avansului primit de Promotor:</div>
                            <div class="col-3 el-form-item__label"><strong>{{ formatPercent(parseFloat(vrfOthers.eligible) / parseFloat(vrfForm.vrf_6_1)) }}</strong></div>
                        </div>
                        <div class="row">
                            <div class="col-4 el-form-item__label">Se recomanda plata transei:</div>
                            <div class="col-3">
                                <el-form-item prop="vrf_6_3" class="mb-0"
                                              :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                                    <el-select v-model="vrfForm.vrf_6_3" size="medium">
                                        <el-option value="Transa 1">Transa 1</el-option>
                                        <el-option value="Transa 2">Transa 2</el-option>
                                        <el-option value="Transa 3">Transa 3</el-option>
                                        <el-option value="Transa 4">Transa 4</el-option>
                                        <el-option value="Transa 5">Transa 5</el-option>
                                        <el-option value="Transa 6">Transa 6</el-option>
                                        <el-option value="Transa 7">Transa 7</el-option>
                                        <el-option value="Transa finala">Transa finala</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 el-form-item__label">Valoarea transei recomandata la plata:</div>
                            <div class="col-3">
                                <el-form-item prop="vrf_6_4" class="mb-0"
                                              :rules="[{ required: true, message: 'Introdu o valoare', trigger: 'blur' }]">
                                    <el-input v-model="vrfForm.vrf_6_4" type="number" size="medium">
                                        <template slot="append">€</template>
                                    </el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 el-form-item__label">Luna in care se face plata:</div>
                            <div class="col-3">
                                <el-form-item prop="vrf_6_5" class="mb-0"
                                              :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                                    <el-date-picker v-model="vrfForm.vrf_6_5" type="month" value-format="yyyy-MM"
                                                    format="MM.yyyy" size="medium" placeholder="Alege o luna"></el-date-picker>
                                </el-form-item>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-4 el-form-item__label">Data intocmirii rap. de verificare a eligibilitatii cheltuielilor:</div>
                            <div class="col-3">
                                <el-form-item prop="vrf_7_1" class="mb-0"
                                              :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                                    <el-date-picker v-model="vrfForm.vrf_7_1" type="date" size="medium"
                                            value-format="yyyy-MM-dd" format="dd.MM.yyyy" placeholder="Alege o data">
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-4"></div>
                            <div class="col-3">
                                <el-button type="primary" size="medium" @click.prevent="saveVrf">Salveaza VRF</el-button>
                                <el-button type="secondary" round size="medium" :disabled="vrfDisabled"
                                           @click.prevent="exportVrf"><i class="icofont-file-excel"></i> Export VRF
                                </el-button>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-4 el-form-item__label">Solicita verificare:</div>
                            <div class="col-3">
                                <el-button type="secondary" size="medium" @click="requestInfoAddress()">Solicita verificare</el-button>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-4 el-form-item__label">Aproba raportare financiara</div>
                            <div class="col-3">
                                <el-checkbox-button :disabled="!!reporting.app_financial_report"
                                    :checked="!!reporting.app_financial_report" @change="checkApp(1)">APROBA
                                </el-checkbox-button>
                                <el-button :disabled="!reporting.app_financial_report" round
                                           @click="openDrawerVRF()" size="medium" type="secondary">
                                    <i class="icofont-upload"></i> Incarca VRF
                                </el-button>
                            </div>
                        </div>
                    </el-form>
                </el-tab-pane>

                <el-tab-pane label="Adresa de informare" name="information">
                    <div class="row mt-4">
                        <div class="col-sm-1 text-right">
                            <h5>1.</h5>
                        </div>
                        <div class="col-sm-11">
                            <template v-if="reporting.app_financial_report">
                                <span class="icofont-check-alt btn-success"></span> Verificare Financiara
                            </template>
                            <template v-else>
                                <span class="icofont-close"></span> Verificare Financiara
                            </template>
                            <template v-if="reporting.app_technical_report">
                                <span class="icofont-check-alt btn-success"></span> Verificare Tehnica
                            </template>
                            <template v-else>
                                <span class="icofont-close"></span> Verificare Tehnica
                            </template>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-1 text-right">
                            <h5>2.</h5>
                        </div>
                        <div class="col-sm-11">
                            <el-button size="medium" :disabled="reporting.app_financial_report && reporting.app_technical_report ? false : true"
                                       @click.prevent="openUrl('/reportings/export-info-address/'+reporting.id, true)" type="secondary">
                                <i class="icofont-file-pdf"></i> Exporta Adresa de Informare
                            </el-button>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-1 text-right">
                            <h5>3.</h5>
                        </div>
                        <div class="col-sm-11">
                            <el-button :disabled="reporting.app_financial_report && reporting.app_technical_report ? false : true"
                                       @click="openDrawerInfoAddress()" size="medium" type="secondary">
                                Incarca adresa de informare
                            </el-button>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-1 text-right">
                            <h5>4.</h5>
                        </div>
                        <div class="col-sm-11">
                            <el-button :disabled="reporting.app_financial_report && reporting.app_technical_report && reporting.documents_info_address.length ? false : true"
                                       size="medium" @click="sendInfoAddress()" type="secondary">
                                Trimite Adresa de Informare
                            </el-button>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>

        <!--  Comentarii -->
        <div class="form-group">
            <h3 class="mt-5">
                Comentarii
            </h3>
            <div class="row mb-4">
                <div class="col-12 text-right">
                    <el-button round v-if="typeof reporting.comments !== 'undefined'"
                               type="secondary" class="float-right" size="medium" @click="exportComments()">
                        <i class="icofont-file-pdf"></i> Exporta clarificarile
                    </el-button>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                    <el-timeline reverse>
                        <el-timeline-item
                            v-for="(row, index) in reporting.comments"
                            :key="index"
                            :timestamp="formatUserDate(row.created_at)">
                            <div class="row">
                                <div class="col-8">
                                    <i class="icofont-girl" style="font-size:100%"></i>
                                    <small v-if="row.user && typeof row.user.promoter[0] !== 'undefined'" class=" font-weight-bold">Promotor</small>
                                    <small v-else class=" font-weight-bold">Operator de Fond</small>
                                    <el-button v-if="row.user_id === user_id && row.reply_to_id === -1" class="float-right ml-2"round size="mini" type="warning" icon="el-icon-files"
                                               @click="openDrawerComment(row)" style="margin-top:-10px"></el-button>
                                    <el-button v-if="row.user_id === user_id && row.reply_to_id === -1" class="float-right ml-2"round size="mini" type="danger" icon="el-icon-delete"
                                               @click="removeComment(index, row)" style="margin-top:-10px"></el-button>
                                    <el-button v-if="row.user_id === user_id && row.reply_to_id === -1" class="float-right ml-2" round size="mini" type="primary" icon="el-icon-edit"
                                               @click="innitEditComment(row)" style="margin-top:-10px"></el-button>
                                    <br>
                                    {{ row.comment }}
                                </div>
                                <div class="col-4 text-right">
                                    <small v-if="row.files && row.files.length > 0" class="font-weight-bold">Fisiere incarcate</small><br />
                                    <template v-for="doc in row.files">
                                        <small><a :href="'/getFile/' + doc.id" target="_blank">{{ doc.file_name }}</a></small><br />
                                    </template>
                                </div>
                            </div>
                        </el-timeline-item>
                    </el-timeline>
                </div>
                <div class="col-4">
                    <h5>Adauga solicitari de clarificare</h5>
                    <el-input v-model="form.comentariu"  placeholder="Adauga comentariul tau referitor la validare" type="textarea" :rows="10" size="medium"></el-input>
                    <el-button type="secondary" icon="el-icon-plus" size="mini" @click="addComment()" class="mt-3"
                            :disabled="form.comentariu && !isOFFButton ? false : true">Adauga comentariu</el-button>
                    <upload-drawer size="50%" :user_data="user_id"></upload-drawer>
                    <upload-drawer-simple :user_data="user_id"></upload-drawer-simple>
                    <!-- Editare comentariu -->
                    <el-dialog title="Editare comentariu" :visible.sync="dialogEditComment">
                        <div class="form-group">
                            <label>Scrie mai jos noul comentariu:</label>
                            <el-input v-model="form.new_comment" placeholder="Scrie aici..." type="textarea" :rows="10"></el-input>
                        </div>
                        <el-button size="mini" type="primary" @click="editCommentAction()"
                                   :disabled="form.new_comment ? false : true"><i class="icofont-edit"></i> Editeaza comentariul</el-button>
                    </el-dialog>
                </div>
            </div>
        </div>

        <!-- Validate or Send -->
        <div class="form-group">
<!--            <div class="row" v-if="user_type === 1">-->
<!--                <el-checkbox v-model="checkbox" @change="checkTerms" border label="Lorem ipsum"></el-checkbox>-->
<!--            </div>-->
            <div class="row my-5">
                <div class="col-sm-12 mb-2 text-left">
                    <p class="mb-0">Se asteapta raspuns pana la data de: {{formatUserDateTime(reporting.answer_limit)}}
                        <small v-if="form.answer_limit"><br/>Timp ramas: {{ datesDifferenceWithoutDayPlus(contractMinDate, form.answer_limit) }}</small>
                    </p>
                    <label>
                        <el-date-picker v-model="form.answer_limit" placeholder="Pana la" type="date" value-format="yyyy-MM-dd" size="medium"
                                        format="dd.MM.yyyy" style="width:100%" :picker-options="startPeriodOptions" @change="UPDB"></el-date-picker>
                    </label>
                </div>
                <div class="col-6 text-left">
                    <el-button :disabled="sendNotificationValidationInvalid" @click="sendNotification()" class="btn btn-warning" type="secondary"><i class="icofont-exclamation-tringle"></i> Trimite spre clarificare</el-button>
                    <el-button v-if="reporting.type_report !== 1" :disabled="sendNotificationValidationInvalid" @click="sendAudit()" class="btn btn-warning" type="secondary"><i class="icofont-exclamation-tringle"></i> Nota de debit/Audit</el-button>
                </div>
                <div class="col-sm-6 text-right">
                    <el-button v-if="checkbox" class="btn btn-success btn-sm"  @click="saveValidation()" type="primary"><i class="icofont-verification-check"></i>Salvare validare</el-button>
                </div>
            </div>
        </div>

        <!-- Change Expense Modal -->
        <el-dialog :visible.sync="showModal" width="80%" :destroy-on-close="true">
            <span slot="title"><h5>Modifica linie cheltuieli</h5></span>
            <el-form :model="form" :id="'expenseForm'" :validate-on-rule-change="false" v-loading="modalLoading"
                 :rules="rules" :ref="'expenseForm'" label-position="top" :status-icon="true" :inline-message="true">
                <div class="row">
                    <div class="col-sm-12">
                        <el-form-item label="Capitol buget" prop="category">
                            <el-select v-model="form.category" placeholder="Selecteaza" style="width:100%" size="medium" value-key="id" @change="updatePosition">
                                <el-option v-for="item in costCategories.values"
                                           :key="item.id" :label="item.value"  :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-sm-6">
                        <el-form-item label="Cheltuiala se aloca la:" prop="partner">
                            <el-select v-model="form.partner" clearable placeholder="Selecteaza" size="medium"  style="width:100%" value-key="id">
                                <el-option key="0" label="Aplicant" :value="{ id: this.project.promoter_id }"></el-option>
                                <el-option v-for="item in project.partners"
                                           :key="item.id" :label="item.name" :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-sm-6">
                        <el-form-item label="Linia de buget" prop="budget">
                            <el-select v-model="form.budget" placeholder="Selecteaza" style="width:100%" size="medium"  value-key="id" @change="updateLine">
                                <el-option v-for="item in budgetLines.values"
                                           :key="item.id" :label="item.value"  :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-sm-6" v-if="showPosition">
                        <el-tooltip effect="light" position="bottom">
                            <div slot="content">ATENTIE! Cu exceptia voluntarilor si a personalului administrativ, nicio persoana din echipa de proiect nu va putea fi raportata daca nu este nominalizata in proiect fie direct in cererea de finantare fie prin notificare aprobata anterior depunerii raportului tehnico-financiar.</div>
                            <i class="el-icon-info mt-1"></i>
                        </el-tooltip>
                        <el-form-item label="Pozitia in cadrul proiectului" prop="position"
                                      :rules="[{ required: true, message: 'Acest camp este obligatoriu', trigger: 'blur' }]">
                            <el-select v-model="form.position" placeholder="Selecteaza" size="medium"  style="width:100%" value-key="id" @change="updateSupplier">
                                <el-option v-for="item in project.employees" v-if="!showVolunteer && item.is_volunteer !== 1"
                                           :key="item.id" :label="item.position + (item.first_name ? ' - ' + item.first_name + ' ' + item.last_name: '')" :value="item">
                                </el-option>
                                <el-option v-for="item in project.employees" v-if="showVolunteer && item.is_volunteer === 1"
                                           :key="item.id" :label="item.position + (item.first_name ? ' - ' + item.first_name + ' ' + item.last_name: '')" :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="row" v-if="showPosition">
                    <div class="col-sm-12">
                        <div class="alert alert-danger">ATENTIE! Se completeaza doar pentru salariu net si pentru voluntari!</div>
                    </div>
                    <div class="col-sm-3">
                        <el-tooltip effect="light" position="bottom">
                            <div slot="content">Se completeaza doar pentru salariul net iar pentru contributiile salariale se completeaza cu valoare 0 (zero). Se completeaza cu totalul orelor lucratoare din luna raportata, aferente intregului contract individual de munca din care se raporteaza(partial sau integral). Se va tine cont de norma din contract, indiferent daca norma in proiect este partiala sau intreaga sau daca aceasta se raporteaza impartita pe mai multe activitati.</div>
                            <i class="el-icon-info mt-1"></i>
                        </el-tooltip>
                        <el-form-item label="Ore lucrătoare pe lună" prop="month_hours">
                            <el-input v-model="form.month_hours" placeholder="Număr ore" size="medium"  type="number" clearable></el-input>
                        </el-form-item>
                    </div>
                    <div class="col-sm-3">
                        <el-tooltip effect="light" position="bottom">
                            <div slot="content">Se completeaza doar pentru salariul net iar pentru contributiile salariale se completeaza cu valoare 0 (zero). Se completeaza cu numarul de ore din luna efectiv lucrate in activitate si pentru care se raporteaza costul salarial.</div>
                            <i class="el-icon-info mt-1"></i>
                        </el-tooltip>
                        <el-form-item label="Ore efectiv lucrate" prop="worked_hours">
                            <el-input v-model="form.worked_hours" placeholder="Număr ore" size="medium"  type="number" clearable></el-input>
                        </el-form-item>
                    </div>
                    <div class="col-sm-3">
                        <el-tooltip effect="light" position="bottom">
                            <div slot="content">Selectati luna pentru care se raporteaza (in functie de data documentului de angajare a costului) sau scrieti de forma LL-AAAA</div>
                            <i class="el-icon-info mt-1"></i>
                        </el-tooltip>
                        <el-form-item label="Luna curentă" prop="month">
                            <el-date-picker v-model="form.month" type="month" placeholder="Alege o lună"  size="medium"  value-format="yyyy-MM"
                                            format="yyyy-MM" style="width:100%"></el-date-picker>
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6" v-if="!showPosition">
                        <el-tooltip effect="light" position="bottom">
                            <div slot="content">Se completeaza cu CUI/CIF furnizor si apoi se selecteaza din lista. Pentru linia bugetara 1.6.1. Experti - contracte drepturi autor se completeaza CNP expert</div>
                            <i class="el-icon-info mt-1"></i>
                        </el-tooltip>
                        <el-form-item label="CUI Furnizor / CNP" prop="supplier">
                            <el-autocomplete v-model="form.supplier" @select="handleSelectCui" size="medium"
                                             placeholder="Introduceti CUI-ul Furnizorului" type="text"
                                             :maxlength="60" show-word-limit :fetch-suggestions="getCompanies" style="width:100%">
                                <template slot-scope="{ item }">
                                    <span>{{ item.value }} - {{ item.label }}</span>
                                </template>
                            </el-autocomplete>
                        </el-form-item>
                    </div>
                    <div class="col-sm-6" v-if="!showPosition">
                        <el-tooltip effect="light" position="bottom">
                            <div slot="content">Pentru linia bugetara 1.6.1. Experti - contracte drepturi autor se completeaza cu numele si prenumele expertului.</div>
                            <i class="el-icon-info mt-1"></i>
                        </el-tooltip>
                        <el-form-item label="Denumire furnizor / Nume angajat" prop="supplier_name">
                            <el-input v-model="form.supplier_name" placeholder="Furnizor" size="medium"  type="text" maxlength="60" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                    <div class="col-sm-6" v-if="showPosition">
                        <br/>
                        <el-form-item label="CNP Angajat" prop="supplier">
                            <el-input v-model="form.supplier" placeholder="Introduceti CNP-ul" size="medium"  type="text" maxlength="60" show-word-limit :disabled="isDisabled"></el-input>
                        </el-form-item>
                    </div>
                    <div class="col-sm-6" v-if="showPosition">
                        <el-tooltip effect="light" position="bottom">
                            <div slot="content">Se completeaza automat. In cazul in care nu se completeaza verificati daca persoana este nonimalizata in proiect (pentru salariati) sau daca voluntarul este definit/introdus de catre dvs. in sectiunea ,,Echipa de proiect ''</div>
                            <i class="el-icon-info mt-1"></i>
                        </el-tooltip>
                        <el-form-item label="Nume angajat/voluntar" prop="supplier_name">
                            <el-input v-model="form.supplier_name" placeholder="Introduceti numele angajatului" size="medium"  type="text" maxlength="60" show-word-limit :disabled="isDisabled"></el-input>
                        </el-form-item>
                    </div>
                    <div class="col-sm-6">
                        <el-tooltip effect="light" position="bottom">
                            <div slot="content">Completati cu suficiente detalii astfel incat cheltuiala sa se coreleze cu activitatile realizate in proiect si sa se justifice necesitatea acesteia.
                                <br/>Pentru cheltuielile de transport se va mentiona inclusiv durata deplasarii, locul si scopul deplasarii, numele salariatului are se deplaseaza.
                                <br/>Pentru diurne se va mentiona numele salariatului caruia i se acorda diurna, durata deplasarii si numarul de zile de diurna acordata, locul si scopul deplasarii.
                                <br/>Pentru cazare se va mentiona numarul de persoane cazate, perioada cazarii si numarul de nopti de cazare, locul si scopul deplasarii
                                <br/>Pentru costurile aferente voluntarilor se completeaza, dupa caz cu:  tipul costului, numar de voluntari, perioada pentru care se acorda, locul si scopul.
                                <br/>Pentru costurile aferente participantilor se va mentiona tipul costului, numarul de participanti si, dupa caz, scopul/evenimentul/actiunea in care au fost implicati, durata, locatia, <br/>alte detalii necesare pentru o buna intelegere a necesitatii cheltuielii realizate.
                                <br/>Pentru toate celelalte tipuri de costuri mentionati dupa caz, toate informatiile necesare pentru o buna intelegere justificarii costului raportat in contextul proiectului, facand referire, <br/>daca este cazul la, perioade de desfasurare a evenimentelor/ actiunilor, locatii, numar de persoane, numar de ore prestate, cantitati, etc.</div>
                            <i class="el-icon-info mt-1"></i>
                        </el-tooltip>
                        <el-form-item label="Descriere" prop="description">
                            <el-input v-model="form.description" placeholder="Introduceti descrierea" size="medium"  type="text" maxlength="255" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <el-tooltip effect="light" position="bottom">
                            <div slot="content">Se va completa integral numarul din documentul de angajare a costului (toate cifrele ) fara a mentiona seria si tipul documentului(Ex. 0000075 sau 12357VJH002)
                                <br/>In cazul statelor de plata fara numar, se va completa cu numarul lunii pentru care s-a intocmit statul de plata ( ex: pentru ianuarie, se va completa cu 1, fiind prima luna din an)
                                <br/>In cazuri exceptionale, cand se identifica documente de angajare a costului fara numar, se va completa cu ,,FN'' (fara numar).
                                <br/>In cazul muncii voluntare (linia 1.1.2), se va completa cu numarul contractului de voluntariat.</div>
                            <i class="el-icon-info mt-1"></i>
                        </el-tooltip>
                        <el-form-item :label="(showPosition ? 'Nr. doc. angajare cost' : 'Nr. document cost') " prop="invoice_no">
                            <el-input v-model="form.invoice_no" placeholder="Introduceti numarul" size="medium"  type="text" maxlength="20" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                    <div class="col-sm-3">
                        <el-tooltip effect="light" position="bottom">
                            <div slot="content">Selectati sau scrieti de forma ZZ.LL.AAAA</div>
                            <i class="el-icon-info mt-1"></i>
                        </el-tooltip>
                        <el-form-item :label="(showPosition ? 'Data doc. angajare cost' : 'Data document cost')" prop="invoice_date">
<!--                            <el-date-picker v-if="form.budget === 108" v-model="form.invoice_date" type="date" value-format="yyyy-MM-dd" size="medium" format="d.M.yyyy" placeholder="Selecteaza data" :picker-options="periodOptions2" style="width:100%" @change="getRate"></el-date-picker>-->
                            <el-date-picker v-model="form.invoice_date" type="date" value-format="yyyy-MM-dd" size="medium" format="d.M.yyyy" placeholder="Selecteaza data" style="width:100%" @change="getRate"></el-date-picker>
                        </el-form-item>
                    </div>
                    <div class="col-sm-3">
                        <el-tooltip effect="light" position="bottom">
                            <div slot="content">Se va completa numarul ordinului de plata/extrasului de cont/chitantei/bonului fiscal/dispozitiei de plata, etc. Daca documentul de angajare este acelasi cu documentul de plata (Ex. bon fiscal, bilet de tren, tichet de calatori), se vor completa aceleasi informatii ca cele din campul ,, Nr. document angajare cost''</div>
                            <i class="el-icon-info mt-1"></i>
                        </el-tooltip>
                        <el-form-item label="Nr. document plata" prop="receipt_no">
                            <el-input v-model="form.receipt_no" placeholder="Introduceti numarul" type="text" size="medium"  :disabled="showVolunteer" maxlength="20" show-word-limit>
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="col-sm-3">
                        <el-tooltip effect="light" position="bottom">
                            <div slot="content">Selectati sau scrieti de forma ZZ.LL.AAAA</div>
                            <i class="el-icon-info mt-1"></i>
                        </el-tooltip>
                        <el-form-item label="Data document plata" prop="receipt_date">
                            <el-date-picker v-if="reporting.type_report === 1" v-model="form.receipt_date" size="medium"  type="date" :disabled="showVolunteer" value-format="yyyy-MM-dd"  format="d.M.yyyy" placeholder="Selecteaza data" style="width:100%"></el-date-picker> <!--  :picker-options="periodOptions4"-->
                            <el-date-picker v-else v-model="form.receipt_date" type="date" :disabled="showVolunteer"  size="medium"  value-format="yyyy-MM-dd"  format="d.M.yyyy" placeholder="Selecteaza data" style="width:100%"></el-date-picker> <!--  :picker-options="periodOptions3" -->
                        </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <el-tooltip effect="light" position="bottom">
                            <div slot="content">Pentru promotori si pentru parteneri romani moneda utilizata este in mod obligatoriu RON </div>
                            <i class="el-icon-info mt-1"></i>
                        </el-tooltip>
                        <el-form-item :label="(showPosition ? 'Moneda in care a fost facuta cheltuiala' : 'Moneda in care a fost facuta cheltuiala')" prop="currency">
                            <el-select v-model="form.currency" placeholder="Selecteaza" size="medium"  style="width:100%" @change="getRate">
                                <el-option key="RON" label="RON" value="RON" selected></el-option>
                                <el-option key="EUR" label="EUR" value="EUR"></el-option>
                                <el-option key="BGN" label="BGN" value="BGN"></el-option>
                                <el-option key="CHF" label="CHF" value="CHF"></el-option>
                                <el-option key="CZK" label="CZK" value="CZK"></el-option>
                                <el-option key="HRK" label="HRK" value="HRK"></el-option>
                                <el-option key="HUF" label="HUF" value="HUF"></el-option>
                                <el-option key="ISK" label="ISK" value="ISK"></el-option>
                                <el-option key="MDL" label="MDL" value="MDL"></el-option>
                                <el-option key="NOK" label="NOK" value="NOK"></el-option>
                                <el-option key="PLN" label="PLN" value="PLN"></el-option>
                                <el-option key="RSD" label="RSD" value="RSD"></el-option>
                                <el-option key="SIT" label="SIT" value="SIT"></el-option>
                                <el-option key="UAH" label="UAH" value="UAH"></el-option>
                                <el-option key="GBP" label="GBP" value="GBP"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-sm-3">
                        <el-tooltip effect="light" position="bottom">
                            <div slot="content">Se va completa valoarea totala a documentului de angajare a costului (100%), indiferent daca aceasta se raporteaza impartit pe mai multe activitati sau linii de buget .
                                <br/>In cazul salariilor se va completa cu costul total salarial pentru fiecare persoana raportata, aferent intregului contract individual de munca pentru care se raporteaza, indiferent daca norma in proiect este partiala sau intreaga sau daca acest cost se raporteaza impartit pe mai multe activitati.</div>
                            <i class="el-icon-info mt-1"></i>
                        </el-tooltip>
                        <el-form-item :label="(showPosition ? 'Valoare totala doc. angajare cost' : 'Valoare totala document cost')" prop="amount_cost">
                            <el-input v-model="form.amount_cost" placeholder="0" type="text" size="medium"  show-word-limit
                                      @focus="$event.target.select()" @change="getRate">
                                <template slot="append">{{form.currency}}</template>
                            </el-input>
                        </el-form-item>
                    </div>
                    <template v-if="form.expenseType === 'indirect'">
                        <div class="col-sm-3">
                            <el-tooltip effect="light" position="bottom">
                                <div slot="content">Se completeaza cu partea din procentul de repartizare a costurilor indirecte care se solicita din finantare nerambursabila.
                                    <br/>Nu pot fi alocate proiectului cheltuieli aferente
                                    <br/>costurilor indirecte în proporţie de 100% (finantare+cofinanatre) din valoarea unui document justificativ (facturi, etc ) ci doar
                                    <br/>suma rezultată prin aplicarea procentului din metodologia de repartizare a costurilor indirecte asupra valorii totale a documentului.
                                    <br/>Procentul total din metodologie poate fi repartizat, dupa caz, integral pe finantare nerambursabila, integral pe cofinantare sau
                                    <br/>impartit pe finantare nerambursabila si cofinantare.</div>
                                <i class="el-icon-info mt-1"></i>
                            </el-tooltip>
                            <el-form-item label="Din care cheltuieli finantare" prop="amount_financing_percent">
                                <el-input v-model="form.amount_financing_percent" placeholder="0"  style="width:48%" size="medium"
                                          show-word-limit @input="privateIndirectRate2" @focus="$event.target.select()">
                                    <template slot="append">%</template>
                                </el-input>
                                <el-input :model="form.amount_financing" size="medium" :value="((form.amount_financing_percent/100)*form.amount_cost).toFixed(2)" type="number"
                                          style="width:48%;" disabled>
                                    <template slot="append">{{form.currency}}</template>
                                </el-input>
                            </el-form-item>
                        </div>
                        <div class="col-sm-3">
                            <el-tooltip effect="light" position="bottom">
                                <div slot="content">Se completeaza cu partea din procentul de repartizare a costurilor indirecte care se solicita din cofinantare.
                                    <br/>Nu pot fi alocate proiectului cheltuieli aferente costurilor
                                    <br/>indirecte în proporţie de 100% (finantare+cofinantare) din valoarea unui document justificativ (facturi, etc ) ci doar suma rezultată
                                    <br/>prin aplicarea procentului din metodologia de repartizare a costurilor indirecte asupra valorii totale a documentului. Procentul total
                                    <br/>din metodologie poate fi repartizat, dupa caz, integral pe finantare nerambursabila, integral pe cofinantare sau impartit pe finantare
                                    <br/>nerambursabila si cofinantare.</div>
                                <i class="el-icon-info mt-1"></i>
                            </el-tooltip>
                            <el-form-item label="Din care cheltuieli cofinantare" prop="amount_cofinancing_percent">
                                <el-input v-model="form.amount_cofinancing_percent" placeholder="0"  style="width:48%" size="medium"
                                          show-word-limit @input="privateIndirectRate3" @focus="$event.target.select()">
                                    <template slot="append">%</template>
                                </el-input>
                                <el-input :model="form.amount_cofinancing" size="medium" :value="((form.amount_cofinancing_percent/100)*form.amount_cost).toFixed(2)"
                                          style="width:48%;" disabled>
                                    <template slot="append">{{form.currency}}</template>
                                </el-input>
                            </el-form-item>
                        </div>
                    </template>
                    <template v-else>
                        <div class="col-sm-3">
                            <br/>
                            <el-form-item label="Din care cheltuieli finantare" prop="amount_financing">
                                <el-input v-model="form.amount_financing" placeholder="0" type="text" size="medium"  show-word-limit
                                          @focus="$event.target.select()" @change="getRate">
                                    <template slot="append">{{form.currency}}</template>
                                </el-input>
                            </el-form-item>
                        </div>
                        <div class="col-sm-3">
                            <br/>
                            <el-form-item label="Din care cheltuieli cofinantare" prop="amount_cofinancing">
                                <el-input v-model="form.amount_cofinancing" placeholder="0" type="text" size="medium"  show-word-limit
                                          @focus="$event.target.select()" @change="getRate">
                                    <template slot="append">{{form.currency}}</template>
                                </el-input>
                            </el-form-item>
                        </div>
                    </template>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <el-form-item label="Curs EURO">
                            <el-input v-model="form.rate" type="text" size="medium"  disabled align="right"></el-input>
                        </el-form-item>
                    </div>
                    <div class="col-sm-3">
                        <el-form-item label="Cheltuieli din finantare">
                            <el-input  :model="form.total_financing_spent" size="medium"  :value="formatPrice(form.amount_financing / form.rate)" type="text" disabled align="right">
                                <template slot="append">€</template>
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="col-sm-3">
                        <el-form-item label="Cheltuieli din cofinantare">
                            <el-input :model="form.total_cofinancing_spent"  size="medium"  :value="formatPrice(form.amount_cofinancing / form.rate)" type="text" disabled align="right">
                                <template slot="append">€</template>
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="col-sm-3">
                        <el-form-item label="Cost total">
                            <el-input :model="form.total_spent" size="medium"  :value="formatPrice((parseFloat(form.amount_financing) + parseFloat(form.amount_cofinancing))/form.rate)" type="text" disabled style="text-align:right">
                                <template slot="append">€</template>
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="col-sm-6">
                        <el-tooltip effect="light" position="bottom">
                            <div slot="content">Se completeaza cu suma aferenta dezvoltarii organizationale.
                                <br/>ATENTIE! Acest cost se completeaza doar la activitatea de dezvoltare organizationala conform bugetului;</div>
                            <i class="el-icon-info mt-1"></i>
                        </el-tooltip>
                        <el-form-item label="Partea costului total raportat care intra pe componenta de dezvoltare organizationala" class="form-group">
                            <el-input v-model="form.dezv" placeholder="0" size="medium"  @focus="$event.target.select()">
                                <template slot="append">€</template>
                            </el-input>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <button type="button" class="btn btn-secondary" size="medium" @click.prevent="exitExpenseChange();">Inchide fara a salva</button>
                <button type="button" class="btn btn-primary" size="medium" @click.prevent="saveExpenseChange();">Salveaza modificarile</button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import mixin from './../../mixins/common';
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import UploadDrawer from './../common/upload-reporting-drawer';
    import UploadDrawer2 from './../common/upload-drawer-simple';
    import {getIndirectCategories} from '../../store/modules/project/actions';
    import moment from 'moment';
    import VueScrollTo from 'vue-scrollto';

    export default {
        name: "reporting-verify-form",
        mixins:[mixin],
        components: {
            UploadDrawer,
            UploadDrawer2
        },
        props:{
            project_id: {
                required: true,
                type: Number
            },
            reporting_id: {
                required: true,
                type: Number,
            },
            user_type: {
                required: true,
                type: Number,
            },
            user_id: {
                required: true,
            }
        },
        data(){
            return{
                modalLoading: false,
                tabGeneral: 'first',
                currentDate: new Date(),
                loading:false,
                form: {
                    new_comment: null
                },
                minDate: new Date(),
                maxDate: new Date(),
                vrfForm: {
                    reporting_id: null,
                    project_id: null,
                    vrf_1_1: '',
                    vrf_1_2: '',
                    vrf_1_3: '',
                    vrf_1_4: '',
                    vrf_2_1: '',
                    vrf_2_2: '',
                    vrf_2_3: '',
                    vrf_2_4: '',
                    vrf_2_5: '',
                    vrf_3_1: '',
                    vrf_3_2: '',
                    vrf_3_3: '',
                    vrf_3_4: '',
                    vrf_3_5: '',
                    vrf_3_6: '',
                    vrf_3_7: '',
                    vrf_6_1: 0,
                    vrf_6_2: 0,
                },
                vrfEligibility: [],
                vrfEligibilityDetails: [],
                vrfDocuments: [],
                vrfCash: [],
                vrfFinancingExpenses: [],
                vrfIndirects: [],
                vrfBudget: [],
                vrfOthers: {
                    rate: 0,
                    eligible: 0,
                },
                vrfDisabled: true,
                indirectForm: {},
                reportTabs: '',
                questions:{},
                stateOf: ['Valid', 'Invalid'],
                disabled: 0,
                redrawElem: true,
                yes: 1,
                no: 2,
                no_case: 3,
                showVolunteer: false,
                sendValidation: true,
                contractMinDate: new Date(),
                sendNotificationValidationInvalid: true,
                dialogEditComment: false,
                isOFFButton: false,
                checkbox: false,
                limit: 10,
                planTabs: '',
                elements: [],
                updateComment: {},
                validationState: 0,
                showPosition: false,
                periodOptions2: {
                    disabledDate: this.disabledDate2
                },
                periodOptions3: {
                    disabledDate: this.disabledDate3
                },
                periodOptions4: {
                    disabledDate: this.disabledDate4
                },
                currentRate: 1,
                startPeriodOptions: {
                    disabledDate: this.disabledContractDate
                },
                source: {},
                risk_low: false,
                risk_medium: false,
                risk_high: false,
                activityId: 0,
                isDisabled: false,
                financialPartnersAccepted: [],
                financialPartnersReported: [],
                financialIndirectReportAccepted: [],
                financialIndirectReportReported: [],
                syntheticReportAccepted: [],
                syntheticReportReported: [],
                financialReportAccepted: [],
                financialReportReported: [],
                showModal: false,
                rules: {
                    category: [
                        {required: true, message: 'Capitolul este obligatoriu', trigger: 'blur'},
                    ],
                    /*partner: [
                        {required: true, message: 'Aplicantul sau partenerul este obligatoriu', trigger: 'blur'},
                    ],*/
                    budget: [
                        {required: true, message: 'Linia de buget este obligatorie', trigger: 'blur'},
                    ],
                    month: [
                        {required: true, message: 'Luna curenta este obligatorie', trigger: 'blur'},
                    ],
                    month_hours: [
                        {required: true, message: 'Orele lucrate lunar sunt obligatorii', trigger: 'blur'},
                    ],
                    worked_hours: [
                        {required: true, message: 'Orele efectiv lucrate sunt obligatorii', trigger: 'blur'},
                    ],
                    supplier: [
                        {required: true, message: 'Furnizorul este obligatoriu', trigger: 'blur'},
                    ],
                    supplier_name: [
                        {required: true,  message: 'Campul este obligatoriu', trigger: 'blur'},
                    ],
                    description: [
                        {required: true, message: 'Descrierea este obligatorie', trigger: 'blur'},
                    ],
                    invoice_no: [
                        {required: true, message: 'Numarul documentului este obligatoriu', trigger: 'blur'},
                    ],
                    invoice_date: [
                        {required: true, message: 'Data documentului este obligatoriu', trigger: 'blur'},
                    ],
                    receipt_no: [
                        {required: true, message: 'Numarul documentului este obligatoriu', trigger: 'blur'},
                    ],
                    receipt_date: [
                        {required: true, message: 'Data documentului este obligatoriu', trigger: 'blur'},
                    ],
                    amount_cost: [
                        {required: true, message: 'Valoarea este obligatorie', trigger: 'blur'},
                    ],
                    amount_financing: [
                        {required: true, message: 'Valoarea este obligatorie', trigger: 'blur'},
                    ],
                    amount_cofinancing: [
                        {required: true, message: 'Valoarea este obligatorie', trigger: 'blur'},
                    ],
                },
            }
        },
        computed:{
            ...mapGetters({
                costCategories: 'project/costCategories',
                budgetLines: 'project/budgetLines',
                showUploadDrawer: 'showUploadDrawer',
                showUploadDrawer2: 'showUploadDrawer2',
                reporting: 'reportings/reporting',
                project: 'reportings/project',
                financialIndirectReport: 'reportings/financialIndirectReport',
                //financialIndirectReportNow: 'reportings/financialIndirectReportNow',
                financialReport: 'reportings/financialReport',
                //financialReportNow: 'reportings/financialReportNow',
                syntheticReport: 'reportings/syntheticReport',
                //syntheticReportNow: 'reportings/syntheticReport',
                financialPartners: 'reportings/financialPartners',
                //financialPartnersNow: 'reportings/financialPartnersNow',
                reportingDocuments: 'reportings/reportingDocuments',
                hrReport: 'reportings/hrReport',
                riskDocuments: 'reportings/riskDocuments',
            }),
        },
        watch:{
            validateDocuments: function(){
                if(this.validateDocuments) {
                    this.submitForm();
                }
            },
            showUploadDrawer:function(status, data){
                if(this.showUploadDrawer.visible === false){
                    if(data.object.validation) {
                        this.loading = true;
                        axios.post('/reportings/reportingActivities',
                            {
                                reporting_id: data.object.reporting_id,
                                project_id: data.object.project_id,
                                activity_id: data.object.activity,
                            }).then((result) => {
                            this.elements = result.data.elements;
                        });
                        axios.post('/reportings/reporting',
                            {
                                reporting_id: data.object.reporting_id,
                                project_id: data.object.project_id,
                                activity_id: data.object.activity,
                            }).then((result) => {
                            this.reporting.indirects = result.data.indirects;
                            this.loading = false;
                        });
                    }
                    this.getValidationState({reporting_id : this.reporting_id});
                }
            },
        },
        mounted(){
            this.refreshData();
            if (this.user_type === 0) {
                this.checkbox = true;
            }
            if(this.reporting.type_report === 2)
            {
                this.minDate = moment(this.project.implementation_start_date);
                this.maxDate = moment(this.project.implementation_end_date).add(30,'days');
            }
            else
            {
                this.minDate = moment(this.reporting.start_date);
                this.maxDate = moment(this.reporting.end_date);
            }
        },
        updated() {
            this.calculateNotification();

            if (this.validationState === 1) {
                this.sendValidation = false;
            } else {
                this.sendValidation = true;
            }
        },
        methods:{
            ...mapGetters({
                validation: 'validation/project',
            }),
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
                setShowUploadDrawer2: 'setShowUploadDrawer2',
                // setReporting: 'setReporting',
                // setProject: 'setProject',
            }),
            ...mapActions({
                getIndirectCategories: 'project/getIndirectCategories',
                getDateRates: 'getDateRates',
                getCostCategories: 'project/getCostCategories',
                getBudgetLines: 'project/getBudgetLines',
                getValidationState: 'reportings/getValidationState',
                // saveValidation: 'reportings/saveValidation',
                searchAnafCompany: 'searchAnafCompany',
                saveAppStatus: 'reportings/saveAppStatus',
                getReporting: 'reportings/getReporting',
                getProject: 'reportings/getProject',
                editComment: 'reportings/editComment',
                deleteComment: 'reportings/deleteComment',
                saveReportingAnswerLimit: 'reportings/saveReportingAnswerLimit',
                getFinancialIndirectReport: 'reportings/getFinancialIndirectReport',
                getFinancialPartners: 'reportings/getFinancialPartners',
                getSyntheticReport: 'reportings/getSyntheticReport',
                getSources: 'reportings/getSources',
                getIndirectReport: 'reportings/getIndirectReport',
                getSyntheticFromReport: 'reportings/getSyntheticFromReport',
                getFinancingPartnerReport: 'reportings/getFinancingPartnerReport',
                getActivityReportNew: 'reportings/getActivityReportNew',
                getReportingDocuments: 'reportings/getReportingDocuments',
                getHrReport: 'reportings/getHrReport',
                getFinancialReport: 'reportings/getFinancialReport',
                getRiskDocuments: 'reportings/getRiskDocuments',
                setExpenseChange: 'reportings/setExpenseChange',
                setIndirectExpenseChange: 'reportings/setIndirectExpenseChange',
                setVrfReporting: 'reportings/setVrfReporting',
                // vrf
                getVrfEligibility: 'reportings/getVrfEligibility',
                getVrfEligibilityDetails: 'reportings/getVrfEligibilityDetails',
                getVrfDocuments: 'reportings/getVrfDocuments',
                getVrfCash: 'reportings/getVrfCash',
                getVrfFinancingExpenses: 'reportings/getVrfFinancingExpenses',
                getVrfIndirects: 'reportings/getVrfIndirects',
                getVrfBudget: 'reportings/getVrfBudget',
                getVrfOthers: 'reportings/getVrfOthers',
                getVrfExport: 'reportings/getVrfExport',
            }),
            requestInfoAddress() {
                this.loading = true;
                axios.get('/reportings/info-address/'+this.reporting.id).then((result) => {
                    this.loading = false;
                    this.$notify({ type: 'success', title: 'Adresa de informare', message: 'Solicitarea verificarii a fost trimisa succes!'});
                });
            },
            checkApp(type){
                this.loading = true;
                if(type === 1) {
                    this.saveAppStatus({id: this.reporting.id, financial: 1})
                        .then(result => {
                            if(result.valid){
                                this.reporting.app_financial_report = 1;
                            }
                            this.loading = false;
                        });
                } else {
                     this.saveAppStatus({id: this.reporting.id, technical: this.reporting.app_technical_report})
                        .then(_ => {
                             this.loading = false;
                        });
                }
            },
            disabledContractDate(date) {
                return date < this.contractMinDate
            },
            viewFile2(document) {
                this.setShowUploadDrawer2({
                    visible: true,
                    model: 'App\\Models\\ReportingDocument',
                    model_id: document.id,
                    title: 'Fisierele incarcate le gasiti mai jos, apasati pe ele pentru a le descarca!',
                    auxDocuments: 1,
                    canUpload: 2,
                    category_id: 183,
                    object: document,
                    acceptFiles: document.extensions,
                    files: document.documents
                });
            },
            viewFile(document) {
                this.setShowUploadDrawer2({
                    visible: true,
                    model: 'App\\Models\\ReportingDocument',
                    model_id: document.id,
                    title: 'Fisierele incarcate le gasiti mai jos, apasati pe ele pentru a le descarca!',
                    auxDocuments: 1,
                    canUpload: 1,
                    canDelete: 0,
                    category_id: 183,
                    object: document,
                    acceptFiles: document.extensions,
                    files: document.documents
                });
            },
            updateLine(){
                //console.log(this.form.category.id);
                this.sortBudgetID = this.form.budget.id;
                this.showPosition = this.form.category.id === 99;
                if(this.form.budget.id === 108) {
                    this.showVolunteer = true;
                    this.rules.receipt_no[0].required = false;
                    this.rules.receipt_date[0].required = false;
                }else{
                    this.showVolunteer = false;
                    this.rules.receipt_no[0].required = true;
                    this.rules.receipt_date[0].required = true;
                }
            },
            handlePreview(file) {
                window.open('/getFile/'+file.id, '_blank');
            },
            getRate(){
                if(this.form.invoice_date && this.form.currency){
                    if (this.form.currency === 'EUR') {
                        this.currentRate = 1;
                        this.form.rate = 1;
                    } else {
                        this.getDateRates([this.formatMysqlDate(this.form.invoice_date), this.form.currency])
                            .then((result) => {
                                this.currentRate = result.value;
                                this.form.rate = result.value;
                            });
                    }
                }
            },
            handleSelectCui(item){
                this.form.supplier_name = item.label;
            },
            getCompanies(query, cb) {
                if (query !== undefined && query.length >= 3) {
                    // setTimeout(() => {

                    this.searchAnafCompany(query).then(result => {
                        //console.log(result);
                        cb(result);
                    });
                    // }, 200);
                }
            },
            UPDB() {
                this.loading = true;
                this.form.answer_limit = moment(this.form.answer_limit).utc(true).endOf('day').format('YYYY-MM-DD HH:mm:ss');
                this.reporting.answer_limit = this.form.answer_limit;
                this.saveReportingAnswerLimit({
                    id: this.reporting.id,
                    date: this.form.answer_limit,
                }).then(_ => {
                        this.loading = false;
                        this.$notify({
                        type: 'success',
                        title: 'Data limita raspuns',
                        message: 'Data limita pentru a primi un raspuns a fost salvata cu succes!'
                    });
                });
            },
            exportComments() {
                window.open('/reportings/export-comments/'+this.reporting.id, '_blank');
            },
            removeComment(index, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi acest comentariu?')
                    .then(_ => {
                        this.loading = true;
                        this.reporting.comments.splice(index, 1);
                        this.deleteComment({
                            id: row.id,
                        }).then(_ => {
                                this.loading = false;
                                this.$notify({
                                type: 'success',
                                title: 'Stergere comentariu',
                                message: 'Comentariul a fost sters cu succes din lista!'
                            });
                        });
                    }).catch(err => {
                    console.log(err)
                });
            },
            innitEditComment(row){
                this.dialogEditComment = true;
                this.form.new_comment = row.comment;
                this.form.id_comment = row.id;
                this.updateComment = row;
            },
            uploadFile(activity){
                this.setShowUploadDrawer({
                    visible: true,
                    title: 'Vizualizare',
                    model: 'App\\Models\\ReportingActivity',
                    model_id: activity.id,
                    category_id: 183,
                    object: activity,
                    updateCommentFiles: 1,
                    canDelete: 1,
                    auxDocuments: 2,
                    files: activity.documents
                });
            },
            editCommentAction(){
                this.loading = true;
                this.dialogEditComment = false;
                this.editComment({
                    id: this.form.id_comment,
                    comment: this.form.new_comment,
                }).then(_ => {
                        this.updateComment.comment = this.form.new_comment;
                        this.loading = false;
                        this.$notify({
                        type: 'success',
                        title: 'Editare comentariu',
                        message: 'Comentariul a fost editat cu succes!'
                    });
                });
            },
            sendInfoAddress() {
                this.loading = true;
                axios.get('/reportings/sendInfoAddress/'+this.reporting.id).then((result) => {
                    this.loading = false;
                    this.$notify({ type: 'success', title: 'Adresa de informare', message: 'Adresa de informare a fost trimisa cu succes!'});
                });
            },
            updateSupplier(item){
                // this.isDisabled = this.form.position.position === "Voluntar";
                this.isDisabled = this.form.category.id === 99;
                this.form.supplier = this.form.position.cnp;
                this.form.supplier_name = this.form.position.first_name + ' ' + this.form.position.last_name;
            },
            addComment() {
                this.isOFFButton = true;
                this.loading = true;
                axios.post('/reportings/saveCommentTechnical',
                {
                    comment: this.form.comentariu,
                    id:this.reporting.id
                }).then((result) => {
                    this.form.comentariu = "";
                    this.isOFFButton = false;
                    this.loading = false;
                    this.reporting.comments.push(result.data.comm);
                    this.$notify({ type: 'success', title: 'Ai adaugat un comentariu', message: 'Comentariul a fost adaugat cu succes!'});
                });
            },
            openDrawerComment(object){
                if(typeof object.files === 'undefined') object.files = [];
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Comment',
                    model_id: object.id,
                    acceptFiles: '.pdf,.docx,.doc,.jpg,.xls', //.docx,.doc,
                    multiple: true,
                    updateCommentFiles: 1,
                    comments: object,
                    title: 'Documente comentariu',
                    files: object.files,
                });
            },
            viewDocument() {
                this.setShowUploadDrawer2({
                    visible: true,
                    model: 'App\\Models\\Reporting',
                    model_id: this.reporting.id,
                    category_id: this.reporting.type,
                    acceptFiles: '.pdf',
                    multiple: false,
                    canDelete: 1,
                    canUpload: 1,
                    title: 'Vizualizare documente',
                    files: this.reporting.documents,
                });
            },
            openAUXDoc(document){
                this.setShowUploadDrawer2({
                    visible: true,
                    model: 'App\\Models\\ReportingDocument',
                    model_id: document.id,
                    auxDocuments: 1,
                    category_id: 183,
                    object: document,
                    acceptFiles: document.extensions,
                    files: document.documents
                });
            },
            openDrawerDOC(object){
                this.setShowUploadDrawer2({
                    visible: true,
                    model: 'App\\Models\\Reporting',
                    model_id: this.reporting.id,
                    updateDOCFiles: 1,
                    category_id: 100,
                    acceptFiles: '.pdf,.docx,.doc,.jpg,.xls', //.docx,.doc,
                    multiple: true,
                    reporting: this.reporting,
                    title: 'Vizualizare documente',
                    files: this.reporting.documents_admin,
                });
            },
            openDrawerInfoAddress(object){
                this.setShowUploadDrawer2({
                    visible: true,
                    model: 'App\\Models\\Reporting',
                    model_id: this.reporting.id,
                    category_id: 558,
                    InfoAddress: 1,
                    acceptFiles: '.pdf,.docx,.doc,.jpg,.xls', //.docx,.doc,
                    multiple: true,
                    reporting: this.reporting,
                    title: 'Vizualizare documente',
                    files: this.reporting.documents_info_address,
                });
            },
            openDrawerVRF(object){
                this.setShowUploadDrawer2({
                    visible: true,
                    model: 'App\\Models\\Reporting',
                    model_id: this.reporting.id,
                    category_id: 556,
                    VRF: 1,
                    acceptFiles: '.pdf,.docx,.doc,.jpg,.xls', //.docx,.doc,
                    multiple: true,
                    reporting: this.reporting,
                    title: 'Vizualizare documente',
                    files: this.reporting.documents_vrf,
                });
            },
            openDrawer(object, param){
                if (param === null || param === undefined) {
                    this.setShowUploadDrawer({
                        visible: true,
                        model: 'App\\Models\\ReportingValidationActivity',
                        model_id: object.id,
                        category_id: 0,
                        acceptFiles: '.pdf,.docx,.doc', //.docx,.doc,
                        multiple: true,
                        reporting: this.reporting,
                        project: this.project,
                        title: 'Incarca document',
                        files: object.validation !== null ? object.validation.documents : [],
                        object: object,
                        validation: 1,
                    });
                } else {
                    this.setShowUploadDrawer({
                        visible: true,
                        model: 'App\\Models\\Reporting',
                        model_id: this.reporting.id,
                        category_id: this.reporting.type,
                        acceptFiles: '.pdf',
                        multiple: false,
                        title: 'Vizualizare documente',
                        files: this.reporting.documents !== null ? this.reporting.documents : [],
                    });
                }
            },
            refreshData() {
                this.getReporting({reporting_id : this.reporting_id}).then(_ => {
                    this.vrfForm.reporting_id = this.reporting.id;
                    this.vrfForm.project_id = this.reporting.project_id;
                });
                this.getProject({project_id : this.project_id});
                this.getFinancialIndirectReport({project_id: this.project_id, reporting_id: this.reporting_id});
                this.getSyntheticReport({project_id: this.project_id, reporting_id: this.reporting_id});
                this.getFinancialPartners({project_id: this.project_id, reporting_id: this.reporting_id});
                //this.getHrReport({project_id: this.project_id, reporting_id: this.reporting_id});
                this.getFinancialReport({project_id: this.project_id, reporting_id: this.reporting_id});
                //this.questions = this.reporting.questions;
                if(this.project_id && this.reporting_id) {
                    this.getRiskDocuments({project_id: this.project_id, reporting_id: this.reporting_id});
                }

            },
            calculateNotification() {
                if (this.user_type === 1) {
                    this.sendNotificationValidationInvalid = false;
                } else {
                    if (this.reporting.notificationActive === 0) {
                        this.sendNotificationValidationInvalid = true;
                    } else {
                        this.sendNotificationValidationInvalid = false;
                    }
                }
            },
            checkTerms() {
                if(this.checkbox === true){
                    this.sendValidation = false;
                }else{
                    this.sendValidation = true;
                }
            },
            saveValidation() {
                if(!this.reporting.app_financial_report || !this.reporting.documents_vrf.length) {
                    this.$notify({
                        type: 'error',
                        title: 'VRF',
                        message: 'VRF-ul nu a fost inca incarcat & finalizat, nu poti aproba raportul!'
                    });
                    return 0;
                }
                this.$confirm('Esti sigur(a) ca vrei sa validezi aceasta raportare?' +
                    ' Dupa validare nu vei mai putea edita raportul!' +
                    ' Inainte de a merge mai departe verifica daca sunt incarcate VRT, VRF si Adresa de informare! ')
                    .then(_ => {
                        this.loading = true;
                        axios.post('/reportings/saveFinancial',
                            {
                                reporting_id:this.reporting.id,
                                project_id:this.project.id
                            }).then((result) => { window.location.href = '/reportings' });
                    }).catch(err => {
                    console.log(err)
                });
            },
            saveVrf(){
                this.$refs['vrfForm'].validate((valid) => {
                    if (valid) {
                        this.setVrfReporting(this.vrfForm)
                            .then(_ => {
                                this.vrfDisabled = false;
                                this.$notify({
                                    type: 'success',
                                    title: 'VRF',
                                    message: 'Datele au fost salvate cu succes!'
                                });
                            })
                    }else{
                        VueScrollTo.scrollTo('#vrfForm');
                    }
                })
            },
            exportVrf(){
                this.$refs['vrfForm'].validate((valid) => {
                    if (valid) {
                        this.setVrfReporting(this.vrfForm)
                            .then(_ => {
                                this.getVrfExport({project_id: this.vrfForm.project_id, reporting_id: this.vrfForm.reporting_id});
                                // this.openUrl('/reportings/export-vrf/' + this.vrfForm.project_id + '/' + this.vrfForm.reporting_id, true);
                            })
                    }
                })
            },
            sendNotification() {
                if (this.form.answer_limit) {
                    this.$confirm('Esti sigur(a) ca vrei sa trimiti spre revizuire aceasta raportare?')
                        .then(_ => {
                            this.loading = true;
                            axios.post('/reportings/reviewFinancial',
                                {
                                    reporting_id: this.reporting.id,
                                    project_id: this.project.id,
                                }).then((result) => { window.location.href = '/reportings' });
                        }).catch(err => {
                        console.log(err)
                    });
                } else {
                    this.$notify({
                        type: 'error',
                        title: 'Revizuire',
                        message: 'Trebuie sa completezi data limita     !'
                    });
                }

            },
            sendAudit() {
                this.$confirm('Esti sigur(a) ca vrei sa creezi nota de debit pentru acest raport final?')
                    .then(_ => {
                        this.loading = true;
                        axios.post('/reportings/reviewFinancial',
                            {
                                reporting_id: this.reporting.id,
                                project_id: this.project.id,
                            }).then((result) => { window.location.href = '/reportings/audit/'+this.reporting.id });
                    }).catch(err => {
                    console.log(err)
                });
            },
            loadDoc(file) {
                window.open('/getFile/'+file.id, '_blank');
            },
            selectTab(tab, event){
                if (tab.name.substring(0, 8) === 'activity') {
                    this.loading = true;
                    axios.post('/reportings/reportingActivities',
                        {
                            reporting_id: this.reporting.id,
                            project_id: this.project.id,
                            activity_id: tab.$vnode.key,
                        }).then((result) => {
                            this.elements = result.data.elements;
                            this.loading = false;
                        });
                }
                if (tab.name === 'docs') {
                    this.loading = true;
                    this.getReportingDocuments({project_id: this.reporting.project_id, reporting_id: this.reporting.id})
                        .then(_ => {
                             this.loading = false;
                        })
                }
                if(tab.name === 'hr'){
                    this.loading = true;
                    this.getHrReport({project_id: this.project_id, reporting_id: this.reporting_id})
                    .then(result => {
                        this.loading = false;
                    });
                }
                if (tab.name === 'vrf') {
                    this.loading = true;
                    if (this.reporting.vrf && this.reporting.vrf.id > 0) {
                        this.vrfForm = this.reporting.vrf;
                        if (this.vrfForm.id) this.vrfDisabled = false;
                    }
                    this.getVrfEligibility({project_id: this.project_id, reporting_id: this.reporting_id})
                    .then(result => {
                        this.vrfEligibility = result;
                        // this.loading = false;
                    });
                    this.getVrfEligibilityDetails({project_id: this.project_id, reporting_id: this.reporting_id})
                        .then(result => {
                            this.vrfEligibilityDetails = result;
                            // this.loading = false;
                        })
                    this.getVrfDocuments({project_id: this.project_id, reporting_id: this.reporting_id})
                        .then(result => {
                            this.vrfDocuments = result;
                            // this.loading = false;
                        })
                    this.getVrfCash({project_id: this.project_id, reporting_id: this.reporting_id})
                        .then(result => {
                            this.vrfCash = result;
                            // this.loading = false;
                        })
                    this.getVrfFinancingExpenses({project_id: this.project_id, reporting_id: this.reporting_id})
                        .then(result => {
                            this.vrfFinancingExpenses = result;
                            // this.loading = false;
                        })
                    this.getVrfIndirects({project_id: this.project_id, reporting_id: this.reporting_id})
                        .then(result => {
                            this.vrfIndirects = result;
                            // this.loading = false;
                        })
                    this.getVrfBudget({project_id: this.project_id, reporting_id: this.reporting_id})
                        .then(result => {
                            this.vrfBudget = result;
                            this.loading = false;
                        })
                    this.getVrfOthers({project_id: this.project_id, reporting_id: this.reporting_id})
                        .then(result => {
                            this.vrfOthers = result;
                            this.loading = false;
                        })
                }
            },
            handleClick(tab, event) {
                if (tab.name === 'totals') {
                    this.loading = true;
                    // go and bring the totals
                    this.getSources({
                        project_id: this.project.id, reporting_id: this.reporting.id,
                    }).then((response) => {
                        this.loading = false;
                        this.source = response.data;
                    });
                }
                if (tab.name === 'indirects') {
                    this.loading = true;
                    // go and bring the totals
                    this.getIndirectReport({
                        project_id: this.project.id, reporting_id:this.reporting.id,
                    }).then((response) => {
                        this.loading = false;
                        this.financialIndirectReportAccepted = response.data.financialIndirectReportAccepted;
                        this.financialIndirectReportReported = response.data.financialIndirectReportReported;
                    });
                }
                if (tab.name === 'synthetic') {
                    this.loading = true;
                    // go and bring the totals
                    this.getSyntheticFromReport({
                        project_id: this.project.id, reporting_id:this.reporting.id,
                    }).then((response) => {
                        this.loading = false;
                        this.syntheticReportAccepted = response.data.syntheticReportAccepted;
                        this.syntheticReportReported = response.data.syntheticReportReported;
                    });
                }
                if (tab.name === 'partners') {
                    this.loading = true;
                    // go and bring the totals
                    this.getFinancingPartnerReport({
                        project_id: this.project.id, reporting_id:this.reporting.id,
                    }).then((response) => {
                        this.loading = false;
                        this.financialPartnersAccepted = response.data.financialPartnersAccepted;
                        this.financialPartnersReported = response.data.financialPartnersReported;
                    });
                }
                var t = tab.name;
                var xyz = t.replace(/\d/g, '');
                if (xyz === 'A') {

                    var chars = t.slice(0, t.search(/\d/));
                    var numbs = t.replace(chars, '');

                    this.getActivityReportNew({
                        project_id: this.project.id, reporting_id:this.reporting.id, activity_id: numbs,
                    }).then((response) => {
                        this.loading = false;
                        this.financialReportReported = response.data.financialReportReported;
                        this.financialReportAccepted = response.data.financialReportAccepted;
                    });
                }
            },
            getRiskSummary(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = 'Totaluri';
                        return;
                    }
                    if(index > 0 && index < 10 || index === 15){
                        sums[index] = '';
                        return;
                    }
                    // if (index === 12) {
                    //     sums[index] = '';
                    //     return;
                    // }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    } else {
                        sums[index] = ' - ';
                    }
                    if(index > 10 && index < 14){
                        sums[index] =  this.formatPrice(parseFloat(sums[index]), 2, ' RON');
                    } else if (index === 14) {
                        sums[index] =  this.formatPrice(parseFloat(sums[index]));
                    }
                });
                return sums;
            },
            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = 'Totaluri';
                        return;
                    }
                    // if(index === 1){
                    //     sums[index] = '';
                    //     return;
                    // }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    } else {
                        sums[index] = ' - ';
                    }
                    if(index !== 0){
                        if (sums[index] !== ' - ') {
                            sums[index] =  this.formatPrice(parseFloat(sums[index]));
                        } else {
                            sums[index] = this.formatPrice(0);
                        }
                        //console.log(isNaN(sums[index]));
                    }
                });
                return sums;
            },
            updatePosition(){
                this.form.position = null;
                this.form.position_id = null;
                this.form.budget = null;
                this.form.month_hours = null;
                this.form.worked_hours = null;
                this.form.month = null;
                this.showPosition = this.form.category.id === 99;
                this.getBudgetLines(this.form.category.id);
            },
            changeExpense(row, type = 'budget') {
                this.modalLoading = true;
                if (type === 'indirect') {
                    this.getIndirectCategories()
                    .then(() => {
                        this.getBudgetLines(106)
                            .then(() => {
                                this.form = row;
                                this.form.expenseType = type;
                                this.showPosition = this.form.category.id === 99;
                                this.updateLine();
                                if(!row.partner){
                                    this.form.partner = { id: this.project.promoter_id };
                                }
                                if(this.form.invoice_date && this.form.currency){
                                    if (this.form.currency === 'EUR') {
                                        this.currentRate = 1;
                                        this.form.rate = 1;
                                        this.modalLoading = false;
                                    } else {
                                        this.getDateRates([this.formatMysqlDate(this.form.invoice_date), this.form.currency])
                                            .then((result) => {
                                                this.currentRate = result.value;
                                                this.form.rate = result.value;
                                                this.modalLoading = false;
                                            });
                                    }
                                }
                            });
                    });
                } else {
                    this.getCostCategories();
                    this.getBudgetLines(row.category.id)
                        .then(() => {
                            this.form = row;
                            this.form.expenseType = type;
                            this.showPosition = this.form.category.id === 99;
                            this.updateLine();
                            if(!row.partner){
                                this.form.partner = { id: this.project.promoter_id };
                            }
                            if(this.form.invoice_date && this.form.currency){
                                if (this.form.currency === 'EUR') {
                                    this.currentRate = 1;
                                    this.form.rate = 1;
                                    this.modalLoading = false;
                                } else {
                                    this.getDateRates([this.formatMysqlDate(this.form.invoice_date), this.form.currency])
                                        .then((result) => {
                                            this.currentRate = result.value;
                                            this.form.rate = result.value;
                                            this.modalLoading = false;
                                        });
                                }
                            }
                        });
                }
                this.activityId = row.activity_id;
            },
            exitExpenseChange(){
                this.modalLoading = true;
                if (this.form.expenseType === 'indirect') {
                    axios.post('/reportings/reporting',
                        {
                            reporting_id: this.reporting.id,
                            project_id: this.project.id,
                            activity_id: this.activityId,
                        }).then((result) => {
                        this.reporting.indirects = result.data.indirects;
                        this.modalLoading = false;
                        this.showModal = false;
                    });
                }
                else {
                    axios.post('/reportings/reportingActivities',
                        {
                            reporting_id: this.reporting.id,
                            project_id: this.project.id,
                            activity_id: this.form.activity,
                        }).then((result) => {
                        this.elements = result.data.elements;
                        this.modalLoading = false;
                        this.showModal = false;
                    });
                }
            },
            saveExpenseChange(){
                this.$refs['expenseForm'].validate((valid) => {
                    if (valid) {
                        this.modalLoading = true;
                        if(this.form.expenseType === 'indirect'){
                            this.privateSetIndirects();
                        } else {
                            this.privateSetExpense();
                        }
                    } else {
                        return false;
                    }
                });
            },
            privateSetExpense(){
                this.setExpenseChange(this.form)
                    .then(() => {
                        axios.post('/reportings/reportingActivities',
                            {
                                reporting_id: this.form.reporting_id,
                                project_id: this.form.project_id,
                                activity_id: this.form.activity,
                            }).then((result) => {
                            this.elements = result.data.elements;
                        });
                    })
                    .finally(() => {
                        this.modalLoading = false;
                        this.showModal = false;
                        return true;
                    });
            }, // used in other function
            privateSetIndirects(){
                this.setIndirectExpenseChange(this.form)
                    .then(() => {
                        this.getReporting({reporting_id : this.reporting_id}).then(_ => {
                            this.vrfForm.reporting_id = this.reporting.id;
                            this.vrfForm.project_id = this.reporting.project_id;
                        });
                        axios.post('/reportings/reportingActivities',
                            {
                                reporting_id: this.form.reporting_id,
                                project_id: this.form.project_id,
                                activity_id: this.form.activity,
                            }).then((result) => {
                            this.elements = result.data.elements;
                        });
                    })
                    .finally(() => {
                        this.modalLoading = false;
                        this.showModal = false;
                        return true;
                    });
            }, // used in other function
            privateIndirectRate2(){
                this.form.amount_financing = ((this.form.amount_financing_percent/100)*this.form.amount_cost).toFixed(2);
            }, // used in other function
            privateIndirectRate3() {
                this.form.amount_cofinancing = ((this.form.amount_cofinancing_percent/100)*this.form.amount_cost).toFixed(2);
            }, // used in other function
            disabledDate(date) {
                if(moment(date) >= this.minDate && moment(date) <= this.maxDate2){
                    return false;
                }else{
                    return true;
                }
            },
            disabledDate2(date) { // Data document cost
                if (this.reporting.type_report === 2) {
                    return !(moment(date) >= moment(this.minDate) && moment(date) <= moment(this.reporting.end_date));
                } else {
                    return !(moment(date) >= moment(this.project.implementation_start_date) && moment(date) <= moment(this.reporting.end_date));
                }
            },
            disabledDate3(date) {
                if (this.reporting.type_report === 2) {
                    return !(moment(date) >= moment(this.minDate) && moment(date) <= (moment(this.project.implementation_end_date).add(30,'days')));
                } else {
                    return !(moment(date) >= moment(this.minDate) && moment(date) <= (moment(this.project.implementation_end_date).add(30,'days')));
                }
            },
            disabledDate4(date) {
                return !(moment(date) >= moment(this.project.project_start_date) && moment(date) <= (moment(this.maxDate)));
            },
            getActivitySummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = 'Totaluri';
                        return;
                    }
                    if((index > 0 && index < 15) || index === 30 || index === 31 || index === 19){
                        sums[index] = '';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    } else {
                        sums[index] = ' - ';
                    }
                    if(index >= 16 && index <= 18){
                        sums[index] =  this.formatNumber(sums[index]/1);
                    }
                    else if(index > 18){
                        sums[index] =  this.formatNumber(sums[index]/1) + '€';
                    }
                });
                return sums;
            },
            getIndirectsSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = 'Totaluri';
                        return;
                    }
                    if((index > 0 && index < 12) || index === 15){
                        sums[index] = '';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    } else {
                        sums[index] = ' - ';
                    }
                    if(index >= 12 && index <= 14){
                        sums[index] =  this.formatNumber(sums[index]/1);
                    }
                    else if(index >= 16 && index <= 19){
                        sums[index] =  this.formatNumber(sums[index]/1) + '€';
                    }else if(index > 19 && index < 25){
                        sums[index] =  this.formatNumber(sums[index]/1) + '€';
                    }
                });
                return sums;
            },
            updateProject(type) {
                if (type === 'low') {
                    axios.post('/projects/risk', {
                        value: 1,
                        type: 'risk_low',
                        projectId: this.project.id
                    }).then((result) => {
                        if (result.data.success === true && result.data.type === 'low') {
                            this.risk_low = true;
                            this.risk_medium = false;
                            this.risk_high = false;
                            this.$notify({
                                type: 'success',
                                title: 'Risc',
                                message: 'Gradul de risc a fost setat la scazut!'
                            });
                        }
                    });
                } else if (type === 'medium') {
                    axios.post('/projects/risk', {
                        value: 1,
                        type: 'risk_medium',
                        projectId: this.project.id
                    }).then((result) => {
                        if (result.data.success === true && result.data.type === 'medium') {
                            this.risk_low = false;
                            this.risk_medium = true;
                            this.risk_high = false;
                            this.$notify({
                                type: 'success',
                                title: 'Risc',
                                message: 'Gradul de risc a fost setat la mediu!'
                            });
                        }
                    });
                } else if (type === 'high') {
                    axios.post('/projects/risk', {
                        value: 1,
                        type: 'risk_high',
                        projectId: this.project.id
                    }).then((result) => {
                        if (result.data.success === true && result.data.type === 'high') {
                            this.risk_low = false;
                            this.risk_medium = false;
                            this.risk_high = true;
                            this.$notify({
                                type: 'success',
                                title: 'Risc',
                                message: 'Gradul de risc a fost setat la ridicat!'
                            });
                        }
                    });
                }
            },
            getCommentStatus(comments){
                if (comments[comments.length - 1]
                    && comments[comments.length - 1].user
                    && comments[comments.length - 1].user.promoter.length > 0 ) {
                    return 'bg-danger';
                }
            }
        }
    }
</script>
<style scoped>
    /deep/ .el-form-item__error{
        margin-top:-5px;
        position: relative;
    }
</style>
