<template>
    <div>
        <el-table :data="project.reportings">
            <el-table-column label="Raport" width="60">
                <template slot-scope="scope">
<!--                    <span v-if="scope.row.type === 11 || scope.row.type === 22">#{{ scope.row.graph_id}}</span>-->
                    <span v-if="scope.row.graph">#{{ scope.row.graph.reporting_id}}</span>
                </template>
            </el-table-column>
            <el-table-column label="Dată raportare" width="140">
                <template slot-scope="scope">
                    {{ formatUserDate(scope.row.updated_at) }}
                </template>
            </el-table-column>
            <el-table-column label="Tip raportare">
                <template slot-scope="scope">
                    <span v-if="scope.row.type === 1 || scope.row.type === 2">Raport financiar</span>
                    <span v-if="scope.row.type === 11 || scope.row.type === 22">Raport tehnic</span>
                </template>
            </el-table-column>
            <el-table-column label="Tip" width="180">
                <template slot-scope="scope">
                    <span v-if="scope.row.type_report === 1 || scope.row.type_report === 11">Raport intermediar</span>
                    <span v-else>Raport final</span>
                </template>
            </el-table-column>
            <el-table-column label="Perioadă acoperită">
                <template slot-scope="scope">
                    {{ formatUserDate(scope.row.start_date) }} - {{ formatUserDate(scope.row.end_date) }}
                </template>
            </el-table-column>
            <el-table-column prop="id" label="Stare" width="190">
                <template slot-scope="scope">
                    <span class="badge" style="background-color:#FDD9B5;" v-if="scope.row.status === 1">Ciornă</span>
                    <span class="badge" style="background-color:#93dfdf" v-if="scope.row.status === 2 && scope.row.response === 1">Clarificari primite</span> <!-- B99ABF-->
                    <span class="badge" style="background-color:#93dfdf" v-if="scope.row.status === 2 && scope.row.response === 2">Clarificari primite</span>
                    <span class="badge" style="background-color:#006F06;color:white;" v-if="scope.row.status === 3">Aprobat</span>
<!--                    <span class="badge badge-danger" v-if="scope.row.status === 4 && validAnswerLimit(scope.row.answer_limit)">Trimis spre revizuire</span>-->
<!--                    <span class="badge badge-danger" v-if="scope.row.status === 7 && scope.row.terms === 0 && validAnswerLimit(scope.row.answer_limit)">Trimite spre clarificare cu editare</span>-->
<!--                    <span class="badge badge-danger" v-if="scope.row.status === 7 && scope.row.terms === 1 && validAnswerLimit(scope.row.answer_limit)">Trimite spre clarificare</span>-->
                    <span class="badge badge-danger" v-if="(scope.row.status === 4 || scope.row.status === 7) && validAnswerLimit(scope.row.answer_limit)">Necesită modificări</span>
                    <span class="badge badge-danger" v-if="(scope.row.status === 4 || scope.row.status === 7) && !validAnswerLimit(scope.row.answer_limit)">Transmis fara raspuns la SC</span>
                    <span class="badge badge-danger" v-if="scope.row.status === 5">Nefinalizat</span>
                    <span class="badge badge-danger" v-if="scope.row.status === 6 || scope.row.status === 8">Audit</span>
                </template>
            </el-table-column>
            <el-table-column label="Acțiuni" width="200" align="right" header-align="center">
                <template slot-scope="scope">
                    <el-button size="mini" v-if="(scope.row.status === 2 || ((scope.row.status === 4 || scope.row.status === 7) && !validAnswerLimit(scope.row.answer_limit)))
                     && ((scope.row.type === 1 || scope.row.type === 2) && checkPermission(['reporting-financial-write'])
                        || (scope.row.type === 11 || scope.row.type === 22) && checkPermission(['reporting-technical-write']))"
                               type="secondary" icon="el-icon-edit"
                               @click="openUrl('/reportings/verify/'+scope.row.project_id+'/'+scope.row.id)"> Verificare</el-button>
                   <el-button size="mini" v-if="(scope.row.status === 8 && checkPermission(['reporting-technical-write']))"
                               type="secondary" icon="el-icon-edit"
                               @click="openUrl('/reportings/audit/'+scope.row.id)"> Verificare</el-button>
                    <el-button size="mini" v-if="checkPermission(['reporting-read'])" type="primary" icon="el-icon-view"
                               @click="openUrl('/reportings/reporting/'+scope.row.project_id+'/'+scope.row.id)"></el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
    import mixin from './../../mixins/common';
    export default {
        name: "pane-reporting-list",
        mixins:[mixin],
        props:{
            permissions:{
                required: true,
            },
            project: {
                required: true,
                type: Object
            }
        },
        data(){
            return{
                currentDate: new Date(),
            }
        },
        methods:{
            checkPermission(permission){
                let perm = this.permissions;
                return _.filter(permission, function (item){
                    return _.includes(perm, item);
                }).length;
            }
        },
    }
</script>

<style>
    .el-drawer__body {
        height: 100%;
        box-sizing: border-box;
        overflow-y: auto;
    }
</style>

<style scoped>

</style>
