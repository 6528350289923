<template>
    <el-form :model="reporting" v-loading="loading" ref="document" id="document" label-position="top" :status-icon="true" :inline-message="false">
        <div class="row">
            <div class="col-sm-12">
                <h3>Semnare nota de debit</h3>
                <div class="alert alert-info">
                    Exportati documentul .PDF si incarcati-l completat & semnat pentru a finaliza!
                </div>
                <h5 class="text-muted mt-3 mb-3">Date privind persoanele responsabile de intocmirea si transmiterea auditului</h5>
                <div class="row">
                    <div class="col-sm">
                        <el-form-item label="Numele prestatorului de servicii de audit" props="names" class="form-group"
                                      :rules="{required: true, message: 'Numele reprezenantului legal este obligatoriu', trigger: 'blur'}">
                            <el-input v-model="reporting.names" placeholder="Introdu numele" @change="saveData()" clearable
                                      type="text" maxlength="100" show-word-limit>
                            </el-input>
                        </el-form-item>
                    </div>
                </div>
                <hr>
                <el-button type="primary" :disabled="reporting.names ? false : true" class="float-left" @click="exportDOC()"><i class="icofont-file-pdf"></i> Exporta nota de debit</el-button>
                <el-button type="secondary" :disabled="reporting.names ? false : true" class="float-left" icon="el-icon-edit" @click="uploadDocument()">
                  <span>Incarca nota de debit</span>
                </el-button>
            </div>
        </div>
    </el-form>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex'
    import VueScrollTo from 'vue-scrollto';
    export default {
        name: "reporting-document-form",
        data(){
            return {
                loading: false,
            }
        },
        computed:{
            ...mapGetters({
                reporting: 'reportings/reporting',
                //validateDocument: 'reportings/validateDocument',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        /*watch:{
            validateDocument: function(){
                if(this.validateDocument) {
                    this.setValidateDocument(false);
                    this.submitForm();
                }
            },
        },*/
        methods:{
            ...mapActions({
                saveReporting: 'reportings/saveReporting',
            }),
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
                //setValidateDocument: 'reportings/setValidateDocument',
                setValidationErrorsExists: 'setValidationErrorsExists',
            }),
            exportDOC() {
                window.open('/reportings/export-pdf-audit/'+this.reporting.id, '_blank');
            },
            saveData(){
                this.loading = true;
                this.saveReporting(this.reporting).then((result) => {
                    this.loading = false;
                    if (result.valid === false) {
                        this.$notify({type: 'error', title: 'Eroare', message: result.message});
                    } else {
                        /*this.$notify({
                            type: 'success',
                            title: 'Salvare date',
                            message: 'Apasa din nou pe „Exporta documentul” daca doresti sa exporti fisierul PDF!'
                        });*/
                        /*this.$confirm('Doresti sa exporti documentul cu noul nume al reprezenantului legal?')
                            .then(_ => {
                                window.open('/reportings/export-pdf/'+this.reporting.id, '_blank');
                            }).catch(err => {
                            console.log(err)
                        });*/
                    }
                });
            },
            uploadDocument()
            {
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Reporting',
                    model_id: this.reporting.id,
                    category_id: 555,
                    acceptFiles: '.pdf',
                    multiple: true,
                    title: 'Vizualizare documente',
                    files: this.reporting.documents,
                    reportingDocument: 1,
                });
            },
            submitForm() {
                this.$refs['document'].validate((valid) => {
                    if (!valid) {
                        //this.setValidateDocument(false);
                        this.setValidationErrorsExists(false);
                        VueScrollTo.scrollTo('#document');
                        return true;
                    } else {
                        VueScrollTo.scrollTo('#document');
                        this.setValidationErrorsExists(true);
                        return false;
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
