<template>
    <div v-if="project" id="activity">
        <h3 class="mb-4 mt-3">
            A{{project.activities[activityIndex].activity}}: {{ project.activities[activityIndex].name }}
        </h3>
        <div class="row">
            <div class="col-sm-12">
                <el-table :key="key" :data="newActivity" stripe fit highlight-current-row style="width:100%" ref="table">
<!--                    <el-table-column type="expand" width="40">-->
<!--                        <template slot-scope="scope">-->
<!--                            bla-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                    <el-table-column label="Linie" prop="line" width="60" align="center"></el-table-column>-->
                    <el-table-column type="index" width="60" align="center"></el-table-column>
                    <el-table-column label="Capitol buget" width="200">
                        <template slot-scope="scope">
                            <el-popover placement="top-start" title="Capitol buget" width="300" trigger="click" :content="scope.row.category.value">
                                <span  slot="reference">{{ scope.row.category.value }}</span>
<!--                                | truncate(40, '...')-->
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="Aplicant/Partener" prop="partner">
                        <template slot-scope="scope" v-if="scope.row.partner">
                            {{ scope.row.partner.id !== project.promoter_id ? 'P' + scope.row.partner.id : 'A' }}
                        </template>
                    </el-table-column>
<!--                    <el-table-column label="Cod" prop="code"></el-table-column>-->
                    <el-table-column label="Linie buget" width="150">
                        <template slot-scope="scope">
                            <el-popover placement="top-start" title="Linie buget" width="300" trigger="click" :content="scope.row.budget.value">
                                <span  slot="reference">{{ scope.row.budget.value}}</span>
<!--                                | truncate(30, '...')-->
                            </el-popover>
                        </template>
                    </el-table-column>
<!--                    <el-table-column label="Pozitia">-->
<!--                        <template slot-scope="scope">-->
<!--                            <el-popover v-if="scope.row.position" placement="top-start" title="Pozitia" width="300" trigger="click" :content="scope.row.position">-->
<!--                                <span  slot="reference">{{ scope.row.position.position | truncate(30, '...')}}</span>-->
<!--                            </el-popover>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                    <el-table-column label="Descriere">-->
<!--                        <template slot-scope="scope">-->
<!--                            <el-popover placement="top-start" title="Descriere" width="300" trigger="click" :content="scope.row.description">-->
<!--                                <span  slot="reference">{{ scope.row.description | truncate(30, '...')}}</span>-->
<!--                            </el-popover>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
                    <el-table-column label="Finantare" align="right">
                        <template slot-scope="scope">
                            {{ formatPrice(parseFloat(scope.row.amount_financing)/parseFloat(scope.row.rate)) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Cofinantare" align="right">
                        <template slot-scope="scope">
                            {{ formatPrice(parseFloat(scope.row.amount_cofinancing)/parseFloat(scope.row.rate)) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Total Cost" align="right">
                        <template slot-scope="scope">
                            {{ formatPrice((parseFloat(scope.row.amount_financing) + parseFloat(scope.row.amount_cofinancing))/parseFloat(scope.row.rate)) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Dezv. Org." align="right">
                        <template slot-scope="scope">
                            {{formatPrice(scope.row.dezv !== undefined ? scope.row.dezv : 0) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Actiuni" width="130px" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="secondary" icon="el-icon-edit"
                                       @click="handleSelectRow(scope.$index, scope.row)"></el-button>
                            <el-button size="mini" type="danger" icon="el-icon-delete"
                                       @click="handleDeleteRow(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-form :model="form" :id="'reportingActivityForm'+activityId" :validate-on-rule-change="false" v-loading="loading"
                 :rules="rules" :ref="'reportingActivityForm'+activityId" label-position="top" :status-icon="true" :inline-message="true">
            <div class="row">
                <div class="col-sm-12">
                    <h4 class="mb-4 mt-5">Adauga / Modifica linie cheltuieli</h4>
                </div>
                <div class="col-sm-12 form-group">
                    <el-form-item label="Capitol buget" prop="category">
                        <el-select v-model="form.category" placeholder="Selecteaza" style="width:100%" value-key="id" @change="updatePosition">
                            <el-option v-for="item in costCategories.values"
                                       :key="item.id" :label="item.value"  :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-sm-6 form-group">
                    <el-form-item label="Cheltuiala se aloca la:" prop="partner">
                        <el-select v-model="form.partner" clearable placeholder="Selecteaza" style="width:100%" value-key="id">
                            <el-option key="0" label="Aplicant" :value="{ id: this.project.promoter_id }"></el-option>
                            <el-option v-for="item in project.partners"
                                       :key="item.id" :label="item.name" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-sm-6 form-group">
                    <el-form-item label="Linia de buget" prop="budget">
                        <el-select v-model="form.budget" placeholder="Selecteaza" style="width:100%" value-key="id" @change="updateLine">
                            <el-option v-for="item in budgetLines.values"
                                       :key="item.id" :label="item.value"  :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-sm-6 form-group" v-if="showPosition">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">ATENTIE! Cu exceptia voluntarilor si a personalului administrativ, nicio persoana din echipa de proiect nu va putea fi raportata daca nu este nominalizata in proiect fie direct in cererea de finantare fie prin notificare aprobata anterior depunerii raportului tehnico-financiar.</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="Pozitia in cadrul proiectului" prop="position"
                                  :rules="[{ required: true, message: 'Acest camp este obligatoriu', trigger: 'blur' }]">
                        <el-select v-model="form.position" placeholder="Selecteaza" style="width:100%" value-key="id" @change="updateSupplier">
                            <el-option v-for="item in project.employees" v-if="!showVolunteer && item.is_volunteer !== 1"
                               :key="item.id" :label="item.position + (item.first_name ? ' - ' + item.first_name + ' ' + item.last_name: '')" :value="item">
                            </el-option>
                            <el-option v-for="item in project.employees" v-if="showVolunteer && item.is_volunteer === 1"
                               :key="item.id" :label="item.position + (item.first_name ? ' - ' + item.first_name + ' ' + item.last_name: '')" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
            <div class="row" v-if="showPosition">
                <div class="col-sm-12">
                    <div class="row">
                        <div class="alert alert-danger">ATENTIE! Se completeaza doar pentru salariu net si pentru voluntari!</div>
                    </div>
                </div>
                <div class="col-sm-3 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Se completeaza doar pentru salariul net iar pentru contributiile salariale se completeaza cu valoare 0 (zero). Se completeaza cu totalul orelor lucratoare din luna raportata, aferente intregului contract individual de munca din care se raporteaza(partial sau integral). Se va tine cont de norma din contract, indiferent daca norma in proiect este partiala sau intreaga sau daca aceasta se raporteaza impartita pe mai multe activitati.</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="Ore lucrătoare pe lună" prop="month_hours">
                        <el-input v-model="form.month_hours" placeholder="Număr ore" type="number" clearable></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Se completeaza doar pentru salariul net iar pentru contributiile salariale se completeaza cu valoare 0 (zero). Se completeaza cu numarul de ore din luna efectiv lucrate in activitate si pentru care se raporteaza costul salarial.</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="Ore efectiv lucrate" prop="worked_hours">
                        <el-input v-model="form.worked_hours" placeholder="Număr ore" type="number" clearable></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Selectati luna pentru care se raporteaza (in functie de data documentului de angajare a costului) sau scrieti de forma LL-AAAA</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="Luna curentă" prop="month">
                        <el-date-picker v-model="form.month" type="month" placeholder="Alege o lună" value-format="yyyy-MM"
                                        format="yyyy-MM" :picker-options="periodOptions2" style="width:100%"></el-date-picker>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 form-group" v-if="!showPosition">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Se completeaza cu CUI/CIF furnizor si apoi se selecteaza din lista. Pentru linia bugetara 1.6.1. Experti - contracte drepturi autor se completeaza CNP expert</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="CUI Furnizor" prop="supplier">
                        <el-autocomplete v-model="form.supplier" @select="handleSelectCui"
                             placeholder="Introduceti CUI-ul Furnizorului" type="text"
                             :maxlength="60" show-word-limit :fetch-suggestions="getCompanies" style="width:100%">
                            <template slot-scope="{ item }">
                                <span>{{ item.value }} - {{ item.label }}</span>
                            </template>
                        </el-autocomplete>
                    </el-form-item>
                </div>
                <div class="col-sm-6 form-group" v-if="!showPosition">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Pentru linia bugetara 1.6.1. Experti - contracte drepturi autor se completeaza cu numele si prenumele expertului.</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="Furnizor" prop="supplier_name">
                        <el-input v-model="form.supplier_name" placeholder="Furnizor" type="text" maxlength="60" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-6 form-group" v-if="showPosition">
                    <br/>
                    <el-form-item label="CNP Angajat" prop="supplier">
                        <el-input v-model="form.supplier" placeholder="Introduceti CNP-ul" type="text" maxlength="60" show-word-limit :disabled="isDisabled"></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-6 form-group" v-if="showPosition">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Se completeaza automat. In cazul in care nu se completeaza verificati daca persoana este nonimalizata in proiect (pentru salariati) sau daca voluntarul este definit/introdus de catre dvs. in sectiunea ,,Echipa de proiect ''</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="Nume angajat/voluntar" prop="supplier_name">
                        <el-input v-model="form.supplier_name" placeholder="Introduceti numele angajatului" type="text" maxlength="60" show-word-limit :disabled="isDisabled"></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-6 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Completati cu suficiente detalii astfel incat cheltuiala sa se coreleze cu activitatile realizate in proiect si sa se justifice necesitatea acesteia.
                            <br/>Pentru cheltuielile de transport se va mentiona inclusiv durata deplasarii, locul si scopul deplasarii, numele salariatului are se deplaseaza.
                            <br/>Pentru diurne se va mentiona numele salariatului caruia i se acorda diurna, durata deplasarii si numarul de zile de diurna acordata, locul si scopul deplasarii.
                            <br/>Pentru cazare se va mentiona numarul de persoane cazate, perioada cazarii si numarul de nopti de cazare, locul si scopul deplasarii
                            <br/>Pentru costurile aferente voluntarilor se completeaza, dupa caz cu:  tipul costului, numar de voluntari, perioada pentru care se acorda, locul si scopul.
                            <br/>Pentru costurile aferente participantilor se va mentiona tipul costului, numarul de participanti si, dupa caz, scopul/evenimentul/actiunea in care au fost implicati, durata, locatia, <br/>alte detalii necesare pentru o buna intelegere a necesitatii cheltuielii realizate.
                            <br/>Pentru toate celelalte tipuri de costuri mentionati dupa caz, toate informatiile necesare pentru o buna intelegere justificarii costului raportat in contextul proiectului, facand referire, <br/>daca este cazul la, perioade de desfasurare a evenimentelor/ actiunilor, locatii, numar de persoane, numar de ore prestate, cantitati, etc.</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="Descriere" prop="description">
                        <el-input v-model="form.description" placeholder="Introduceti descrierea" type="text" maxlength="255" show-word-limit></el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Se va completa integral numarul din documentul de angajare a costului (toate cifrele ) fara a mentiona seria si tipul documentului(Ex. 0000075 sau 12357VJH002)
                            <br/>In cazul statelor de plata fara numar, se va completa cu numarul lunii pentru care s-a intocmit statul de plata ( ex: pentru ianuarie, se va completa cu 1, fiind prima luna din an)
                            <br/>In cazuri exceptionale, cand se identifica documente de angajare a costului fara numar, se va completa cu ,,FN'' (fara numar).
                            <br/>In cazul muncii voluntare (linia 1.1.2), se va completa cu numarul contractului de voluntariat.</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item :label="(showPosition ? 'Nr. doc. angajare cost' : 'Nr. document cost') " prop="invoice_no">
                        <el-input v-model="form.invoice_no" placeholder="Introduceti numarul" type="text" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Selectati sau scrieti de forma ZZ.LL.AAAA</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item :label="(showPosition ? 'Data doc. angajare cost' : 'Data document cost')" prop="invoice_date">
                        <el-date-picker v-model="form.invoice_date" type="date" value-format="yyyy-MM-dd"  format="d.M.yyyy" placeholder="Selecteaza data" style="width:100%" :picker-options="periodOptions2" @change="getRate"></el-date-picker>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Se va completa numarul ordinului de plata/extrasului de cont/chitantei/bonului fiscal/dispozitiei de plata, etc. Daca documentul de angajare este acelasi cu documentul de plata (Ex. bon fiscal, bilet de tren, tichet de calatori), se vor completa aceleasi informatii ca cele din campul ,, Nr. document angajare cost''</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="Nr. document plata" prop="receipt_no">
                        <el-input v-model="form.receipt_no" placeholder="Introduceti numarul" type="text" :disabled="showVolunteer" maxlength="20" show-word-limit>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Selectati sau scrieti de forma ZZ.LL.AAAA</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="Data document plata" prop="receipt_date">
                        <el-date-picker v-if="reporting.type_report === 1" v-model="form.receipt_date" type="date" :disabled="showVolunteer" value-format="yyyy-MM-dd"  format="d.M.yyyy" placeholder="Selecteaza data" style="width:100%" :picker-options="periodOptions4"></el-date-picker>
                        <el-date-picker v-else v-model="form.receipt_date" type="date" :disabled="showVolunteer" value-format="yyyy-MM-dd"  format="d.M.yyyy" placeholder="Selecteaza data" style="width:100%" :picker-options="periodOptions3"></el-date-picker>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Pentru promotori si pentru parteneri romani moneda utilizata este in mod obligatoriu RON </div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item :label="(showPosition ? 'Moneda in care a fost facuta cheltuiala' : 'Moneda in care a fost facuta cheltuiala')" prop="currency">
                        <el-select v-model="form.currency" placeholder="Selecteaza" style="width:100%" @change="getRate">
                            <el-option key="RON" label="RON" value="RON" selected></el-option>
                            <el-option key="EUR" label="EUR" value="EUR"></el-option>
                            <el-option key="BGN" label="BGN" value="BGN"></el-option>
                            <el-option key="CHF" label="CHF" value="CHF"></el-option>
                            <el-option key="CZK" label="CZK" value="CZK"></el-option>
                            <el-option key="HRK" label="HRK" value="HRK"></el-option>
                            <el-option key="HUF" label="HUF" value="HUF"></el-option>
                            <el-option key="ISK" label="ISK" value="ISK"></el-option>
                            <el-option key="MDL" label="MDL" value="MDL"></el-option>
                            <el-option key="NOK" label="NOK" value="NOK"></el-option>
                            <el-option key="PLN" label="PLN" value="PLN"></el-option>
                            <el-option key="RSD" label="RSD" value="RSD"></el-option>
                            <el-option key="SIT" label="SIT" value="SIT"></el-option>
                            <el-option key="UAH" label="UAH" value="UAH"></el-option>
                            <el-option key="GBP" label="GBP" value="GBP"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Se va completa valoarea totala a documentului de angajare a costului (100%), indiferent daca aceasta se raporteaza impartit pe mai multe activitati sau linii de buget .
                            <br/>In cazul salariilor se va completa cu costul total salarial pentru fiecare persoana raportata, aferent intregului contract individual de munca pentru care se raporteaza, indiferent daca norma in proiect este partiala sau intreaga sau daca acest cost se raporteaza impartit pe mai multe activitati.</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item :label="(showPosition ? 'Valoare totala doc. angajare cost' : 'Valoare totala document cost')" prop="amount_cost">
                        <el-input v-model="form.amount_cost" placeholder="0" type="text" show-word-limit
                                  @focus="$event.target.select()" @change="getRate">
                            <template slot="append">{{form.currency}}</template>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <br/>
                    <el-form-item label="Din care cheltuieli finantare" prop="amount_financing">
                        <el-input v-model="form.amount_financing" placeholder="0" type="text" show-word-limit
                                  @focus="$event.target.select()" @change="getRate">
                            <template slot="append">{{form.currency}}</template>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <br/>
                    <el-form-item label="Din care cheltuieli cofinantare" prop="amount_cofinancing">
                        <el-input v-model="form.amount_cofinancing" placeholder="0" type="text" show-word-limit
                                  @focus="$event.target.select()" @change="getRate">
                            <template slot="append">{{form.currency}}</template>
                        </el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 form-group">
                    <el-form-item label="Curs EURO">
                        <el-input v-model="currentRate" type="text" disabled align="right"></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Cheltuieli din finantare">
                        <el-input  :model="form.total_financing_spent" :value="formatPrice(form.amount_financing / currentRate)" type="text" disabled align="right">
                            <template slot="append">€</template>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Cheltuieli din cofinantare">
                        <el-input :model="form.total_cofinancing_spent"  :value="formatPrice(form.amount_cofinancing / currentRate)" type="text" disabled align="right">
                            <template slot="append">€</template>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Cost total">
                        <el-input :model="form.total_spent" :value="formatPrice((parseFloat(form.amount_financing) + parseFloat(form.amount_cofinancing))/currentRate)" type="text" disabled style="text-align:right">
                            <template slot="append">€</template>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-6 form-group">
                    <el-tooltip effect="light" position="bottom">
                        <div slot="content">Se completeaza cu suma aferenta dezvoltarii organizationale.
                            <br/>ATENTIE! Acest cost se completeaza doar la activitatea de dezvoltare organizationala conform bugetului;</div>
                        <i class="el-icon-info mt-1"></i>
                    </el-tooltip>
                    <el-form-item label="Partea costului total raportat care intra pe componenta de dezvoltare organizationala" class="form-group">
                        <el-input v-model="form.dezv" placeholder="0" @focus="$event.target.select()">
                            <template slot="append">€</template>
                        </el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 text-right mb-5">
                    <el-button @click.prevent="cancelRecord">Anulează, m-am răzgândit</el-button>
                    <el-button @click.prevent="clear">Goleste formularul</el-button>
                    <el-button @click.prevent="addRecord" type="secondary">Aplică modificările</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from './../../mixins/common';
    import moment from 'moment';
    import VueScrollTo from 'vue-scrollto';
    export default {
        name: "reporting-activity-form",
        mixins:[mixin],
        props:{
            activityId: Number,
            activityNumber: String,
            activityIndex: Number,
            newActivity: {
                required:true,
            },
        },
        data(){
            return {
                key: 0,
                activity:[],
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                showPosition: false,
                showVolunteer: false,
                isDisabled: false,
                loading:false,
                form:{},
                minDate: new Date(),
                maxDate: new Date(),
                originalData: { },
                periodOptions: {
                    disabledDate: this.disabledDate
                },
                periodOptions2: {
                    disabledDate: this.disabledDate2
                },
                periodOptions3: {
                    disabledDate: this.disabledDate3
                },
                periodOptions4: {
                    disabledDate: this.disabledDate4
                },
                currentRate: 1,
                sortBugetID: 0,
                drawer: false,
                projectPeriodOptions: {
                    disabledDate: this.disabledPeriodDate
                },
                rules: {
                    category: [
                        {required: true, message: 'Capitolul este obligatoriu', trigger: 'blur'},
                    ],
                    partner: [
                        {required: true, message: 'Aplicantul sau partenerul este obligatoriu', trigger: 'blur'},
                    ],
                    budget: [
                        {required: true, message: 'Linia de buget este obligatorie', trigger: 'blur'},
                    ],
                    month: [
                        {required: true, message: 'Luna curenta este obligatorie', trigger: 'blur'},
                    ],
                    month_hours: [
                        {required: true, message: 'Orele lucrate lunar sunt obligatorii', trigger: 'blur'},
                    ],
                    worked_hours: [
                        {required: true, message: 'Orele efectiv lucrate sunt obligatorii', trigger: 'blur'},
                    ],
                    supplier: [
                        {required: true, message: 'Furnizorul este obligatoriu', trigger: 'blur'},
                    ],
                    supplier_name: [
                        {required: true,  message: 'Campul este obligatoriu', trigger: 'blur'},
                    ],
                    description: [
                        {required: true, message: 'Descrierea este obligatorie', trigger: 'blur'},
                    ],
                    invoice_no: [
                        {required: true, message: 'Numarul documentului este obligatoriu', trigger: 'blur'},
                    ],
                    invoice_date: [
                        {required: true, message: 'Data documentului este obligatoriu', trigger: 'blur'},
                    ],
                    receipt_no: [
                        {required: true, message: 'Numarul documentului este obligatoriu', trigger: 'blur'},
                    ],
                    receipt_date: [
                        {required: true, message: 'Data documentului este obligatoriu', trigger: 'blur'},
                    ],
                    amount_cost: [
                        {required: true, message: 'Valoarea este obligatorie', trigger: 'blur'},
                    ],
                    amount_financing: [
                        {required: true, message: 'Valoarea este obligatorie', trigger: 'blur'},
                    ],
                    amount_cofinancing: [
                        {required: true, message: 'Valoarea este obligatorie', trigger: 'blur'},
                    ],
                },
            }
        },
        computed:{
            ...mapGetters({
                // rates: 'rates',
                dayRate: 'dayRate',
                costCategories: 'project/costCategories',
                budgetLines: 'project/budgetLines',
                // units: 'project/units',
                project: 'project/project',
                validateActivity: 'reportings/validateActivity',
                validationErrorsExists: 'validationErrorsExists',
                reporting: 'reportings/reporting',
            }),
            // project(){
            //     return this.getProject;
            // }
        },
        watch:{
            validateActivity: function(){
                if(this.validateActivity[this.activityIndex]) {
                    this.submitForm();
                }
            },
            newActivity : function (x) {
                this.clear();
                this.activity = [];
               x.forEach(act => {
                   this.activity = x;
               });
               //console.log(this.activity.length);
                this.form.line = this.activity.length > 0 ? this.activity.length  : 1;
                //console.log(this.form.line);
            },
            reporting: function(newVal){
                if(this.reporting.type_report === 2)
                {
                    this.minDate = moment(this.project.implementation_start_date);
                    this.maxDate = moment(this.project.implementation_end_date).add(30,'days');
                }
                else
                {
                    this.minDate = moment(newVal.start_date);
                    this.maxDate = moment(newVal.end_date);
                }
                this.maxDate2 = moment(this.reporting.end_date);
            }
        },
        mounted(){
            this.clear();
            if(this.reporting.type_report === 2)
            {
                this.minDate = moment(this.project.implementation_start_date);
                this.maxDate = moment(this.project.implementation_end_date).add(30,'days');
            }
            else
            {
                this.minDate = moment(this.reporting.start_date);
                this.maxDate = moment(this.reporting.end_date);
            }
        },
        changed() {

        },
        methods:{
            ...mapActions({
                getBudgetLines: 'project/getBudgetLines',
                searchAnafCompany: 'searchAnafCompany',
                getDateRates: 'getDateRates',
                removeActivityLine: 'reportings/removeActivityLine',
                saveActivityLine: 'reportings/saveActivityLine',
            }),
            ...mapMutations({
                setValidateActivity: 'reportings/setValidateActivity',
                setValidationErrorsExists: 'setValidationErrorsExists',
            }),
            disabledPeriodDate(date) {
                return !(moment(date) >= moment(this.reporting.start_date) && moment(date) <= moment(this.reporting.end_date).add(30,'days'));
            },
            disabledDate(date) {
                if(moment(date) >= this.minDate && moment(date) <= this.maxDate2){
                    return false;
                }else{
                    return true;
                }
            },
            disabledDate2(date) { // Data document cost
                if (this.reporting.type_report === 2) {
                    return !(moment(date) >= moment(this.minDate) && moment(date) <= moment(this.reporting.end_date));
                } else {
                    return !(moment(date) >= moment(this.project.implementation_start_date) && moment(date) <= moment(this.reporting.end_date));
                }
            },
            disabledDate3(date) {
                if (this.reporting.type_report === 2) {
                    return !(moment(date) >= moment(this.minDate) && moment(date) <= (moment(this.project.implementation_end_date).add(30,'days')));
                } else {
                    return !(moment(date) >= moment(this.minDate) && moment(date) <= (moment(this.project.implementation_end_date).add(30,'days')));
                }
            },
            disabledDate4(date) {
                return !(moment(date) >= moment(this.project.implementation_start_date) && moment(date) <= (moment(this.maxDate)));
            },
            getRate(){
                if(this.form.invoice_date && this.form.currency){
                    if (this.form.currency === 'EUR') {
                        this.currentRate = 1;
                    } else {
                        this.getDateRates([moment(this.form.invoice_date).format('YYYY-MM-DD'),this.form.currency])
                            .then((result) => {
                                this.currentRate = result.value;
                            });
                    }
                }
            },
            updatePosition(){
                this.showPosition = this.form.category.id === 99;
                this.getBudgetLines(this.form.category.id);
            },
            updateSupplier(item){
                // this.isDisabled = this.form.position.position === "Voluntar";
                this.isDisabled = this.form.category.id === 99;
                this.form.supplier = this.form.position.cnp;
                this.form.supplier_name = this.form.position.first_name + ' ' + this.form.position.last_name;
            },
            updateLine(){
                //console.log(this.form.category.id);
                this.sortBudgetID = this.form.budget.id;
                this.showPosition = this.form.category.id === 99;
                if(this.form.budget.id === 108) {
                    this.showVolunteer = true;
                    this.rules.receipt_no[0].required = false;
                    this.rules.receipt_date[0].required = false;
                }else{
                    this.showVolunteer = false;
                    this.rules.receipt_no[0].required = true;
                    this.rules.receipt_date[0].required = true;
                }
            },
            handleSelectCui(item){
                this.form.supplier_name = item.label;
            },
            getCompanies(query, cb) {
                if (query !== undefined && query.length >= 3) {
                    // setTimeout(() => {

                        this.searchAnafCompany(query).then(result => {
                            //console.log(result);
                            cb(result);
                        });
                    // }, 200);
                }
            },
            cancelRecord(){
                this.key += 1;
                this.$forceUpdate();
                //vm.$forceUpdate();
                //this.clear(1);
                // this.newActivity = {};
                // console.log(this.newActivity);
                // this.newActivity = this.originalData;
                // console.log('----');
                // console.log(this.newActivity);
                VueScrollTo.scrollTo('#activity');
            },
            addRecord(){
                this.$refs['reportingActivityForm'+this.activityId].validate((valid) => {
                    if (valid) {
                        //console.log(this.form.total_financing_spent);
                        //console.log(this.form);
                        this.loading = true;
                        let value = this.form.budget.id;
                        let totalBudget = 0;
                        let filteredBudget = this.project.activities[this.activityIndex].budget.filter(function(obj) {
                            return (obj.budget_id === value);
                        });
                        filteredBudget.forEach(function(e){
                            totalBudget += parseFloat(e.total);
                        });

                        // added later to calculate all the lines for this activity
                        let activitiesValue = 0;
                        // console.log(this.newActivity);
                        // console.log(this.activityIndex);
                        // console.log('---------------');
                        let filteredBudgetActivity = this.newActivity.filter(function(obj) {
                            //console.log(obj);
                            return (obj.budget_id === value);
                        });
                        filteredBudgetActivity.forEach(function(e){
                            activitiesValue += parseFloat(e.amount_cost);
                        });
                        let xyz  = 0;
                        let abc  = 0;
                        let total_value_per_activity  = 0;
                         // console.log(this.form.total_cost);
                         // console.log('---');
                        xyz = this.form.amount_financing / this.currentRate;
                        abc = activitiesValue / this.currentRate;
                        total_value_per_activity = xyz + abc;
                        // console.log(xyz);
                        // console.log('---');
                        // console.log(abc);
                        // console.log(this.form);
                        // console.log('---');
                        // console.log(total_value_per_activity);
                        // console.log('---');
                        //console.log(totalBudget / this.currentRate);
                        // console.log('---');
                        //  console.log(totalBudget);
                        if((totalBudget) <= total_value_per_activity){
                            // calculate total budget and divide it by the rate, to have euro value of the budget and compare it with the euro value of the activities
                            this.loading = false;
                            return this.$notify({
                                type:'error', title: 'Atentie!',
                                message: 'Ati depasit suma bugetata. Va rugam sa revizuiti suma de raportat sau sa initiati o modificare bugetara anterior depunerii raportului!' });
                        }
                        if (this.form.amount_cost < (parseInt(this.form.amount_financing) + parseInt(this.form.amount_cofinancing))) {
                            this.loading = false;
                            return this.$notify({
                                type:'error', title: 'Atentie!',
                                message: 'Valoarea totala a documentului nu poate fi mai mica decat suma dintre valoarea finantatii nerambursabile si valoarea cofinantarii. Va rugam sa revizuiti sumele raportate.' });

                        }
                        if (this.form.supplier) {
                            if (this.form.supplier_name.length < 4) {
                                this.loading = false;
                                return this.$notify({
                                    type:'error', title: 'Atentie!',
                                    message: 'Pozitia in cadrul proiectului raportata nu are o persoana nominalizata. Va rugam sa revizuiti!' });
                            }
                        }
                        if(typeof this.form.partner.name !== undefined)
                        {
                            if (this.form.position !== null) {
                                if(this.form.position.partner_id !== null && this.form.position.partner_id !== this.form.partner.id)
                                {
                                    this.loading = false;
                                    return this.$notify({
                                        type:'error', title: 'Atentie!',
                                        message: 'Pozitia in cadrul proiectului raportata nu apartine organizatiei selectate. Va rugam sa revizuiti!' });
                                }
                            }
                        }
                        this.form.rate = this.currentRate;
                        this.form.activity_id = this.activityId;
                        this.form.activity = this.activityNumber;
                        //this.form.id = this.reporting.id;
                        this.form.project_id = this.reporting.project_id;
                        this.form.reporting_id = this.reporting.id;
                        this.saveActivityLine(this.form).then((result) => {
                            this.$notify({ type: 'success', title: 'Cheltuieli', message: 'Înregistrarea a fost adăugată cu succes!'});
                            VueScrollTo.scrollTo('#reportingActivityForm'+this.activityId);
                            this.loading = false;
                            if(this.form.line >= this.newActivity.length) {
                                this.newActivity.push(result.line);
                            }
                            this.clear();
                        })
                    } else {
                        VueScrollTo.scrollTo('#reportingActivityForm'+this.activityId);
                        return false;
                    }
                });
            },
            saveActivity(){
                // console.log(this.form);
                // this.saveReporting(this.reporting).then((result) => {
                //     if(result.valid === false){
                //         this.$notify({ type:'error', title: 'Eroare', message: result.message });
                //         reject(false);
                //     }
                // });
            },
            handleSelectRow(line, row){
                this.originalData = this.newActivity;
                console.log(this.originalData);
                //console.log(row.line + 1);
                this.form = row;
                this.form.line = line;
                if(!row.partner){
                    this.form.partner = '0';
                }
                if(!isNaN(row.cnp))
                {
                    this.form.supplier = row.cnp;
                    this.form.supplier_name = row.first_name + ' ' + row.last_name;
                }
                this.updatePosition();
                this.getRate();
                VueScrollTo.scrollTo('#reportingActivityForm'+this.activityId);
            },
            clear(from){

                //this.newActivity = this.originalData;
                if (from === 1) {
                    console.log(this.originalData);
                    this.newActivity = this.originalData;
                    this.form =  {
                        // line: this.activity.length + 1,
                        line: this.activity.length > 0 ? this.activity.length : 1,
                        category: null,
                        budget: null,
                        currency: 'RON',
                        supplier:'',
                        supplier_name:'',
                        position: null,
                        dezv: 0,
                        amount_cost: 0,
                        amount_financing: 0,
                        amount_cofinancing: 0,
                        total_financing_spent: 0,
                        total_cofinancing_spent: 0,
                        total_spent: 0
                    };
                    console.log('---');
                    console.log(this.newActivity);
                }
                this.form =  {
                    // line: this.activity.length + 1,
                    line: this.activity.length > 0 ? this.activity.length : 1,
                    category: null,
                    budget: null,
                    currency: 'RON',
                    supplier:'',
                    supplier_name:'',
                    position: null,
                    dezv: 0,
                    amount_cost: 0,
                    amount_financing: 0,
                    amount_cofinancing: 0,
                    total_financing_spent: 0,
                    total_cofinancing_spent: 0,
                    total_spent: 0
                };
                // this.$refs['activityForm'].clearValidate();
            },
            handleDeleteRow(line, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi aceasta inregistrare? Acest proces este ireversibil!')
                    .then(_ => {
                        if (line !== -1) {
                            if(row.id > 0) {
                                // console.log(row);
                                this.removeActivityLine({
                                    pid: row.project_id,
                                    rid: row.reporting_id,
                                    line: row.id});
                                this.activity.splice(line, 1);
                            }else {
                                this.activity.splice(line, 1);
                            }
                            this.$notify({ type: 'success', title: 'Cheltuieli', message: 'Inregistrarea a fost stearsa cu succes!'});
                            this.clear();
                        }
                    }).catch(err => {
                        console.log(err)
                    });
            },
            submitForm() {
                // if (this.activity.length > 0) {
                //     this.reporting.activities[this.activityIndex].reporting = this.activity;
                    this.setValidateActivity({index: this.activityIndex, value: false});
                    this.setValidationErrorsExists(false);
                    VueScrollTo.scrollTo('#activity');
                    return true;
                // } else {
                //     this.setValidationErrorsExists(true);
                //     this.$notify({ type: 'warning', title: 'Buget', message: 'Trebuie sa adaugati cel putin o linie de cheltuieli!'});
                //     return false;
                // }
            },
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
