<template>
    <div>
        <div class="row">
            <div class="col-sm-22">
                <h3>
                    Centralizator raport
                    <a href="#" @click.prevent="openUrl('/reportings/exportFinancialReport/'+reporting.id+'/'+project.id, true)" title="Export in Excel" class="btn btn-sm btn-secondary ml-5">
                        <i class="icofont-file-excel"></i> Export in Excel
                    </a>
                </h3>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12">
<!--                {{ project.activities }}-->
                <el-tabs v-model="reportTabs" @tab-click="handleClick" v-loading="loading">
                    <el-tab-pane :label="'A'+activity.activity" :name="'A'+activity.activity" v-for="activity in project.activities" :key="activity.id">
                        <template slot="label">
                            <el-tooltip position="top">
                                <div slot="content">{{ activity.name }}</div>
                                <span>A{{ activity.activity }}</span>
                            </el-tooltip>
                        </template>
                        <el-table :data="financialReport[activity.id]" :summary-method="getSummaries" show-summary size="mini">
                            <el-table-column show-overflow-tooltip label="Linie buget" width="200">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.name">{{ scope.row.name }}</span>
                                </template>
                            </el-table-column>
<!--                            <el-table-column label="Linie buget" prop="budget"></el-table-column>-->
                            <el-table-column label="Buget aprobat" align="center">
                                <el-table-column label="Total" align="right" header-align="center" prop="total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                                </el-table-column>
                                <el-table-column label="Comp. dezv." align="right" header-align="center" prop="dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli acceptate raportari precedente" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="pre_financing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.old_financing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="pre_cofinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.old_cofinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="pre_total">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.old_financing + scope.row.old_cofinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="pre_dezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.old_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli aferente perioadei raportate" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="now_financing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.now_financing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="now_cofinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.now_cofinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="now_total">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.now_total) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="now_dezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.now_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Sume necheltuite" align="center">
                                <el-table-column label="Din buget" align="right" header-align="center" prop="diff_total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_total) }}</template>
                                </el-table-column>
                                <el-table-column label="Din dezv. org." align="right" header-align="center" prop="diff_dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="Indirecte" name="Indirecte">
                        <template slot="label">
                            <el-tooltip position="top">
                                <div slot="content">Cheltuieli indirecte</div>
                                <span>Indirecte</span>
                            </el-tooltip>
                        </template>
                        <el-table :data="financialIndirectReport" :summary-method="getSummaries" show-summary size="mini">
                            <el-table-column show-overflow-tooltip label="Aplicant/Partener" width="200">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.partner">{{ scope.row.partner }}</span>
<!--                                    <span v-if="scope.row.budget" style="margin-left:10px;">{{ scope.row.budget }}</span>-->
                                </template>
                            </el-table-column>
                            <el-table-column label="Buget aprobat" align="center">
                                <el-table-column label="Total" align="right" header-align="center" prop="total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                                </el-table-column>
                                <el-table-column label="Comp. dezv." align="right" header-align="center" prop="dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli acceptate raportari precedente" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="pre_financing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.pre_financing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="pre_cofinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.pre_cofinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="pre_total">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.pre_total) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="pre_dezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.pre_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli aferente perioadei raportate" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="now_financing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.now_financing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="now_cofinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.now_cofinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="now_total">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.now_total) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="now_dezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.now_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Sume necheltuite" align="center">
                                <el-table-column label="Din buget" align="right" header-align="center" prop="diff_total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_total) }}</template>
                                </el-table-column>
                                <el-table-column label="Din dezv. org." align="right" header-align="center" prop="diff_dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="Buget sintetic" name="syntethic">
                        <el-table :data="syntheticReport" :summary-method="getSummaries" show-summary size="mini">
                            <el-table-column show-overflow-tooltip label="Linie de buget" width="200">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.name">{{ scope.row.name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Buget aprobat" align="center">
                                <el-table-column label="Total" align="right" header-align="center" prop="total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                                </el-table-column>
                                <el-table-column label="Comp. dezv." align="right" header-align="center" prop="totalDezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalDezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli acceptate raportari precedente" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingOldFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingOldCofinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldCofinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="totalOld">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalOld) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingOldDezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldDezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli aferente perioadei raportate" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingNowFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingNowCofinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowCofinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="totalNow">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalNow) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingNowDezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowDezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Sume necheltuite" align="center">
                                <el-table-column label="Din buget" align="right" header-align="center" prop="diff_total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_total) }}</template>
                                </el-table-column>
                                <el-table-column label="Din dezv. org." align="right" header-align="center" prop="diff_dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="Total pe parteneri" name="financing">
                        <el-table :data="financialPartners" :summary-method="getSummaries" show-summary size="mini">
                            <el-table-column show-overflow-tooltip label="Promotor / Partener" width="200">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.name">{{ scope.row.name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Buget aprobat" align="center">
                                <el-table-column label="Total" align="right" header-align="center" prop="total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                                </el-table-column>
                                <el-table-column label="Comp. dezv." align="right" header-align="center" prop="totalDezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalDezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli acceptate raportari precedente" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingOldFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingOldCofinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldCofinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="sumTotalOld">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.sumTotalOld) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingOldDezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingOldDezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli aferente perioadei raportate" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="totalReportingNowFinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowFinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="totalReportingNowCofinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowCofinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="sumTotalNow">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.sumTotalNow) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="totalReportingNowDezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.totalReportingNowDezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Sume necheltuite" align="center">
                                <el-table-column label="Din buget" align="right" header-align="center" prop="diff_total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_total) }}</template>
                                </el-table-column>
                                <el-table-column label="Din dezv. org." align="right" header-align="center" prop="diff_dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="Surse de finantare" name="totals">
                        <table class="table table-responsive el-table el-table--fit el-table--border el-table--group el-table--enable-row-hover el-table--enable-row-transition el-table--mini">
                            <thead>
                                <tr>
                                    <th style="width:40%;white-space:normal;">Sursele de finantare </th>
                                    <th style="width:10%;white-space:normal;">Sume aprobate conform contractului de finantare</th>
                                    <th style="width:10%;white-space:normal;">% conform contractului de finantare</th>
                                    <th style="width:10%;white-space:normal;">Sume raportate ca fiind eligibile</th>
                                    <th style="width:10%;white-space:normal;">% cheltuielilor raportate si acceptate ca fiind eligibile pe surse de finantare</th>
                                    <th style="width:10%;white-space:normal;">Procentul cheltuirii bugetului</th>
                                    <th style="width:10%;white-space:normal;">Procentul bugetului ramas de cheltuit</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    1. Finantare nerambursabila solicitata
                                </td>
                                <td class="text-right">
                                    {{ formatPrice(this.source.totalIrredeemable) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalIrredeemable/this.source.totalEligible) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPrice(this.source.totalEligibleNow) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalEligibleNow/this.source.totalProject) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalEligibleNow/this.source.totalIrredeemable) }}
                                </td>
                                <td class="text-right">
                                    {{ this.source.percentLeftFinancing }}%
                                </td>
                            </tr>
                            <tr>
                                <td>2. Cofinantare Aplicant si Parteneri</td>
                                <td class="text-right">
                                    {{ formatPrice(this.source.totalCoFunding) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalCoFunding/this.source.totalEligible) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPrice(this.source.totalCoFundingNow) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalCoFundingNow/this.source.totalProject) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalCoFundingNow/this.source.totalCoFunding) }}
                                </td>
                                <td class="text-right">
                                    {{ this.source.percentLeftCofinancing }}%
                                </td>
                            </tr>
                            <tr>
                                <td class="text-muted">2.1 Contributie in bani</td>
                                <td class="text-right text-muted">
                                    {{ formatPrice(this.source.totalCash) }}
                                </td>
                                <td class="text-right text-muted">
                                    {{ formatPercent(this.source.totalCash/this.source.totalCoFunding) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPrice(this.source.totalCashNow) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalCashNow/this.source.totalCoFundingNow) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalCashNow/this.source.totalCash) }}
                                </td>
                                <td class="text-right">
                                    {{ this.source.percentLeftCash }}%
                                </td>
                            </tr>
                            <tr>
                                <td class="text-muted">2.2 Contributie in natura</td>
                                <td class="text-right text-muted">
                                    {{ formatPrice(this.project.totalNature) }}
                                </td>
                                <td class="text-right text-muted">
                                    {{ formatPercent(this.project.totalNature/this.project.totalCoFunding) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPrice(this.source.totalNatureNow) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalNatureNow/this.source.totalCoFundingNow) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalNatureNow/this.source.totalNature) }}
                                </td>
                                <td class="text-right">
                                    {{ this.source.percentLeftNature }}%
                                </td>
                            </tr>
                            <tr>
                                <td>Total costuri eligibile</td>
                                <td class="text-right">
                                    {{ formatPrice(this.source.totalEligible) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalEligible/this.source.totalEligible) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPrice(this.source.totalProject) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalProject/this.source.totalProject) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPercent(this.source.totalProject/this.source.totalEligible) }}
                                </td>
                                <td class="text-right">
                                    {{ this.source.percentSpentNow }}%
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from './../../mixins/common';
    import VueScrollTo from "vue-scrollto";

    export default {
        name: "reporting-report-form",
        mixins:[mixin],
        data(){
            return{
                reportTabs: 'A1',
                loading: false,
                report: [],
                source: {

                },
            };
        },
        computed:{
            ...mapGetters({
                project: 'project/project',
                validateReport: 'reportings/validateReport',
                validationErrorsExists: 'validationErrorsExists',
                reporting: 'reportings/reporting',
                financialReport: 'reportings/financialReport',
                syntheticReport: 'reportings/syntheticReport',
                financialIndirectReport: 'reportings/financialIndirectReport',
                financialPartners: 'reportings/financialPartners',
            }),
        },
        watch:{
            reporting: function(bla){
                if(this.project.id && this.reporting.id) {
                    this.getFinancialReport({project_id: this.project.id, reporting_id: this.reporting.id});
                    this.getFinancialIndirectReport({project_id: this.project.id, reporting_id: this.reporting.id});
                    this.getSyntheticReport({project_id: this.project.id, reporting_id: this.reporting.id});
                    this.getFinancialPartners({project_id: this.project.id, reporting_id: this.reporting.id});
                }
            },
            validateReport: function(){
                if(this.validateReport) {
                    this.setValidationErrorsExists(true);
                    this.submitForm();
                }
            },
        },
        mounted(){
            if(this.project.id && this.reporting.id) {
                this.getFinancialReport({project_id: this.project.id, reporting_id: this.reporting.id});
                this.getSyntheticReport({project_id: this.project.id, reporting_id: this.reporting.id});
                this.getFinancialIndirectReport({project_id: this.project.id, reporting_id: this.reporting.id});
                this.getFinancialPartners({project_id: this.project.id, reporting_id: this.reporting.id});
            }
        },
        methods:{
            ...mapActions({
                getFinancialReport: 'reportings/getFinancialReport',
                getSyntheticReport: 'reportings/getSyntheticReport',
                getFinancialPartners: 'reportings/getFinancialPartners',
                getFinancialIndirectReport: 'reportings/getFinancialIndirectReport',
                setValidateReport: 'reportings/setValidateReport',
                getSources: 'reportings/getSources',
            }),
            ...mapMutations({
                setValidationErrorsExists: 'setValidationErrorsExists',
            }),
            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = 'Totaluri';
                        return;
                    }
                    // if(index === 1){
                    //     sums[index] = '';
                    //     return;
                    // }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    } else {
                        sums[index] = ' - ';
                    }
                    if(index !== 0){
                        if (sums[index] !== ' - ') {
                            sums[index] =  this.formatPrice(parseFloat(sums[index]));
                        } else {
                            sums[index] = this.formatPrice(0);
                        }
                        //console.log(isNaN(sums[index]));
                    }
                });
                return sums;
            },
            submitForm() {
                // this.$confirm('Ești sigur(a) că vrei să finalizezi acest raport? După acest pas informațiile introduse nu vor mai putea fi modificate!')
                //     .then(_ => {
                this.setValidationErrorsExists(false);
                return true;
                //     })
                // .catch(_ => {
                //     console.log('no');
                //     this.setValidationErrorsExists(true);
                //     this.setValidateReport(false);
                //     return false;
                // })
            },
            handleClick(tab, event) {
                if (tab.name === 'totals') {
                    this.loading = true;
                    // go and bring the totals
                    this.getSources({
                        project_id: this.project.id, reporting_id:this.reporting.id,
                    }).then((response) => {
                        this.loading = false;
                        console.log(response.data);
                        this.source = response.data;
                    });
                }
            },
        }
    }
</script>

<style scoped>

</style>
