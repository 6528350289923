export const errors = (state) => { return state.errors };
export const promoters = (state) => { return state.promoters };
export const validatePromoter = (state) => { return state.validatePromoter };
export const promoterValidated = (state) => { return state.promoterValidated };
export const validatePromoterAccount = (state) => { return state.validatePromoterAccount };
export const promoterAccountValidated = (state) => { return state.promoterAccountValidated };
export const financialIndirectReport = (state) => { return state.financialIndirectReport };
export const financialIndirectReportNow = (state) => { return state.financialIndirectReportNow };
export const financialReport = (state) => { return state.financialReport };
export const financialReportNow = (state) => { return state.financialReportNow };
export const getReporting = (state) => { return state.reporting };
export const reporting = (state) => { return state.reporting };
export const project = (state) => { return state.project };
export const syntheticReport = (state) => { return state.syntheticReport };
export const syntheticReportNow = (state) => { return state.syntheticReportNow };
export const financialPartners = (state) => { return state.financialPartners };
export const financialPartnersNow = (state) => { return state.financialPartnersNow };
export const reportingDocuments = (state) => { return state.reportingDocuments };
export const hrReport = (state) => { return state.hrReport };
export const riskDocuments = (state) => { return state.riskDocuments };
export const validateGeneral = (state) => { return state.validateGeneral };
export const validateActivity = (state) => { return state.validateActivity };
export const validateIndirect = (state) => { return state.validateIndirect };
export const validateFinal = (state) => { return state.validateFinal };
export const validateReport = (state) => { return state.validateReport };